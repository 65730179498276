import { useCallback, useEffect, useMemo } from 'react'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { createProductId } from 'helpers/createProductId'
import { getAxonItems } from 'helpers/getAxonItems'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { ScreenName } from 'root-constants'

import { useLogSalePageShown } from './useLogSalePageShown'
import { usePurchaseStore } from './usePurchaseStore'

type TReturnPurchaseAnalytics = {
  sendGeneralPurchaseEvents: (shouldPurchaseShownBeLogged?: boolean) => void
}

export const usePurchaseAnalytics = (
  screenName: ScreenName = ScreenName.PURCHASE,
): TReturnPurchaseAnalytics => {
  const {
    uuid,
    periodName,
    periodQuantity,
    price,
    subscriptions,
    trialPrice,
    email,
    stripeAccountId,
    stripeAccountName,
    selectedSubscription,
  } = usePurchaseStore()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  useEffect(() => {
    window.axon && window.axon('track', 'page_view')
  }, [])

  useLogSalePageShown({ subscriptions, screenName })

  const sendGeneralPurchaseEvents = useCallback(
    (shouldPurchaseShownBeLogged = true) => {
      if (shouldPurchaseShownBeLogged) {
        eventLogger.logPurchaseShown({
          productId,
          screenName,
          stripeAccountId,
          stripeAccountName,
        })
      }

      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })

      window.obApi && window.obApi('track', 'Checkout')
      window._tfa &&
        window._tfa.push({ notify: 'event', name: 'start_checkout' })

      window.ttq &&
        window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })

      googleAnalyticsLogger.logCheckoutStarted(subscriptions)

      selectedSubscription &&
        window.axon &&
        window.axon('track', 'begin_checkout', {
          currency: selectedSubscription.currency.toUpperCase(),
          value:
            selectedSubscription.trialPrices.fullPrice ||
            selectedSubscription.mainPrices.fullPrice,
          items: getAxonItems([selectedSubscription]),
        })
    },
    [
      uuid,
      isPersonalDataAllowed,
      email,
      subscriptions,
      selectedSubscription,
      productId,
      screenName,
      stripeAccountId,
      stripeAccountName,
    ],
  )

  return { sendGeneralPurchaseEvents }
}
