import React from 'react'

import { StyledAnswerButtonWithImage2 as S } from './AnswerButtonWithImage2.styles'

type TProps = {
  text: string
  imageSrc: string
}

export const AnswerButtonWithImage2: React.FC<TProps> = ({
  text,
  imageSrc,
}) => (
  <S.Root>
    <S.ImageContainer>
      <S.Image src={imageSrc} alt="" />
    </S.ImageContainer>
    <S.TextContainer>{text}</S.TextContainer>
  </S.Root>
)
