import React, { FC, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'storybook-ui'

import { PageTitle } from 'components/PageTitle'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'

import { getCountryNameFromCode } from 'helpers/getCountryName'

import { TPageProps } from 'models/common.model'

import { StickyButtonContainer } from 'common-styles'

import { BUTTONS_PROPS } from '../TriedKoreanBeauty/constants'
import { StyledHaveSolution as S } from './HaveSolution.styles'
import { QUESTION } from './constants'

export const HaveSolution: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const countryCode = useSelector(selectUserCountryCode)

  const [userCountryName, setUserCountryName] = useState('')

  useEffect(() => {
    const countryName = getCountryNameFromCode(countryCode)

    if (countryName) {
      setUserCountryName(countryName)
    }
  }, [countryCode])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <div>
      <S.Container>
        <PageTitle marginBottom={16}>
          <Trans i18nKey="onboarding.haveSolution.title" />
        </PageTitle>

        <S.AppNumberOne>
          <Trans
            i18nKey="onboarding.haveSolution.faceYogaAppNumberOne"
            components={{
              country: <S.Country />,
            }}
            values={{ country: userCountryName }}
          />
        </S.AppNumberOne>

        <S.Description>
          <Trans i18nKey="onboarding.haveSolution.makingYourFaceGlow" />
        </S.Description>

        <S.Description>
          <Trans i18nKey="onboarding.haveSolution.description" />
        </S.Description>
      </S.Container>

      <StickyButtonContainer centerMode>
        <Button {...BUTTONS_PROPS} onClick={handleNextClick}>
          {t`actions.continue`}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
