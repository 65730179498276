import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { OptionType } from 'root-constants'

import { LIFESTYLE_ANSWERS, QUESTION } from './constants'

export const DoubleChinLifestyle: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
  }

  return (
    <Container>
      <DoubleChinTitle>
        <Trans
          i18nKey="onboarding.doubleChinLifestyle.question"
          components={[<strong />]}
        />
      </DoubleChinTitle>
      {LIFESTYLE_ANSWERS.map(({ value, text }) => (
        <Answer {...optionProps} key={value} value={value}>
          {t(text)}
        </Answer>
      ))}
      <SandyBackgroundGlobalStyles />
    </Container>
  )
}
