import {
  Cohort,
  PROGRESS_SECTION_STEP,
  PROGRESS_STEPS_LENGTH_MAP,
} from 'root-constants'

import { useABTest } from './useABTest'
import { useActiveCohortIdentifier } from './useActiveCohortIdentifier'
import { useCohortToUse } from './useCohortToUse'

export const useOnboardingProgressStepsLength = () => {
  const { isLuvlyChinCohort } = useActiveCohortIdentifier()
  const { isChinPageSetVariantBActive } = useABTest()
  const cohortToUse = useCohortToUse()

  if (!isLuvlyChinCohort) return PROGRESS_STEPS_LENGTH_MAP[cohortToUse]

  const chinCohortProgressSteps =
    PROGRESS_STEPS_LENGTH_MAP[Cohort.LUVLY_CHIN] || 0

  return isChinPageSetVariantBActive
    ? chinCohortProgressSteps + PROGRESS_SECTION_STEP
    : chinCohortProgressSteps
}
