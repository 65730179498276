import { AvailableFortuneWheelAttempts } from 'root-constants'

export const AUDIO_START_POINT = 0
export const GAME_STEP = 1

export const WHEEL_ANIMATION_SEGMENTS = {
  [AvailableFortuneWheelAttempts.TWO]: [120, 220],
  [AvailableFortuneWheelAttempts.ONE]: [240, 320],
}

export const WHEEL_ANIMATION_START_POINTS = {
  [AvailableFortuneWheelAttempts.TWO]: 0,
  [AvailableFortuneWheelAttempts.ONE]: 120,
  [AvailableFortuneWheelAttempts.ZERO]: 240,
}

export const ATTEMPT_NUMBERS = {
  [AvailableFortuneWheelAttempts.TWO]: 1,
  [AvailableFortuneWheelAttempts.ONE]: 2,
}
