import styled from 'styled-components'

type TImageProps = {
  imagePath: string
}

export const StyledAgeWithImages = {
  AnswerContent: styled.div<TImageProps>`
    &::after {
      content: '';
      position: absolute;
      width: 96px;
      height: 96px;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      border-radius: 16px;
      background: url(${({ imagePath }) => imagePath});
      background-size: 96px 96px;
      background-repeat: no-repeat;
      background-position: center;
    }
  `,
}
