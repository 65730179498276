import styled from 'styled-components'

import { Color } from 'root-constants'

import { TInputTheme } from './Switch'

export const StyledSwitch = {
  Root: styled.label<TInputTheme>`
    display: flex;
    cursor: pointer;
    width: ${({ small }) => (small ? '44px' : '60px')};
    height: ${({ small }) => (small ? '24px' : '32px')};
    position: relative;
    -webkit-tap-highlight-color: transparent;
  `,
  Input: styled.input<TInputTheme>`
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + span {
      transform: translateX(calc(100% + 1px));
    }

    &:checked ~ div {
      background-color: ${({ themeColor }) => themeColor || Color.PRIMARY};
      border: ${({ themeColor }) =>
        themeColor ? `1px solid ${themeColor}` : `1px solid ${Color.PRIMARY}`};
    }
  `,
  Track: styled.div`
    width: 100%;
    transition: 0.2s;
    border: 1px solid ${Color.ASH_GRAY};
    border-radius: 16px;
    background: ${Color.GRAY_LIGHT};
    transition-property: background, border;
  `,
  Button: styled.span<TInputTheme>`
    position: absolute;
    top: ${({ small }) => (small ? '2px' : '3px')};
    left: ${({ small }) => (small ? '2px' : '3px')};
    width: ${({ small }) => (small ? '20px' : '26px')};
    height: ${({ small }) => (small ? '20px' : '26px')};
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
    transition-property: transform;
  `,
}
