import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'
import { Select } from 'components/Select'

import { setAnswersAction } from 'root-redux/actions/common'
import {
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectAnswers } from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionPrice,
  selectGeneralSubscriptionPriceId,
  selectIsOnboardingSkipped,
} from 'root-redux/selects/user'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { selectBackupPrimerSubscriptionConfig } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  AGE_OPTIONS,
  ALTERNATIVE_GENDER,
  Gender,
  PROBLEMATIC_AREAS_MAP,
  TARGET_AREA_OPTIONS,
} from 'root-constants'

import { StyledFinalReview as S } from './FinishingTouches.styles'
import {
  CHAT_TARGET_AREA_OPTIONS,
  MIN_NAME_LENGTH,
  SENIOR_AGE_OPTIONS,
  SKIN_TYPE_OPTIONS,
} from './constants'

export const FinishingTouches: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const { isSeniorCohort, isChatCohort, isLuvlyChinCohort } =
    useActiveCohortIdentifier()

  const isOnboardingSkipped = useSelector(selectIsOnboardingSkipped)
  const userAnswersCollection = useSelector(selectAnswers)
  const priceId = useSelector(selectGeneralSubscriptionPriceId)
  const price = useSelector(selectGeneralSubscriptionPrice)
  const backupPrimerConfig = useSelector(selectBackupPrimerSubscriptionConfig)

  const defaultAgeValue = useMemo(
    () =>
      [...(isSeniorCohort ? SENIOR_AGE_OPTIONS : AGE_OPTIONS)].find(
        (option) => option.value === userAnswersCollection?.[PageId.AGE],
      ),
    [isSeniorCohort, userAnswersCollection],
  )

  const isNameValid = useMemo(() => {
    if (userAnswersCollection && userAnswersCollection?.[PageId.NAME]) {
      return (
        userAnswersCollection?.[PageId.NAME].trim().length > MIN_NAME_LENGTH
      )
    }
    return true
  }, [userAnswersCollection])

  const skinTypeOptions = useMemo(
    () =>
      SKIN_TYPE_OPTIONS.map((area) => ({
        ...area,
        label: t(area.label),
      })),
    [t],
  )

  const targetAreasOptions = useMemo(
    () =>
      [...(isChatCohort ? CHAT_TARGET_AREA_OPTIONS : TARGET_AREA_OPTIONS)].map(
        (area) => ({
          ...area,
          label: t(area.label),
        }),
      ),
    [isChatCohort, t],
  )

  const defaultSkinTypeValue = useMemo(
    () =>
      skinTypeOptions.find(
        (option) => option.value === userAnswersCollection?.[PageId.SKIN_TYPE],
      ),
    [userAnswersCollection, skinTypeOptions],
  )

  const defaultTargetAreasValues = useMemo(
    () =>
      userAnswersCollection?.[PageId.PROBLEMATIC_AREA]?.map((option) =>
        targetAreasOptions.find((area) => option.label === area.key),
      ),
    [userAnswersCollection, targetAreasOptions],
  )

  useLayoutEffect(() => {
    if (isOnboardingSkipped) {
      goTo({ pathname: PageId.LOGIN, search })
    }
  }, [isOnboardingSkipped, search])

  useEffect(() => {
    if (!isOnboardingSkipped) {
      eventLogger.logFinishingTouchesScreenShow()

      window.axon && window.axon('track', 'page_view')
    }
  }, [isOnboardingSkipped])

  useEffect(() => {
    if (!(priceId && price) && backupPrimerConfig) {
      dispatch(
        sendUserConfigAction({
          payment_currency: backupPrimerConfig.paymentCurrency,
          payment_method: backupPrimerConfig.paymentMethod,
          subscription_price: backupPrimerConfig.subscriptionPrice,
          subscription_duration: backupPrimerConfig.subscriptionDuration,
          price_id: backupPrimerConfig.priceId,
          trial_price: backupPrimerConfig.trialPrice,
          trial_period: backupPrimerConfig.trialPeriod,
        }),
      )
    }
  }, [backupPrimerConfig, dispatch, price, priceId])

  const handleChange = useCallback(
    ({ answers, pageId }) => {
      dispatch(
        setAnswersAction({
          answers,
          pageId,
        }),
      )
    },
    [dispatch],
  )

  const handleConfirm = useCallback(() => {
    dispatch(sendUserAnswersAction(null, true))
    eventLogger.logFinishingTouchesScreenConfirm({
      name: userAnswersCollection?.[PageId.NAME] || '',
      sex: userAnswersCollection?.[PageId.GENDER] || Gender.FEMALE,
      age: userAnswersCollection?.[PageId.AGE] || '20',
      skinType: userAnswersCollection?.[PageId.SKIN_TYPE] || 'normal',
      targetAreas: userAnswersCollection?.[PageId.PROBLEMATIC_AREA] || 'eyes',
    })

    if (userAnswersCollection?.[PageId.AGE]) {
      eventLogger.logUserAgeSelected(userAnswersCollection[PageId.AGE])
    }

    if (userAnswersCollection?.[PageId.GENDER]) {
      eventLogger.logUserGenderSelected(userAnswersCollection[PageId.GENDER])
    }

    goTo({ pathname: PageId.LOGIN, search })
  }, [userAnswersCollection, dispatch, search])

  return (
    <div>
      <S.Root>
        <S.Title>{t`finishingTouches.title`}</S.Title>
        <S.Subtitle>
          <Trans i18nKey="finishingTouches.subtitle" components={[<br />]} />
        </S.Subtitle>
        <S.Name>
          <InputWithFloatPlaceholder
            maxLength={30}
            hasValidationIcon
            isValid={isNameValid}
            onChange={(event) => {
              handleChange({
                answers: event.target.value,
                pageId: PageId.NAME,
              })
            }}
            labelName={t`finishingTouches.userName.label`}
            value={userAnswersCollection?.[PageId.NAME] || ''}
          />
        </S.Name>
        {userAnswersCollection?.[PageId.GENDER] && (
          <S.Gender>
            <S.SectionTitle>{t`finishingTouches.gender.title`}</S.SectionTitle>
            <S.Label htmlFor={Gender.FEMALE}>
              <S.Radio
                onChange={(event) => {
                  handleChange({
                    answers: event.target.value,
                    pageId: PageId.GENDER,
                  })
                }}
                value={Gender.FEMALE}
                defaultChecked={
                  userAnswersCollection?.[PageId.GENDER] === Gender.FEMALE
                }
                name="gender"
                id={Gender.FEMALE}
                type="radio"
              />
              <S.MarkIcon />
              {t`finishingTouches.gender.female`}
            </S.Label>
            <S.Label htmlFor={Gender.MALE}>
              <S.Radio
                onChange={(event) => {
                  handleChange({
                    answers: event.target.value,
                    pageId: PageId.GENDER,
                  })
                }}
                defaultChecked={
                  userAnswersCollection?.[PageId.GENDER] === Gender.MALE
                }
                value={Gender.MALE}
                name="gender"
                id={Gender.MALE}
                type="radio"
              />
              <S.MarkIcon />
              {t`finishingTouches.gender.male`}
            </S.Label>
            {(isLuvlyChinCohort || isSeniorCohort) && (
              <S.Label htmlFor={ALTERNATIVE_GENDER}>
                <S.Radio
                  onChange={(event) => {
                    handleChange({
                      answers: event.target.value,
                      pageId: PageId.GENDER,
                    })
                  }}
                  defaultChecked={
                    userAnswersCollection?.[PageId.GENDER] ===
                    ALTERNATIVE_GENDER
                  }
                  value={ALTERNATIVE_GENDER}
                  name="gender"
                  id={ALTERNATIVE_GENDER}
                  type="radio"
                />
                <S.MarkIcon />
                {t`finishingTouches.gender.other`}
              </S.Label>
            )}
          </S.Gender>
        )}

        <S.List>
          <S.Item>
            {defaultAgeValue && (
              <Select
                placeholder={t`finishingTouches.age.placeholder`}
                options={isSeniorCohort ? SENIOR_AGE_OPTIONS : AGE_OPTIONS}
                defaultValue={defaultAgeValue}
                onChange={(obj) => {
                  handleChange({ answers: obj.value, pageId: PageId.AGE })
                }}
              />
            )}
          </S.Item>
          <S.Item>
            {defaultSkinTypeValue && (
              <Select
                placeholder={t`finishingTouches.skinType.placeholder`}
                defaultValue={defaultSkinTypeValue}
                options={skinTypeOptions}
                onChange={(obj) => {
                  handleChange({
                    answers: obj.value,
                    pageId: PageId.SKIN_TYPE,
                  })
                }}
              />
            )}
          </S.Item>
          <S.Item>
            {defaultTargetAreasValues && (
              <Select
                isMultiSelect
                placeholder={t`finishingTouches.problematicAreas.placeholder`}
                options={targetAreasOptions}
                defaultValue={defaultTargetAreasValues}
                onChange={(arr) => {
                  const notUniqueAreaValues = arr.map((option) =>
                    PROBLEMATIC_AREAS_MAP.find(
                      (area) => area.label === option.key,
                    ),
                  )
                  handleChange({
                    answers: notUniqueAreaValues,
                    pageId: PageId.PROBLEMATIC_AREA,
                  })
                }}
              />
            )}
            {!defaultTargetAreasValues && (
              <Select
                isMultiSelect
                placeholder={t`finishingTouches.problematicAreas.placeholder`}
                options={targetAreasOptions}
                onChange={(arr) => {
                  const notUniqueAreaValues = arr.map((option) =>
                    PROBLEMATIC_AREAS_MAP.find(
                      (area) => area.label === option.key,
                    ),
                  )
                  handleChange({
                    answers: notUniqueAreaValues,
                    pageId: PageId.PROBLEMATIC_AREA,
                  })
                }}
              />
            )}
          </S.Item>
        </S.List>
      </S.Root>
      <S.Action>
        <Button
          disabled={
            !userAnswersCollection ||
            !userAnswersCollection?.[PageId.PROBLEMATIC_AREA]?.length ||
            !isNameValid
          }
          onClick={handleConfirm}
        >
          {t`finishingTouches.action`}
        </Button>
      </S.Action>
    </div>
  )
}
