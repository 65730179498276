import amelia from 'assets/images/amelia-before-after.png'
import charlotte from 'assets/images/charlotte-before-after.png'
import diana from 'assets/images/diana-before-after.png'
import marissa from 'assets/images/marissa-before-after.png'

export const COACHING_BEFORE_AFTER = [
  {
    img: diana,
    title: 'upsellCoaching.beforeAfter.diana',
  },
  {
    img: marissa,
    title: 'upsellCoaching.beforeAfter.marissa',
  },
  {
    img: amelia,
    title: 'upsellCoaching.beforeAfter.amelia',
  },
  {
    img: charlotte,
    title: 'upsellCoaching.beforeAfter.charlotte',
  },
]
