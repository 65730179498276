import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import {
  selectActiveUpsellPages,
  selectUserStatus,
} from 'root-redux/selects/user'

import { useUserStatus } from 'hooks/useUserStatus'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import {
  DYNAMIC_PAGES_MAP,
  DYNAMIC_PAGES_SET_VARIANT_1,
  EMAIL_CONSENT_PAGES_MAP,
  PURCHASE_PAGES_MAP,
  PageId,
} from 'page-constants'
import {
  COHORT_TO_TEST_MAP,
  Cohort,
  LIVECHAT_UPGRADE_COHORTS,
  OPEN_SANS_COHORTS,
  PAGES_WITHOUT_HEADER,
  POST_PAYMENT_PAGES,
} from 'root-constants'

export const useGetPageInfo = () => {
  const { pathname } = useLocation()
  const availableRoute = useUserStatus()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const userStatus = useSelector(selectUserStatus)
  const dynamicUpsellPages = useSelector(selectActiveUpsellPages)

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )
  const cohortToUse = (parentCohort || cohort) as Cohort

  const isAlternativePagesMap = useFeatureIsOn(
    COHORT_TO_TEST_MAP[cohortToUse] || '',
  )

  const isPagesMapTestRunning = useMemo(
    () => Object.keys(COHORT_TO_TEST_MAP).includes(cohortToUse),
    [cohortToUse],
  )

  const currentVariantPageList = useMemo(() => {
    if (isPagesMapTestRunning) {
      return isAlternativePagesMap
        ? DYNAMIC_PAGES_MAP[cohortToUse][1]
        : DYNAMIC_PAGES_MAP[cohortToUse][0]
    }

    return (
      (DYNAMIC_PAGES_MAP[cohortToUse] && DYNAMIC_PAGES_MAP[cohortToUse][0]) ||
      DYNAMIC_PAGES_SET_VARIANT_1
    )
  }, [cohortToUse, isPagesMapTestRunning, isAlternativePagesMap])

  const progressValue = useMemo(
    () =>
      currentVariantPageList.find(({ id }) => id === currentPageId)
        ?.progressValue,
    [currentPageId, currentVariantPageList],
  )

  const hasNavigation = useMemo(
    () =>
      !!currentVariantPageList.find(({ id }) => id === currentPageId)
        ?.hasNavigation,
    [currentPageId, currentVariantPageList],
  )
  const hasProgressBar = useMemo(
    () =>
      !!currentVariantPageList.find(({ id }) => id === currentPageId)
        ?.hasProgressBar,
    [currentPageId, currentVariantPageList],
  )
  const hasMinimalisticHeader = useMemo(
    () =>
      !!currentVariantPageList.find(({ id }) => id === currentPageId)
        ?.hasMinimalisticHeader,
    [currentPageId, currentVariantPageList],
  )

  const hasSubscription = useMemo(
    () => POST_PAYMENT_PAGES.includes(currentPageId),
    [currentPageId],
  )

  const hasHeader = useMemo(() => {
    if (currentPageId.includes('unsubscribe')) return false
    return !PAGES_WITHOUT_HEADER.includes(currentPageId)
  }, [currentPageId])

  const pagesNumberWithNavigation = useMemo(
    () =>
      currentVariantPageList.filter(
        ({ hasNavigation: isPageWithNavigation }) => isPageWithNavigation,
      ).length,
    [currentVariantPageList],
  )

  const isPurchase = useMemo(
    () => availableRoute === PURCHASE_PAGES_MAP[cohortToUse],
    [availableRoute, cohortToUse],
  )

  const isEmailConsent = useMemo(
    () =>
      availableRoute === EMAIL_CONSENT_PAGES_MAP[cohortToUse] ||
      availableRoute === PageId.EMAIL_CONSENT,
    [availableRoute, cohortToUse],
  )

  const isUpsell = useMemo(
    () =>
      dynamicUpsellPages.includes(availableRoute as PageId) ||
      availableRoute === PageId.UPSELL_PAYWALL,
    [availableRoute, dynamicUpsellPages],
  )

  const isWellnessAppsBundleAvailable = useMemo(
    () => userStatus?.hasSubscription || false,
    [userStatus?.hasSubscription],
  )

  const isUpgradeAvailable = useMemo(() => {
    return (
      (userStatus?.upgrade.isUpgradeAvailable &&
        !userStatus?.upgrade.isUpgradePassed &&
        LIVECHAT_UPGRADE_COHORTS.includes(cohortToUse)) ||
      false
    )
  }, [userStatus, cohortToUse])

  const applyOpenSans = OPEN_SANS_COHORTS.includes(cohortToUse)

  return {
    currentPageId,
    currentVariantPageList,
    progressValue,
    hasNavigation,
    hasSubscription,
    hasHeader,
    hasMinimalisticHeader,
    pagesNumberWithNavigation,
    isPurchase,
    hasProgressBar,
    isUpsell,
    isWellnessAppsBundleAvailable,
    isUpgradeAvailable,
    isEmailConsent,
    applyOpenSans,
  }
}
