import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUserAvailableSpins } from 'root-redux/selects/user'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { CURRENCY_SYMBOLS, Currency } from 'modules/purchase/constants'
import { getFormattedPriceValue } from 'modules/purchase/helpers/rootHelpers'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import {
  AvailableFortuneWheelAttempts,
  Locale,
  MINIMUM_COUNT_OF_MONTH,
  SubscriptionProduct,
} from 'root-constants'

import { StyledColoredSelectSubscriptionItem as S } from './ColoredSelectSubscriptionItem.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}
export const ColoredSelectSubscriptionItem: React.FC<TProps> = ({
  subscription: { id, isDefault, mainPrices, trialPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()

  const taxAmount = useSelector(selectTaxAmount)
  const language = useSelector(selectLanguage)
  const availableSpins = useSelector(selectUserAvailableSpins)

  const isEnLocale = language === Locale.ENGLISH

  const isMxnCurrency = currency === Currency.MXN
  const isJpyCurrency = currency === Currency.JPY

  const getCalculatedPrice = useCallback(
    (fullPrice: number) => {
      if (!hasVatInfo) return fullPrice

      return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
    },
    [hasVatInfo, taxAmount],
  )

  const monthlyPeriodKey =
    mainPrices.periodQuantity > MINIMUM_COUNT_OF_MONTH
      ? 'purchaseIntroOffer.multiMonthlyPeriod'
      : 'purchaseIntroOffer.monthlyPeriod'

  const renderOldDailyPrice = () => {
    if (isJpyCurrency || isMxnCurrency || !trialPrices.oldPrices) {
      return null
    }

    const oldDailyPrice = getCalculatedPrice(trialPrices.oldPrices.daily)

    return (
      <S.OldestPrice>
        <Trans
          i18nKey="purchaseIntroOffer.subscription.oldestPrice"
          values={{
            oldestPrice: getFormattedPriceValue(oldDailyPrice, currency),
            currency: CURRENCY_SYMBOLS[currency],
          }}
        />
      </S.OldestPrice>
    )
  }

  const renderOldPrice = () => {
    if (
      availableSpins === AvailableFortuneWheelAttempts.TWO ||
      !trialPrices.oldPrices?.fullPrice
    ) {
      return null
    }

    const oldPrices =
      trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY
        ? getCalculatedPrice(trialPrices.oldPrices.fullPrice)
        : getCalculatedPrice(mainPrices.fullPrice)

    return (
      <S.PreviousPrice isMxnCurrency={isMxnCurrency}>
        <Trans
          i18nKey="purchaseIntroOffer.subscription.oldPrice"
          values={{
            oldPrice: getFormattedPriceValue(oldPrices, currency),
            currency: CURRENCY_SYMBOLS[currency],
          }}
        />
      </S.PreviousPrice>
    )
  }

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      {isDefault && (
        <S.MostPopularBadge availableSpins={availableSpins}>
          {t`purchaseIntroOffer.subscription.mostPopularBadge`}
        </S.MostPopularBadge>
      )}
      <S.Content
        isSelected={isSelected}
        isEnLocaleStyle={isEnLocale}
        availableSpins={availableSpins}
      >
        <S.PlanContainer isEnLocaleStyle={isEnLocale}>
          <S.PlanPeriod isSelected={isSelected}>
            {trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY ||
            mainPrices.periodQuantity === SubscriptionProduct.SEVEN_DAY
              ? t('purchaseIntroOffer.weeklyPeriod', {
                  trialPeriodDays:
                    trialPrices.durationDays || mainPrices.periodQuantity,
                })
              : t(monthlyPeriodKey, {
                  count: mainPrices.periodQuantity,
                })}
          </S.PlanPeriod>
          <S.PricesComparisonWrapper>
            {renderOldPrice()}
            <S.CurrentPrice isMxnCurrency={isMxnCurrency}>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.price"
                values={{
                  price: getFormattedPriceValue(
                    getCalculatedPrice(
                      trialPrices.fullPrice || mainPrices.fullPrice,
                    ),
                    currency,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
            {renderOldDailyPrice()}
          </S.PricesComparisonWrapper>
        </S.PlanContainer>
        <S.CustomPrice
          isSelected={isSelected}
          isMxnCurrency={isMxnCurrency}
          availableSpins={availableSpins}
        >
          <S.PriceValue currency={currency} language={language}>
            {t('purchaseIntroOffer.subscription.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: getCalculatedPrice(trialPrices.daily || mainPrices.daily),
            })}
          </S.PriceValue>
          <S.Period>
            {t('purchase1.subscription.per', { context: 'day' })}
            {t`purchase1.subscription.day`}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
