import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'storybook-ui'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import afterBeforeImage from 'assets/images/after-before-first-result.png'

import { StickyButtonContainer } from 'common-styles'

import { BUTTONS_PROPS } from '../TriedKoreanBeauty/constants'
import { StyledRealTransformation as S } from './RealTransformation.styles'
import { QUESTION } from './constants'

export const RealTransformation: FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <div>
      <S.Container>
        <S.PageTitle marginBottom={16}>
          <Trans i18nKey="onboarding.realTransformations.title" />
        </S.PageTitle>

        <S.Image src={afterBeforeImage} alt="after/before" />

        <S.Review marginBottom={12}>
          <Trans i18nKey="onboarding.realTransformations.review" />
        </S.Review>

        <S.Review>
          <Trans i18nKey="onboarding.realTransformations.author" />
        </S.Review>
      </S.Container>

      <StickyButtonContainer centerMode>
        <Button {...BUTTONS_PROPS} onClick={handleNextClick}>
          {t`actions.continue`}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
