import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledReviews = {
  Root: styled.div`
    font-family: 'Open Sans';
    margin-bottom: 32px;
  `,
  Card: styled.div`
    border-radius: 20px;
    background-color: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px #e8e8e8;
    padding: 20px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Caption: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: ${Color.GRAY};
    font-family: 'Open Sans';
  `,
  Rating: styled.div`
    display: flex;
  `,
  Title: styled.h3`
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 8px;
  `,
  Text: styled.p`
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 8px;
  `,
  Separator: styled.div`
    width: 60px;
    height: 1px;
    background-color: #c4c4c4;
    margin-bottom: 8px;
  `,
  Name: styled.h4`
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: ${Color.BLUE_DARK};
  `,
  Action: styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    & button {
      all: unset;
      color: ${Color.CORAL};
      font-family: 'Open Sans';
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 133.333% */
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-align: right;
      cursor: pointer;
    }
  `,
}
