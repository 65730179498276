import { getAxonItem } from 'helpers/getAxonItems'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { TProductId } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { ScreenName, TimeInterval } from 'root-constants'

const enum FacebookPixelEvents {
  PURCHASE = 'Purchase',
  SUBSCRIBE = 'Subscribe',
  START_TRIAL = 'StartTrial',
}
function handleLogFacebookPixelEvent({
  price,
  subscriptionId,
  uuid,
  eventType,
  currency,
}: {
  price: number
  subscriptionId: string
  uuid: string
  eventType: string
  currency: string
}): void {
  window.fbq(
    'track',
    eventType,
    {
      value: price,
      currency,
      subscription_id: subscriptionId,
      subscription_sid: subscriptionId,
    },
    { eventID: uuid },
  )
}

function handleLogPurchaseCompleted({
  productId,
  price,
  paymentMethod,
  currency,
  screenName,
  isTrialActive,
  paymentSystem,
  stripeAccountId,
  stripeAccountName,
}: {
  productId: string
  price: number
  paymentMethod: PaymentMethod
  currency: string
  screenName: ScreenName
  isTrialActive?: boolean
  paymentSystem?: PaymentSystem
  stripeAccountId: string
  stripeAccountName: string
}): void {
  eventLogger.logPurchaseCompleted({
    productId,
    priceDetails: {
      price,
      currency,
      trial: isTrialActive,
    },
    paymentMethod,
    screenName,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
  })
}

function handleLogGoogleAnalyticsEvent({
  subscriptionId,
  price,
  periodName,
  periodQuantity,
  currency,
  screenName,
}: {
  subscriptionId: string
  price: number
  periodName: TimeInterval | null
  periodQuantity: number | null
  currency: string
  screenName: ScreenName
}): void {
  googleAnalyticsLogger.logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  })
}

function handleLogTikTokEvent({
  email,
  price,
  trialPrice,
  currency,
  subscriptionId,
  uuid,
}: {
  email: string
  price: number
  trialPrice: number
  currency: string
  subscriptionId: string
  uuid: string
}) {
  window.ttq.identify({ email })
  window.ttq.track('CompletePayment', {
    value: trialPrice || price,
    currency: currency.toUpperCase(),
    content_id: subscriptionId,
    event_id: uuid,
  })
}

function handleLogSnapchatEvent({
  email,
  price,
  trialPrice,
  currency,
  subscriptionId,
}: {
  email: string
  price: number
  trialPrice: number
  currency: string
  subscriptionId: string
}) {
  window.snaptr &&
    window.snaptr('track', 'PURCHASE', {
      price: trialPrice || price,
      currency,
      client_dedup_id: subscriptionId,
      transaction_id: subscriptionId,
      user_email: email,
    })
}

function handleLogOutbrainEvent({
  price,
  trialPrice,
  currency,
  subscriptionId,
}: {
  price: number
  trialPrice: number
  currency: string
  subscriptionId: string
}) {
  window.obApi &&
    window.obApi('track', 'Purchase', {
      orderValue: trialPrice || price,
      orderId: subscriptionId,
      currency: currency.toUpperCase(),
    })
}

function handleLogTaboolaEvent({
  price,
  trialPrice,
  currency,
  subscriptionId,
}: {
  price: number
  trialPrice: number
  currency: string
  subscriptionId: string
}) {
  window._tfa &&
    window._tfa.push({
      notify: 'event',
      name: 'make_purchase',
      revenue: trialPrice || price,
      orderId: subscriptionId,
      currency: currency.toUpperCase(),
    })
}

export const logSuccessfulPayPalPayment = ({
  productId,
  price,
  subscriptionId,
  uuid,
  periodName,
  periodQuantity,
  paymentMethod,
  currency,
  trialPrice,
  trialPeriodDays,
  screenName,
  isTrialActive,
  email,
  stripeAccountId,
  stripeAccountName,
  paymentSystem,
  predictedLtv,
  isPersonalDataAllowed,
}: {
  productId: TProductId
  price: number
  subscriptionId: string
  uuid: string
  periodName: TimeInterval | null
  periodQuantity: number | null
  paymentMethod: PaymentMethod
  currency: string
  trialPrice: number
  trialPeriodDays: number
  screenName: ScreenName
  isTrialActive?: boolean
  email: string
  paymentSystem?: PaymentSystem
  stripeAccountId: string
  stripeAccountName: string
  predictedLtv?: number
  isPersonalDataAllowed?: boolean
}): void => {
  handleLogFacebookPixelEvent({
    price: predictedLtv || trialPeriodDays ? trialPrice : price,
    subscriptionId,
    uuid,
    eventType: FacebookPixelEvents.PURCHASE,
    currency,
  })

  handleLogFacebookPixelEvent({
    price: trialPeriodDays ? trialPrice : price,
    subscriptionId,
    uuid,
    eventType: FacebookPixelEvents.SUBSCRIBE,
    currency,
  })

  if (trialPeriodDays) {
    handleLogFacebookPixelEvent({
      price: trialPrice,
      subscriptionId,
      uuid,
      eventType: FacebookPixelEvents.START_TRIAL,
      currency,
    })
  }

  handleLogPurchaseCompleted({
    productId,
    price,
    paymentMethod,
    currency,
    screenName,
    isTrialActive,
    paymentSystem,
    stripeAccountId,
    stripeAccountName,
  })

  handleLogGoogleAnalyticsEvent({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  })

  handleLogTikTokEvent({
    email,
    price,
    trialPrice,
    currency,
    subscriptionId,
    uuid,
  })

  handleLogSnapchatEvent({
    email,
    price,
    trialPrice,
    currency,
    subscriptionId,
  })

  handleLogOutbrainEvent({
    price,
    trialPrice,
    currency,
    subscriptionId,
  })

  handleLogTaboolaEvent({
    price,
    trialPrice,
    currency,
    subscriptionId,
  })

  window.rdt &&
    window.rdt('track', 'Purchase', {
      value: trialPrice || price,
      currency,
      transactionId: subscriptionId,
      email,
      externalId: uuid,
    })

  window.axon &&
    window.axon('track', 'purchase', {
      value: trialPrice || price,
      currency: currency.toUpperCase(),
      user_id: isPersonalDataAllowed ? uuid : '',
      items: [
        getAxonItem({
          id: subscriptionId,
          periodQuantity,
          periodName,
          trialPrice,
          mainPrice: price,
        }),
      ],
    })
}
