import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ratingImage from 'assets/images/review-stars.png'

import { StyledLongPaywallCustomerReview as S } from './LongPaywallCustomerReview.styles'

type TLongPaywallCustomerReview = {
  review: string
  name: string
  emoji?: string[]
}

export const LongPaywallCustomerReview: React.FC<
  TLongPaywallCustomerReview
> = ({ review, name, emoji }: TLongPaywallCustomerReview) => {
  const { t } = useTranslation()

  return (
    <S.Card>
      <S.CardRating src={ratingImage} alt="" />
      <S.CustomerReview>
        <Trans
          i18nKey={review}
          components={{
            emoji: (
              <S.EmojiContainer>
                {emoji?.map((el) => (
                  <S.Emoji key={el} src={el} alt="emoji" />
                ))}
              </S.EmojiContainer>
            ),
          }}
        />
      </S.CustomerReview>
      <S.Reviewer>{t(name)}</S.Reviewer>
    </S.Card>
  )
}
