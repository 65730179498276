import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { OPTION_VALUES } from 'pages/intro/constants'
import { Button, buttonTheme } from 'storybook-ui'

import { AnswerButtonWithImage2 } from 'components/AnswerButtonWithImage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { setAnswersAction } from 'root-redux/actions/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, StickyButtonContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { StyledAgeRelatedChanges as S } from './AgeRelatedChanges.styles'
import { OPTIONS, QUESTION } from './constants'

export const AgeRelatedChanges: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [answers, setAnswers] = useState<string[]>([])
  const { handleChange } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    const uniqueAnswers = [...new Set(answers)]
    handleChange(uniqueAnswers, handleContinue)
    dispatch(
      setAnswersAction({
        answers: OPTION_VALUES.REDUCE_FAT,
        pageId: PageId.INTRO,
      }),
    )
  }

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <Container>
      <DoubleChinTitle>
        <Trans i18nKey="onboarding.ageRelatedChanges.question" />
      </DoubleChinTitle>

      <S.OptionsContainer>
        {OPTIONS.map(({ value, image, text }) => {
          return (
            <Option {...optionProps} value={value} key={value}>
              <AnswerButtonWithImage2 text={t(text)} imageSrc={image} />
            </Option>
          )
        })}
      </S.OptionsContainer>
      <StickyButtonContainer
        customBackground="linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    )"
        centerMode
      >
        <Button
          onClick={handleNextClick}
          disabled={!answers.length}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
