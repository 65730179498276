import { useMemo } from 'react'

import { Cohort, DYNAMIC_DISCOUNT_COHORTS } from 'root-constants'

import { useCohortToUse } from './useCohortToUse'

export const useActiveCohortIdentifier = () => {
  const cohortToUse = useCohortToUse()

  return useMemo(
    () => ({
      isLuvlyIntroFastCohort: cohortToUse === Cohort.LUVLY_INTRO_FAST,
      isCosmeticCohort: cohortToUse === Cohort.LUVLY_COSMETIC_SCANNER,
      isSeniorCohort: cohortToUse === Cohort.LUVLY_12_SALE,
      isWellnessBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE,
      isChatCohort: cohortToUse === Cohort.LUVLY_CHAT,
      isUpgradeBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF,
      isDynamicDiscountCohort: DYNAMIC_DISCOUNT_COHORTS.includes(cohortToUse),
      isLuvlyIntroSaleCohort: cohortToUse === Cohort.LUVLY_INTRO_SALE,
      isLuvly12SaleCohort: cohortToUse === Cohort.LUVLY_12_SALE,
      isIntro3SaleCohort: cohortToUse === Cohort.LUVLY_INTRO3_SALE,
      isRefundCohort: cohortToUse === Cohort.LUVLY_REF,
      isLuvlyCancelIntro1Cohort: cohortToUse === Cohort.LUVLY_CANCEL_INTRO_1,
      isLuvlyChinCohort: cohortToUse === Cohort.LUVLY_CHIN,
      isLuvlyEmailSaleCohort: cohortToUse === Cohort.LUVLY_EMAIL_SALE,
      isLuvlyInfluencersCohort: cohortToUse === Cohort.LUVLY_INFLUENCERS,
      isLuvlyGiftCardCohort: cohortToUse === Cohort.LUVLY_GIFT,
      isLuvlyKoreanCohort: cohortToUse === Cohort.LUVLY_KOREAN,
      isLuvlyEmailNYCohort: cohortToUse === Cohort.LUVLY_EMAIL_NY,
      isLuvlyWheelCohort: cohortToUse === Cohort.LUVLY_WHEEL,
      isLuvly21Cohort: cohortToUse === Cohort.LUVLY_21,
    }),
    [cohortToUse],
  )
}
