import React from 'react'
import { useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import badgeImg from 'assets/images/sprite/badge.svg'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuarantee.styles'

export const MoneyBackGuarantee: React.FC = () => {
  const { t } = useTranslation()
  return (
    <S.Root>
      <S.Container>
        <S.Title>{t`purchase1.moneyBackGuarantee.title`}</S.Title>
        <S.Text>
          {t('purchase1.moneyBackGuarantee.text')} <br />
          <TermsOfUseLink />
        </S.Text>
        <S.Badge svg={badgeImg} />
      </S.Container>
    </S.Root>
  )
}
