import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useStartSession = (trackEvents = true): void => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const email = useSelector(selectUserOnboardingEmail)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  useEffect(() => {
    if (trackEvents) {
      eventLogger.logSessionStarted(language)
    }
  }, [dispatch, trackEvents, language])

  useEffect(() => {
    if (trackEvents && uuid) {
      window.fbq('track', 'ViewContent', {}, { eventID: uuid })
      window.rdt &&
        window.rdt('track', 'ViewContent', {
          email: isPersonalDataAllowed ? email : '',
          externalId: uuid,
        })
    }
  }, [uuid, email, isPersonalDataAllowed, trackEvents])
}
