import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledHeaderGift = {
  Wrapper: styled.header`
    position: relative;
    width: 100%;
    background-color: ${Color.WHITE};
    padding: 14px 8px;
    display: flex;
    justify-content: center;
    z-index: 3;
  `,
  Arrow: styled(SvgImage)`
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
  `,
}
