import React from 'react'
import { Trans } from 'react-i18next'

import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import { PageId } from 'page-constants'

import { StyledUpsellCoachingCancelOfferBanner as S } from './UpsellCoachingCancelOfferBanner.styles'

export const UpsellCoachingCancelOfferBanner: React.FC = () => {
  const isCoachingCancelOffer = useIsCurrentRoute(
    PageId.COACHING_CANCEL_PAYWALL,
  )

  if (!isCoachingCancelOffer) return null

  return (
    <S.Root>
      <S.Text>
        <Trans i18nKey="upsellCoaching.cancelOfferBanner" />
      </S.Text>
    </S.Root>
  )
}
