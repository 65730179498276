import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'
import { getSupportFormLink } from 'helpers/getSupportFormLink'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { getFormattedPriceValue } from 'modules/purchase/helpers/rootHelpers'
import { usePurchaseStore } from 'modules/purchase/hooks'

import { SubscriptionProduct, TimeInterval } from 'root-constants'

import { StyledPurchaseDisclaimer as S } from './PurchaseDisclaimer.styles'
import {
  PERIOD_NAME_TRANSLATIONS_MAP,
  PURCHASE_INTRO_OFFER_DISCLAIMER,
  PURCHASE_INTRO_OFFER_DISCLAIMER_WITHOUT_TAXES,
  PURCHASE_INTRO_SALE_DISCLAIMER,
  PURCHASE_INTRO_SALE_DISCLAIMER_WITHOUT_TAXES,
  PURCHASE_MAIN_OFFER_DISCLAIMER,
  PURCHASE_MAIN_OFFER_DISCLAIMER_WITHOUT_TAXES,
} from './constants'

type TPurchaseDisclaimerProps = {
  marginBottom?: number
  className?: string
  style?: React.CSSProperties
}

export const PurchaseDisclaimer: React.FC<TPurchaseDisclaimerProps> = ({
  marginBottom,
  className,
  style,
}: TPurchaseDisclaimerProps) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()
  const {
    periodName,
    periodQuantity,
    price,
    currency,
    trialPrice,
    trialPeriodDays,
    taxAmount,
  } = usePurchaseStore()
  const contactFormLink = getSupportFormLink()
  const { isDynamicDiscountCohort, isLuvlyWheelCohort } =
    useActiveCohortIdentifier()

  const localizationKeys = useMemo(() => {
    if (isLuvlyWheelCohort && !trialPeriodDays) {
      return hasVatInfo
        ? PURCHASE_MAIN_OFFER_DISCLAIMER_WITHOUT_TAXES
        : PURCHASE_MAIN_OFFER_DISCLAIMER
    }

    if (isDynamicDiscountCohort) {
      return hasVatInfo
        ? PURCHASE_INTRO_SALE_DISCLAIMER_WITHOUT_TAXES
        : PURCHASE_INTRO_SALE_DISCLAIMER
    }

    if (hasVatInfo) {
      return PURCHASE_INTRO_OFFER_DISCLAIMER_WITHOUT_TAXES
    }
    return PURCHASE_INTRO_OFFER_DISCLAIMER
  }, [hasVatInfo, isDynamicDiscountCohort, isLuvlyWheelCohort, trialPeriodDays])

  const disclaimerTextPath = useMemo(() => {
    if (isLuvlyWheelCohort && !trialPeriodDays) {
      const period =
        periodName === TimeInterval.MONTH
          ? periodQuantity * SubscriptionProduct.ONE_MONTH
          : periodQuantity
      return t(localizationKeys[period], { context: currency })
    }

    if (!trialPeriodDays) return ''

    return t(localizationKeys[trialPeriodDays], { context: currency })
  }, [
    isLuvlyWheelCohort,
    trialPeriodDays,
    t,
    localizationKeys,
    currency,
    periodQuantity,
    periodName,
  ])

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice

    return getCalculatedPriceWithoutVAT(fullPrice, taxAmount)
  }

  const periodNameText = useMemo(() => {
    return t(PERIOD_NAME_TRANSLATIONS_MAP[periodName || TimeInterval.MONTH], {
      count: periodQuantity,
    })
  }, [periodName, periodQuantity, t])

  return (
    <S.Wrapper className={className} marginBottom={marginBottom} style={style}>
      <Trans
        i18nKey={disclaimerTextPath}
        values={{
          price: getFormattedPriceValue(getCalculatedPrice(price), currency),
          trialPrice: getFormattedPriceValue(
            getCalculatedPrice(trialPrice),
            currency,
          ),
          periodQuantity,
          periodName: periodNameText,
          currency: CURRENCY_SYMBOLS[currency],
        }}
      />{' '}
      <TermsOfUseLink /> {t`commonComponents.or`}{' '}
      <a target="_blank" rel="noopener noreferrer" href={contactFormLink}>
        {t`commonComponents.contactSupportDisclaimer`}
      </a>
    </S.Wrapper>
  )
}
