import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/almost-5-stars.png'
import harvardLogo from 'assets/images/harvard-medical-school-logo-v2.png'
import manResult1 from 'assets/images/man-before-after-1.png'
import manResult2 from 'assets/images/man-before-after-2.png'
import womanResult1 from 'assets/images/woman-before-after-1.png'
import womanResult2 from 'assets/images/woman-before-after-2.png'

import { CustomBackgroundStyles, StickyButtonContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { Gender, SKIN_COLOR_OPTION_VALUES } from 'root-constants'

import { StyledWeWantYouLookBest as S } from './WeWantYouLookBest.styles'
import { QUESTION } from './constants'

export const WeWantYouLookBest: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userGender = userAnswers?.[PageId.GENDER]
  const skinColor = userAnswers?.[PageId.IDENTIFY_SKIN_COLOR]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  const beforeAfterImage = () => {
    if (skinColor === SKIN_COLOR_OPTION_VALUES.BROWN_OR_BLACK_BROWN) {
      return userGender === Gender.MALE ? manResult1 : womanResult1
    }

    return userGender === Gender.MALE ? manResult2 : womanResult2
  }

  return (
    <S.Root background={CustomBackgroundStyles.PINK_TO_YELLOW}>
      <Container>
        <S.Title>
          <Trans i18nKey="onboarding.weWantYouLookBest.question" />
        </S.Title>
        <S.AppNumberOne />
        <S.StarsWrapper>
          <img src={stars} alt="stars" />
          4.5 / 5
        </S.StarsWrapper>
        <S.HarvardApproval>
          <S.HarvardLogo src={harvardLogo} />
          <S.Text>
            {t('onboarding.weWantYouLookBest.medicalSchoolApproved')}
          </S.Text>
        </S.HarvardApproval>
      </Container>
      <S.BeforeAfterImage src={beforeAfterImage()} alt="before-after" />
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.soundsGood')}
        </Button>
      </StickyButtonContainer>
    </S.Root>
  )
}
