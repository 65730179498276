import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'
import { EffectCreative, Navigation, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperRef, SwiperSlide } from 'swiper/react'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import card1 from 'assets/images/intro-customize-card-1.png'
import card2 from 'assets/images/intro-customize-card-2.png'
import card3 from 'assets/images/intro-customize-card-3.png'

import { PageId } from 'page-constants'

import { StyledCustomizeGiftCard as S } from './CustomizeGiftCard.styles'
import {
  GIFT_CARDS,
  NAME_MAX_LENGTH,
  QUESTION,
  WISH_MAX_LENGTH,
} from './constants'

export const CustomizeGiftCard: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const userAnswers = useSelector(selectAnswers)
  const userAnswer = userAnswers?.[PageId.CUSTOMIZE_GIFT_CARD]

  const swiperRef = useRef<SwiperRef>(null)

  const [activeCardType, setActiveCardType] = useState(GIFT_CARDS[0])

  const {
    register,
    watch,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      wish: '',
    },
  })

  useEffect(() => {
    userAnswer?.name && setValue('name', userAnswer?.name)
    userAnswer?.wish && setValue('wish', userAnswer?.wish)
    userAnswer?.cardType && setActiveCardType(userAnswer?.cardType)
  }, [userAnswer, setValue])

  useEffect(() => {
    const initialSlideIndex = GIFT_CARDS.indexOf(activeCardType)
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(initialSlideIndex)
    }
  }, [activeCardType])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
    trackEvent: false,
  })

  const onSubmit = (data) => {
    eventLogger.logGiftCustomizePageContinued()
    handleContinue({ ...data, cardType: activeCardType })
  }

  return (
    <S.Root>
      <S.Title>{t`onboarding.customizeGiftCard.title`}</S.Title>

      <S.SwiperContainer>
        <Carousel
          onSlideChange={(swiper) => {
            setActiveCardType(GIFT_CARDS[swiper.activeIndex])
          }}
          ref={swiperRef}
          slidesPerView="auto"
          centeredSlides
          modules={[Pagination, Navigation, EffectCreative]}
          navigation
          pagination={{
            clickable: true,
          }}
          effect="creative"
          creativeEffect={{
            prev: {
              scale: 0.8,
              translate: ['-100%', 0, 0],
            },
            next: {
              scale: 0.8,
              translate: ['100%', 0, 0],
            },
          }}
        >
          <SwiperSlide>
            <img src={card1} alt="card1" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={card2} alt="card2" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={card3} alt="card3" />
          </SwiperSlide>
        </Carousel>
      </S.SwiperContainer>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Content>
          <S.InputContainer data-error-text={errors.name?.message}>
            <S.Input
              {...register('name', {
                validate: {
                  maxLength: (v) =>
                    v.length <= NAME_MAX_LENGTH ||
                    t`onboarding.customizeGiftCard.nameError`,
                },
              })}
              placeholder={t`onboarding.customizeGiftCard.inputPlaceholder`}
            />
          </S.InputContainer>

          <S.TextAreaContainer data-error-text={errors.wish?.message}>
            <S.ReplicatedText>{watch('wish')}</S.ReplicatedText>
            <S.Textarea
              {...register('wish', {
                validate: {
                  maxLength: (v) =>
                    v.length <= WISH_MAX_LENGTH ||
                    t`onboarding.customizeGiftCard.wishError`,
                },
              })}
              placeholder={t`onboarding.customizeGiftCard.textareaPlaceholder`}
            />
          </S.TextAreaContainer>
        </S.Content>

        <S.StickyButtonContainer>
          <Button
            type="submit"
            theme={buttonTheme.LUVLY_SECONDARY}
            disabled={!isValid}
          >
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Form>
    </S.Root>
  )
}
