import React, { useMemo } from 'react'

import { ThemeProvider } from 'styled-components'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import {
  CHECKOUT_CORAL_THEME,
  CHECKOUT_DEFAULT_THEME,
} from '../../styles/themes/checkout'
import { CORAL_THEME, DEFAULT_THEME } from '../../styles/themes/common'
import {
  PURCHASE_VARIANT_7_THEME,
  PURCHASE_VARIANT_8_THEME,
  PURCHASE_VARIANT_9_THEME,
} from '../../styles/themes/paywall'

type TProps = {
  children: React.ReactNode
}

export const Theme: React.FC<TProps> = ({ children }) => {
  const { isLuvlyChinCohort, isLuvlyEmailNYCohort } =
    useActiveCohortIdentifier()

  const currentTheme = useMemo(() => {
    if (isLuvlyChinCohort) {
      return {
        ...CORAL_THEME,
        ...PURCHASE_VARIANT_8_THEME,
        checkout: CHECKOUT_CORAL_THEME,
      }
    }

    if (isLuvlyEmailNYCohort) {
      return {
        ...DEFAULT_THEME,
        ...PURCHASE_VARIANT_9_THEME,
        checkout: CHECKOUT_DEFAULT_THEME,
      }
    }

    return {
      ...DEFAULT_THEME,
      ...PURCHASE_VARIANT_7_THEME,
      checkout: CHECKOUT_DEFAULT_THEME,
    }
  }, [isLuvlyChinCohort, isLuvlyEmailNYCohort])

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}
