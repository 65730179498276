export const QUESTION =
  'In your opinion, what is the cause of your double chin?'

export const OPTION_VALUES = {
  WEIGHT_GAIN: 'weight_gain',
  GENETICS: 'genetics',
  SAGGING: 'sagging',
  WATER_RETENTION: 'water_retention',
  POOR_POSTURE: 'poor_posture',
  INCORRECT_TONGUE_POSITION: 'incorrect_tongue_position',
  DONT_KNOW: 'dont_know',
}

export const DOUBLE_CHIN_CAUSE_ANSWERS = [
  {
    text: 'onboarding.doubleChinCause.option1',
    detailed: 'onboarding.doubleChinCause.detailed1',
    label: OPTION_VALUES.WEIGHT_GAIN,
  },
  {
    text: 'onboarding.doubleChinCause.option2',
    detailed: 'onboarding.doubleChinCause.detailed2',
    label: OPTION_VALUES.GENETICS,
  },
  {
    text: 'onboarding.doubleChinCause.option3',
    detailed: 'onboarding.doubleChinCause.detailed3',
    label: OPTION_VALUES.SAGGING,
  },
  {
    text: 'onboarding.doubleChinCause.option4',
    detailed: 'onboarding.doubleChinCause.detailed4',
    label: OPTION_VALUES.WATER_RETENTION,
  },
  {
    text: 'onboarding.doubleChinCause.option5',
    detailed: 'onboarding.doubleChinCause.detailed5',
    label: OPTION_VALUES.POOR_POSTURE,
  },
  {
    text: 'onboarding.doubleChinCause.option6',
    detailed: 'onboarding.doubleChinCause.detailed6',
    label: OPTION_VALUES.INCORRECT_TONGUE_POSITION,
  },
  {
    text: 'onboarding.doubleChinCause.option7',
    detailed: 'onboarding.doubleChinCause.detailed7',
    label: OPTION_VALUES.DONT_KNOW,
  },
]
