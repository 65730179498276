import { Cohort } from 'root-constants'

export const QUESTION = 'Preferred food'

export const OPTION_VALUES = {
  SANDWICHES_OR_WRAPS: 'sandwiches_or_wraps',
  SOUPS_OR_SALADS: 'soups_or_salads',
  FAST_FOOD: 'fast_food',
  BALANCED_MEALS: 'balanced_meals',
  SOMETHING_DIFFERENT: 'something_different',
  NONE_OF_ABOVE: 'none_of_above',
}

export const DEFAULT_PREFERRED_FOOD_OPTIONS = [
  {
    value: OPTION_VALUES.SANDWICHES_OR_WRAPS,
    text: 'onboarding.preferredFood.sandwichesOrWraps',
  },
  {
    value: OPTION_VALUES.SOUPS_OR_SALADS,
    text: 'onboarding.preferredFood.soupsOrSalads',
  },
  {
    value: OPTION_VALUES.FAST_FOOD,
    text: 'onboarding.preferredFood.fastFood',
  },
]

export const SENIOR_PREFERRED_FOOD_OPTIONS = [
  {
    value: OPTION_VALUES.SANDWICHES_OR_WRAPS,
    text: 'onboarding.preferredFood.sandwichesOrWraps',
  },
  {
    value: OPTION_VALUES.SOUPS_OR_SALADS,
    text: 'onboarding.preferredFood.soupsOrSalads',
  },
  {
    value: OPTION_VALUES.FAST_FOOD,
    text: 'onboarding.preferredFood.fastFood',
  },
  {
    value: OPTION_VALUES.NONE_OF_ABOVE,
    text: 'onboarding.preferredFood.noneOfAbove',
  },
]

export const PREFERRED_FOOD_OPTIONS_MAP = {
  [Cohort.LUVLY_12_SALE]: SENIOR_PREFERRED_FOOD_OPTIONS,
}
