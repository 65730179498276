import styled, { keyframes } from 'styled-components'

import bgLaptop from 'assets/images/korean-plan-bg-laptop.svg'
import bgMobile from 'assets/images/korean-plan-bg-mobile.svg'

import { StickyButtonContainer } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

const appear = keyframes`
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const StyledPersonalKoreanPlan = {
  Root: styled.div`
    min-height: var(--full-height);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 16px;
    background-image: ${`url(${bgMobile})`};
    background-repeat: no-repeat;
    background-position: center 200px;
    background-size: contain;

    @media (min-height: 755px) and (max-width: ${MediaBreakpoint.TABLET}px) {
      gap: 40px;
      justify-content: space-between;
      background-position: center center;

      nav + div {
        margin-top: 130px;
        gap: 80px;
      }
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background: url(${bgLaptop});
      background-repeat: no-repeat;
      background-position: center 200px;
      background-size: contain;
      justify-content: center;
      padding-top: 60px;
    }
  `,
  DiscountContainer: styled.div`
    position: fixed;
    top: 56px;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 360px;
    margin: 0 20px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      flex-direction: column-reverse;
      align-items: center;
      max-width: 100%;
    }

    @media (min-height: 700px) {
      gap: 16px;
    }
  `,
  TextBlock: styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-transform: uppercase;

    @media (min-height: 700px) {
      gap: 16px;
    }
  `,
  Subtitle: styled.span`
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  `,
  PersonBlock: styled.div`
    position: relative;
    min-height: 268px;
    display: flex;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(
        180deg,
        rgba(253, 253, 255, 0) 0%,
        #fdfdff 100%
      );
      pointer-events: none;
      z-index: 1;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      min-height: 356px;
    }
  `,
  PersonImage: styled.img`
    width: 100%;
    max-width: 453px;
    max-height: 381px;
  `,
  Bundle: styled.div`
    position: absolute;
    display: inline-flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 1px solid ${Color.WHITE};
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 0px 20px 0px #e8e8e8;
    backdrop-filter: blur(1px);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    opacity: 0;

    &:nth-child(2) {
      left: -5px;
      top: 44px;
      animation: ${appear} 0.3s ease-out 0.5s forwards;
    }

    &:nth-child(3) {
      right: 15px;
      top: 15px;
      animation: ${appear} 0.3s ease-out 0.3s forwards;
    }

    &:nth-child(4) {
      left: 0;
      bottom: 45px;
      animation: ${appear} 0.3s ease-out 0.7s forwards;
      z-index: 2;
    }

    &:nth-child(5) {
      right: 0;
      bottom: 23px;
      animation: ${appear} 0.3s ease-out 0.9s forwards;
      z-index: 2;
    }
  `,
  Terms: styled.div`
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: ${Color.GRAY_SECONDARY};
    text-align: center;

    a {
      color: ${Color.GRAY_SECONDARY};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.GRAY_SECONDARY};
    margin: 0 5px;
    vertical-align: middle;
  `,
  StickyButtonContainer: styled(StickyButtonContainer)`
    padding: 0 20px 24px;
  `,
}
