import styled from 'styled-components'

import { BottomButtonContainer as ButtonContainer } from 'common-styles'
import { Color } from 'root-constants'

export const StyledFaceYogaExperts = {
  ImageContainer: styled.div`
    display: flex;
    justify-content: center;
    min-height: 44px;
    margin: 40px 0 16px;
  `,
  Icon: styled.img`
    display: block;
    max-width: 38px;
  `,
  Title: styled.h1`
    width: calc(100% + 10px);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto 32px;
    max-width: 360px;
    padding: 0 10px;

    & strong {
      color: ${Color.PRIMARY};
      font-weight: 500;
    }
  `,
  Card: styled.div`
    display: flex;
    align-items: flex-start;
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 20px;
    padding: 12px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  CardV2: styled.div`
    display: flex;
    align-items: flex-start;
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    border-radius: 16px;
    padding: 16px 16px 16px 8px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  CardPicture: styled.img`
    width: 56px;
    height: 56px;
    margin-right: 8px;
    border-radius: 50%;
  `,
  CardPictureContainer: styled.div`
    min-width: 100px;
    margin-right: 16px;
  `,
  CardPictureV2: styled.img`
    width: 100px;
    height: 100px;
    border-radius: 16px;
  `,
  CardInfo: styled.div`
    flex-grow: 1;
  `,
  CardTitle: styled.h3`
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 2px;
  `,
  CardTitleV2: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 4px;
  `,
  CardDescription: styled.p`
    font-size: 14px;
    line-height: 16px;
    color: ${Color.LIGHT};
  `,
  CardDescriptionV2: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY};
  `,
  BottomButtonContainer: styled(ButtonContainer)`
    padding: 40px 16px;
  `,
}
