import emojiStress from 'assets/images/emoji-mood-1.png'
import emojiBad from 'assets/images/emoji-mood-2.png'
import emojiNormal from 'assets/images/emoji-mood-3.png'
import emojiGood from 'assets/images/emoji-mood-4.png'
import emojiRelaxed from 'assets/images/emoji-mood-5.png'

export const QUESTION =
  'Stress can lead to weight gain and fat accumulation around the chin. How do you manage your stress levels?'

const OPTION_VALUES = {
  STRESSED: 'stressed',
  BAD: 'bad',
  NORMAL: 'normal',
  GOOD: 'good',
  RELAXED: 'relaxed',
}

export const STRESS_LEVEL_ANSWERS = [
  {
    value: OPTION_VALUES.STRESSED,
    text: 'onboarding.stressLevels.stress',
    icon: emojiStress,
  },
  {
    value: OPTION_VALUES.BAD,
    text: 'onboarding.stressLevels.bad',
    icon: emojiBad,
  },
  {
    value: OPTION_VALUES.NORMAL,
    text: 'onboarding.stressLevels.normal',
    icon: emojiNormal,
  },
  {
    value: OPTION_VALUES.GOOD,
    text: 'onboarding.stressLevels.good',
    icon: emojiGood,
  },
  {
    value: OPTION_VALUES.RELAXED,
    text: 'onboarding.stressLevels.relaxed',
    icon: emojiRelaxed,
  },
]
