import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledMoneyBackGuarantee = {
  Root: styled.div`
    border-radius: 24px;
    background: ${Color.WHITE};
    padding: 8px;
    box-shadow: 0px 8px 20px 0px #e8e8e8;
    position: relative;
    margin-bottom: 64px;
  `,
  Container: styled.div`
    border: 1px solid ${Color.CORAL};
    border-radius: 24px;
    padding: 20px 16px;
    text-align: center;
  `,
  Title: styled.h2`
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 8px;
  `,
  Text: styled.p`
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.GRAY};

    & a {
      color: ${Color.GRAY};
      text-decoration: underline;
    }
  `,
  Badge: styled(SvgImage)`
    position: absolute;
    bottom: -68px;
    right: -12px;
    width: 92px;
  `,
}
