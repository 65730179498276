import styled from 'styled-components'

import { StyledButtonBase } from 'common-styles'
import { Color } from 'root-constants'

export const StyledDownload = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 15px;
  `,
  Title: styled.h1`
    padding-bottom: 24px;
    font-weight: ${({ theme }) => theme.common.semiBoldFontWeight};
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ImageContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 258px;
    margin: 0 auto 52px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #e8e8e8;
    border-radius: 30px;
  `,
  Image: styled.img`
    max-width: 104px;
    padding: 24px 0;
  `,
  Description: styled.span<{ isJapanese?: boolean }>`
    padding-bottom: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: ${({ isJapanese }) => (isJapanese ? '15px' : '17px')};
    line-height: 24px;
    text-align: center;
  `,
  Button: styled.button`
    ${StyledButtonBase};
    font-weight: 500;
    font-size: 17px;
    line-height: 50px;
  `,
}
