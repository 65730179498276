import styled, { keyframes } from 'styled-components'

import { Color } from 'root-constants'

const progress = keyframes` 
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  `

export const StyledIntroVariantRefund = {
  TickerContainer: styled.div`
    margin-top: 56px;
    margin-bottom: 16px;
  `,
  Title: styled.h1`
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 12px;
    text-align: center;
  `,
  Title2: styled.h1`
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 40px;
    color: ${Color.BLUE_DARK};
  `,
  Subtitle: styled.h2`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
    line-height: 24px;
    text-align: center;
    color: ${Color.GRAY};
  `,
  Animation: styled.div`
    width: 100%;
    aspect-ratio: 343/283;
    margin-bottom: 40px;
  `,

  Loader: styled.div`
    width: 100%;
    height: 8px;
    border-radius: 8px;
    background-color: #e9e9e9;
    position: relative;

    &::after {
      content: '';
      width: 0;
      background-color: ${Color.CORAL};
      animation: ${progress} 2.75s linear forwards;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  `,
}
