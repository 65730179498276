import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellCoachingReviews = {
  Title: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 24px;
  `,
  CustomerReview: styled.div`
    margin-bottom: 12px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 rgba(209, 214, 235, 0.5);
    padding: 16px;
  `,
  CustomerReviewTitle: styled.div`
    color: ${Color.DARK};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 6px;
    }
  `,
  ReviewText: styled.p`
    color: ${Color.GRAY};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
  `,
  CustomerName: styled.p`
    color: ${Color.GRAY_SECONDARY};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
}
