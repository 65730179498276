import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUserAvailableSpins } from 'root-redux/selects/user'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledWheelDiscountCongratsModal as S } from './WheelDiscountCongratsModal.styles'
import {
  DISCOUNT_ANIMATION_PATH_1,
  DISCOUNT_ANIMATION_PATH_2,
} from './constants'

type TWheelDiscountCongratsModalProps = {
  isShown: boolean
  onClose: () => void
}

export const WheelDiscountCongratsModal: React.FC<
  TWheelDiscountCongratsModalProps
> = ({ isShown, onClose }: TWheelDiscountCongratsModalProps) => {
  const { t } = useTranslation()
  const animationRef = useRef<HTMLDivElement | null>(null)
  const availableSpins = useSelector(selectUserAvailableSpins)
  const language = useSelector(selectLanguage)

  const animationPath = `${CDN_FOLDER_LINK}${
    availableSpins > 1 ? DISCOUNT_ANIMATION_PATH_1 : DISCOUNT_ANIMATION_PATH_2
  }_${language}.json`

  useEffect(() => {
    if (animationRef.current && isShown) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: animationPath,
        name: 'discountBox',
        autoplay: true,
        loop: false,
      })
    }

    return () => lottie.destroy('discountBox')
  }, [animationPath, isShown])

  return (
    <S.Wrapper isShown={isShown}>
      <S.Content>
        <S.Title>{t('purchaseWheel.congrats')}</S.Title>
        <S.Subtitle>{t('purchaseWheel.introDiscount')}</S.Subtitle>
        <S.DiscountBoxAnimation ref={animationRef} />
        <S.Button onClick={onClose}>{t('purchaseWheel.grabMyTreat')}</S.Button>
      </S.Content>
    </S.Wrapper>
  )
}
