import {
  FormEvent,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ChatStep } from 'components/ChatMessage/ChatMessage'

import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useEmailInputField } from 'hooks/useEmailInputField'

import { eventLogger } from 'services/eventLogger.service'

import {
  EMAIL_DOMAINS,
  EMAIL_DOMAIN_REGEXP,
  EMAIL_USERNAME_REGEXP,
} from 'root-constants'

export const useEmail = () => {
  const dispatch: TAppDispatch = useDispatch()
  const fetchingActionsList = useSelector(selectActionList)
  const uuid = useSelector(selectUUID)
  const error = useSelector(selectError)

  const cohortToUse = useCohortToUse()

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const [isErrorModalShown, setIsErrorModalShown] = useState<boolean>(false)
  const [isAutoCompleteVisible, setIsAutoCompleteComplete] =
    useState<boolean>(false)
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const [email, setEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)

  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  const domainsList = useMemo(() => {
    const [, emailDomain] = EMAIL_DOMAIN_REGEXP.exec(deferredEmail) || []
    const [userName] = EMAIL_USERNAME_REGEXP.exec(deferredEmail) || []
    return EMAIL_DOMAINS.filter((domain) => domain.includes(emailDomain)).map(
      (filteredDomain) => `${userName}${filteredDomain}`,
    )
  }, [deferredEmail])

  const isStatusFetching = useMemo(
    () =>
      fetchingActionsList.includes(SEND_USER_EMAIL) ||
      fetchingActionsList.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const errorText = useMemo(
    () => (!email.isValid && !domainsList.length ? email.validationText : ''),
    [email.isValid, email.validationText, domainsList],
  )

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleErrorModal = useCallback(() => setIsErrorModalShown(true), [])

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!email.isValid) return

      dispatch(
        sendUserEmailAction({
          email: email.value,
          unsuccessCallback: handleErrorModal,
          isConsentRequired: true,
        }),
      )

      eventLogger.logEmailPageCompleted({ email: email.value })
      window.fbq('track', 'Lead', {}, { eventID: uuid })
      window.ttq &&
        window.ttq.identify({ email: isPersonalDataAllowed ? email.value : '' })
      window.ttq && window.ttq.track('CompleteRegistration', { event_id: uuid })
      window.rdt &&
        window.rdt('track', 'Lead', {
          email: isPersonalDataAllowed ? email.value : '',
          externalId: isPersonalDataAllowed ? uuid : '',
        })
      window.snaptr &&
        window.snaptr('track', 'SIGN_UP', {
          user_email: isPersonalDataAllowed ? email.value : '',
        })
      window.obApi && window.obApi('track', 'Lead')
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: 'complete_registration',
        })
      window.axon &&
        window.axon('track', 'sign_up', {
          user_id: isPersonalDataAllowed ? uuid : '',
          method: 'email',
        })
    },
    [
      dispatch,
      email.isValid,
      email.value,
      handleErrorModal,
      uuid,
      isPersonalDataAllowed,
    ],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
      setIsAutoCompleteComplete(true)
    },
    [setEmail],
  )

  const handleFocus = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: true,
    }))
  }, [setEmail])

  const handleBlur = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: false,
    }))
  }, [setEmail])

  const handlePrefilledEmail = useCallback(
    ({ target }) => {
      setIsAutoCompleteComplete(false)
      setEmail((prevState) => ({
        ...prevState,
        value: target.value,
      }))
    },
    [setEmail],
  )

  return {
    handlePrefilledEmail,
    handleBlur,
    handleFocus,
    handleChange,
    handleSubmit,
    errorText,
    isStatusFetching,
    isEmailValid,
    error,
    isErrorModalShown,
    isAutoCompleteVisible,
    chatStep,
    setChatStep,
    email,
    domainsList,
    setIsErrorModalShown,
    cohortToUse,
  }
}
