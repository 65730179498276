import guidePicture1 from 'assets/images/hair-care-guide-program-1.png'
import guidePicture2 from 'assets/images/hair-care-guide-program-2.png'
import guidePicture3 from 'assets/images/hair-care-guide-program-3.png'

export const GUIDE_PROGRAM_PICTURES = [
  guidePicture1,
  guidePicture2,
  guidePicture3,
]

export const BONUS_CURRENT_PRICE = 0
// hardcoded for calculating price on frontend side (40% of the coaching upsell price)
export const BONUS_PRICE_RATE = 0.4
