import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledUpsellCoachingSteps as S } from './UpsellCoachingSteps.styles'
import { STEPS } from './constants'

export const UpsellCoachingSteps: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <S.Title>{t('upsellCoaching.howItWorks')}</S.Title>
      <div>
        {STEPS.map(({ id, title, description }) => (
          <S.Step key={id}>
            <S.StepNumber>{t('upsellCoaching.step', { id })}</S.StepNumber>
            <S.StepTitle>{t(title)}</S.StepTitle>
            <S.StepDescription>{t(description)}</S.StepDescription>
          </S.Step>
        ))}
      </div>
    </>
  )
}
