import styled from 'styled-components'

import discountBackground from 'assets/images/cancel-offer-discount-bg.png'
import giftBox from 'assets/images/gift-box.png'

import { Color } from 'root-constants'

type TRootProps = {
  isSmallerFontSize?: boolean
}

export const StyledCancelOfferDiscountInfo = {
  Root: styled.div<TRootProps>`
    max-width: 328px;
    padding: 16px 16px 16px 80px;
    background: url(${discountBackground}) no-repeat center,
      linear-gradient(
        263deg,
        rgba(123, 195, 0, 0.5) 44.68%,
        rgba(135, 213, 0, 0.5) 100%
      );
    background-size: contain;
    font-family: Rubik;
    font-size: ${({ isSmallerFontSize }) =>
      isSmallerFontSize ? '14px' : '18px'};
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #1c4f4a;
    margin: 16px auto;
    border-radius: 12px;

    & i {
      text-decoration: line-through;
      font-style: normal;
    }

    & strong {
      color: ${Color.RED};
    }
  `,
  DiscountBanner: styled.div`
    width: 343px;
    height: 80px;
    padding: 10px 16px 14px 92px;
    background: url(${giftBox}) no-repeat left,
      radial-gradient(
        28.57% 141.25% at 13.12% 50%,
        rgba(167, 223, 0, 0.6) 6.54%,
        rgba(123, 195, 0, 0.9) 51.17%,
        #7bc300 100%
      );
    background-size: contain;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.WHITE};
    margin: 16px auto;
    border-radius: 20px;

    & i {
      text-decoration: line-through;
      font-style: normal;
      color: rgba(255, 255, 255, 0.6);
    }

    & strong {
      font-size: 24px;
      line-height: 32px;
    }
  `,
}
