import {
  DEFAULT_DYNAMIC_DISCOUNT,
  PaymentMethod,
} from 'modules/purchase/constants'
import { IPrimerSubscriptionBackupConfig } from 'modules/purchase/types'

import { IDynamicDiscount } from 'models/payment.model'
import { IAppState } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'

import { DEFAULT_CURRENCY, TimeInterval } from 'root-constants'

export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.selectedSubscription?.id || ''
export const selectInAppId = (state: IAppState): string =>
  state.payment.selectedSubscription?.inAppId || ''
export const selectSubscriptionLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.lookupKey || ''
export const selectSubscriptionTrialLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.trialLookupKey || ''
export const selectSubscriptionPeriodQuantity = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.periodQuantity || 0
export const selectSubscriptionPeriodName = (
  state: IAppState,
): TimeInterval | null =>
  state.payment.selectedSubscription?.mainPrices.periodName || null
export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscription || null
export const selectSubscriptionCurrentPriceTax = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPriceTax || 0
export const selectSubscriptionTrialPriceTax = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.fullPriceTax || 0
export const selectUpgradeSubscriptionPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.upgradeDiff?.amountToPay || 0
export const selectUpgradeSubscriptionFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices?.fullPrice || 0
export const selectSubscriptionDiscountAmount = (state: IAppState): number =>
  state.payment.selectedSubscription?.introDiff?.discountAmount || 0
export const selectSubscriptionDiscountPercentage = (
  state: IAppState,
): number =>
  state.payment.selectedSubscription?.introDiff?.discountPercentage || 0
export const selectSubscriptionFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPrice || 0
export const selectSubscriptionOldPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.oldPrices.fullPrice || 0
export const selectTaxAmount = (state: IAppState): number =>
  state.payment.taxAmount || 0
export const selectSubscriptionTrialPeriodPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.fullPrice || 0
export const selectSubscriptionTrialPeriodDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0
export const selectPayPalPlanId = (state: IAppState): string =>
  state.payment.selectedSubscription?.paypalPlanId || ''
export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscription?.currency.toLowerCase() || DEFAULT_CURRENCY
export const selectProductId = (state: IAppState): string =>
  state.payment.selectedSubscription?.product || ''
export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret
export const selectTrialPeriodDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0
export const selectCreatedSubscriptionId = (state: IAppState): string =>
  state.payment.createdSubscriptionId
export const selectIsSubscriptionUpgraded = (state: IAppState): boolean =>
  state.payment.isSubscriptionUpgraded || false
export const selectPaymentMethod = (state: IAppState): PaymentMethod =>
  state.payment.paymentMethod
export const selectPrimerClientSessionToken = (state: IAppState): string =>
  state.payment.primerClientSessionToken
export const selectUserPaymentId = (state: IAppState): string =>
  state.payment.primerPaymentId
export const selectBackupPrimerSubscriptionConfig = (
  state: IAppState,
): IPrimerSubscriptionBackupConfig | null =>
  state.payment.backupPrimerSubscriptionConfig
export const selectIsFirstPaymentRetryPassed = (state: IAppState): boolean =>
  state.payment.isFirstPaymentRetryPassed
export const selectDynamicDiscount = (
  state: IAppState,
): IDynamicDiscount | null => state.payment.dynamicDiscount || null
export const selectDynamicDiscountPercentage = (state: IAppState): number =>
  state.payment.dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT
