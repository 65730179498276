import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useKeywordValue } from 'hooks/useKeywordValue'
import { useNextStep } from 'hooks/useNextStep'
import { useSendIntroPageCompletedAnalytics } from 'hooks/useSendIntroPageCompletedAnalytics'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { OptionType } from 'root-constants'

import { StyledIntroVariant1 as S } from './IntroVariant1.styles'
import {
  INTRO_PAGE_CONTEXT_TEXT_MAP,
  INTRO_PAGE_DEFAULT_TEXT,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const IntroVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const cohortToUse = useCohortToUse()
  const { sendIntroPageCompletedAnalytics } =
    useSendIntroPageCompletedAnalytics()

  const keyword = useKeywordValue()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        sendIntroPageCompletedAnalytics()

        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleChange(value, handleContinue)
        setAnswer(value)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      sendIntroPageCompletedAnalytics,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  const pageText: {
    title: string
    subtitle?: string
    firstAnswer: string
    secondAnswer: string
  } = useMemo(
    () => INTRO_PAGE_CONTEXT_TEXT_MAP[cohortToUse] || INTRO_PAGE_DEFAULT_TEXT,
    [cohortToUse],
  )

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.LIGHT} />
      <S.Container>
        <div>
          {pageText.subtitle && (
            <S.Subtitle>{t`onboarding.intro.subtitle`}</S.Subtitle>
          )}
          <S.Title>
            {keyword
              ? t(`onboarding.intro.title.${keyword}`)
              : t(pageText.title)}
          </S.Title>
          <S.ActionCall>{t`onboarding.intro.question`}</S.ActionCall>
          <S.Actions>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              value={OPTION_VALUES.GET_RID}
              disabled={isAnswersDisabled}
              isChecked={answer === OPTION_VALUES.GET_RID}
            >
              <S.Action>
                {keyword
                  ? t(`onboarding.intro.firstAnswer.${keyword}`)
                  : t(pageText.firstAnswer)}
              </S.Action>
            </S.StyledOption>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              value={OPTION_VALUES.REDUCE_FAT}
              disabled={isAnswersDisabled}
              isChecked={answer === OPTION_VALUES.REDUCE_FAT}
            >
              <S.Action>
                {keyword
                  ? t(`onboarding.intro.secondAnswer.${keyword}`)
                  : t(pageText.secondAnswer)}
              </S.Action>
            </S.StyledOption>
          </S.Actions>
          <S.RadioButton
            {...optionProps}
            value={OPTION_VALUES.BOTH_OPTIONS}
            disabled={isAnswersDisabled}
          >
            <S.RadioButtonLabel
              isChecked={answer === OPTION_VALUES.BOTH_OPTIONS}
            >
              {t`onboarding.intro.bothOptions`}
            </S.RadioButtonLabel>
          </S.RadioButton>
          <S.Terms>
            <p>{t`onboarding.intro.termsTitle`}</p>
            <TermsOfUseLink />
            <S.Separator />
            <PrivacyPolicyLink />
          </S.Terms>
          <S.Footnote>{t`onboarding.intro.footnote`}</S.Footnote>
        </div>
      </S.Container>
    </S.Root>
  )
}
