import { DefaultTheme } from 'styled-components'

import { Color } from 'root-constants'

const defaultTextStyles = {
  fontSize: '14px',
  lineHeight: '20px',
}

const defaultCoralTextStyles = {
  fontSize: '13px',
  lineHeight: '16px',
}

export const CHECKOUT_DEFAULT_THEME: DefaultTheme = {
  priceDescription: {
    ...defaultTextStyles,
    color: Color.LIGHT,
    paddingBottom: '10px',
    marginBottom: '4px',
    borderBottomColor: '#e9eaed',
  },
  totalDiscount: {
    ...defaultTextStyles,
    color: Color.PRIMARY,
  },
  totalPerDay: {
    backgroundColor: '#f5faf7',
    margin: '14px auto 0',
    subtitle: {
      ...defaultTextStyles,
      color: Color.DARK,
    },
    price: {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#7fb89d',
    },
  },
  totalToPay: {
    margin: '32px 8px',
    subtitle: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
      textTransform: 'none',
      color: Color.DARK,
    },
    price: {
      color: Color.DARK,
      fontWeight: 600,
    },
    period: {
      color: Color.DARK,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  savedAmount: {
    ...defaultTextStyles,
    color: Color.PRIMARY,
  },
}

export const CHECKOUT_CORAL_THEME: DefaultTheme = {
  priceDescription: {
    ...defaultCoralTextStyles,
    color: Color.GRAY,
    paddingBottom: '16px',
    marginBottom: '8px',
    borderBottomColor: '#cacaca',
  },
  totalDiscount: {
    ...defaultCoralTextStyles,
    color: '#fa6a2d',
  },
  totalPerDay: {
    backgroundColor: 'rgba(80, 171, 100, 0.08)',
    margin: '16px auto 0',
    subtitle: {
      ...defaultCoralTextStyles,
      color: Color.BLUE_DARK,
    },
    price: {
      ...defaultCoralTextStyles,
      color: '#50ab64',
    },
  },
  totalToPay: {
    margin: '16px auto 24px',
    subtitle: {
      ...defaultCoralTextStyles,
      fontWeight: 500,
      textTransform: 'uppercase',
      color: Color.BLUE_DARK,
    },
    price: {
      color: Color.BLUE_DARK,
      fontWeight: 700,
    },
    period: {
      color: Color.GRAY,
      fontSize: '15px',
      lineHeight: '20px',
    },
  },
  savedAmount: {
    ...defaultCoralTextStyles,
    color: Color.CORAL,
  },
}
