import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSkipUpsellCoachingBlock = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${Color.WHITE};
  `,
  Title: styled.p`
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
  Subtitle: styled.p`
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.LIGHT};
    text-align: center;
    margin-bottom: 24px;

    & > strong {
      font-weight: 600;
      color: ${Color.PRIMARY};
    }
  `,
  GraphContainer: styled.div`
    padding: 8px 16px 10px;
    border-radius: 24px;
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
    margin-bottom: 16px;
    aspect-ratio: 288/165;
  `,
  Chart: styled.img`
    width: 100%;
    height: auto;
    margin-bottom: 8px;
  `,
  Note: styled.p`
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.LIGHT};
  `,
  DiscountContainer: styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY};
    border-radius: 16px;
    background-color: rgba(255, 114, 98, 0.12);
    padding: 16px;
    margin-bottom: 16px;
  `,
  ButtonContainer: styled.div`
    padding: 16px 16px;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 2;
    background-color: ${Color.WHITE};
  `,
}
