import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledHolidaysCancelOfferReviews = {
  Root: styled.div`
    margin: 40px 0;

    & .swiper {
      padding-bottom: 36px;
      margin-bottom: 24px;
    }

    & .swiper-pagination-bullets {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: ${Color.CREAMY};
    }

    & .swiper-pagination-bullet {
      background: ${Color.PRIMARY};
    }
  `,
  ButtonContainer: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  Card: styled.div`
    max-width: 320px;
    padding-bottom: 16px;
    box-shadow: 0 8px 20px #f2e6e1;
    border-radius: 16px;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
  `,
  Image: styled.img`
    vertical-align: top;
    max-width: 100%;
    min-height: 208px;
    height: auto;
    margin-bottom: 16px;
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.GRAY};
    text-align: center;
    margin-bottom: 8px;
    padding: 0 16px;
  `,
  Reviewer: styled.span`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
  `,
}
