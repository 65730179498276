import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCustomizeGiftCard = {
  Title: styled.h1`
    margin-bottom: 20px;
    color: ${Color.DARK};
    text-align: center;
    font-family: 'Rozha One', serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  `,
  Summary: styled.div`
    border: 1px solid #cacaca;
    border-radius: 20px;
    margin-bottom: 16px;
  `,
  SummaryContent: styled.div`
    padding: 20px 20px 0 20px;
  `,
  Caption: styled.header`
    margin-bottom: 24px;

    & p {
      width: 100%;
      border-bottom: 1px solid #e9ecee;
      padding-bottom: 8px;
      overflow-wrap: break-word;
      font-size: 15px;
      font-weight: 600;
    }
  `,
  Action: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  `,
  Subtitle: styled.h3`
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK};
  `,
  Edit: styled.button`
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.5px;
    color: ${Color.DARK_SECONDARY};
    padding: 6px 20px;
    border: none;
    background-color: ${Color.CORAL_GRAY};
    border-radius: 16px;
  `,
  UserName: styled.span`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: ${Color.DARK};
    line-height: 20px;
  `,
  Note: styled.h3`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  NoteText: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    overflow-wrap: break-word;
  `,
  ImageContainer: styled.div`
    padding: 12px 12px 8px 12px;
    min-height: 247px;
  `,
  SubscriptionBlock: styled.div`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: ${Color.GRAY};
    margin-bottom: 20px;

    & i {
      text-decoration: line-through;
      font-style: normal;
    }

    & strong {
      font-weight: 600;
    }
  `,
  ButtonContainer: styled.div`
    padding: 16px 24px;
    border-top: 1px solid #cacaca;
    position: sticky;
    bottom: 0;
    background-color: ${Color.WHITE};
    display: flex;
    justify-content: center;
  `,
}
