import { PaymentMethod } from 'modules/purchase/constants'

import applePayActiveImage from 'assets/images/apple-pay-active.png'
import cardActiveImage from 'assets/images/card-icons-active.png'
import googlePayActiveImage from 'assets/images/google-pay-active.png'
import payPalActiveImage from 'assets/images/paypal-icon-active.png'

export const PAYMENT_METHODS_IMAGES = {
  [PaymentMethod.APPLE_PAY]: applePayActiveImage,
  [PaymentMethod.GOOGLE_PAY]: googlePayActiveImage,
  [PaymentMethod.CREDIT_CARD]: cardActiveImage,
  [PaymentMethod.PAYPAL]: payPalActiveImage,
}

export const GST_COUNTRIES = ['CA', 'AU']

export const SUPPORTED_ALTERNATIVE_PAYMENT_METHODS: PaymentMethod[] = [
  PaymentMethod.GOOGLE_PAY,
  PaymentMethod.APPLE_PAY,
  PaymentMethod.PAYPAL,
]
