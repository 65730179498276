import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { Locale, OptionType } from 'root-constants'

import { StyledObTags as S } from './ObTags.styles'
import { QUESTION, TAGS } from './constants'

export const ObTags: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const [answers, setAnswers] = useState<string[]>([])

  const isEnLocale = language === Locale.ENGLISH

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answers)

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <S.Root>
      <Container fields={16}>
        <S.Title>{t('onboarding.obTags.title')}</S.Title>
        <S.Tags isEnLocaleStyles={isEnLocale}>
          {TAGS.map(({ value, label }) => {
            return (
              <Option
                key={value}
                {...optionProps}
                checked={answers.includes(value)}
                value={value}
              >
                <S.Tag
                  isEnLocaleStyles={isEnLocale}
                  isSmallerFontSize={language === Locale.GERMAN}
                >
                  {t(label)}
                </S.Tag>
              </Option>
            )
          })}
        </S.Tags>
      </Container>
      <NavigationButtons
        onBackClick={goBack}
        onNextClick={handleNextClick}
        disabled={!answers.length}
      />
    </S.Root>
  )
}
