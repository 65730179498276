import { useEffect } from 'react'

import { getAxonItems } from 'helpers/getAxonItems'

import { usePurchaseStore } from 'modules/purchase/hooks'

export const useAxonPurchaseEvent = () => {
  const { subscriptions, selectedSubscription } = usePurchaseStore()

  useEffect(() => {
    if (!subscriptions.length || !selectedSubscription) return

    window.axon &&
      window.axon('track', 'view_item', {
        value:
          selectedSubscription.trialPrices?.fullPrice ||
          selectedSubscription.mainPrices.fullPrice,
        currency: selectedSubscription.currency.toUpperCase(),
        items: getAxonItems(subscriptions),
      })
  }, [subscriptions, selectedSubscription])
}
