import styled from 'styled-components'

type TColorProps = {
  color: string
}

export const StyledIdentifySkinColor = {
  AnswerContent: styled.div<TColorProps>`
    &::after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: ${({ color }) => color};
    }
  `,
}
