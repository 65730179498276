import React, { Suspense } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/bundle'

import { LayoutProvider } from 'components/LayoutProvider'
import { Spinner } from 'components/Spinner'

import {
  selectConfig,
  selectCurrentVariant,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useApplovinPixelInitialization } from 'hooks/useApplovinPixelInitialization'
import { useFacebookPixelInitialization } from 'hooks/useFacebookPixelInitialization'
import { useGoaffproInitialization } from 'hooks/useGoaffproInitialization'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics'
import { growthBook } from 'hooks/useGrowthBook'
import { useHotJarInitialization } from 'hooks/useHotJarInitialization'
import { useInContextInitialization } from 'hooks/useInContextInitialization'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useLanguage } from 'hooks/useLanguage'
import { useOutbrainPixelInitialization } from 'hooks/useOutbrainPixelInitialization'
import { useRedditPixelInitialization } from 'hooks/useRedditPixelInitialization'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetUserStatus } from 'hooks/useSetUserStatus'
import { useSetUuid } from 'hooks/useSetUuid'
import { useSetVariant } from 'hooks/useSetVariant'
import { useSnapPixelInitialization } from 'hooks/useSnapPixelInitialization'
import { useTaboolaPixelInitialization } from 'hooks/useTaboolaPixelInitialization'
import { useTiktokPixelInitialization } from 'hooks/useTiktokPixelInitialization'

import { setViewportFullHeight } from 'helpers/setViewportFullHeight'

import { browserHistory } from 'browser-history'

import { CustomRouter } from './CustomRouter'

setViewportFullHeight()
smoothScrollPolyfill()

Swiper.use([Pagination, Autoplay, Navigation])

export const App: React.FC = () => {
  const userStatus = useSelector(selectUserStatus, shallowEqual)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const config = useSelector(selectConfig)
  const language = useSelector(selectLanguage)

  useInitAppConfig()
  useGoogleAnalytics()
  useSetVariant()
  useLanguage()
  useSetUuid()
  useSetUserStatus()
  useScrollFix()
  useGoaffproInitialization()
  useFacebookPixelInitialization()
  useTiktokPixelInitialization()
  useInContextInitialization()
  useSnapPixelInitialization()
  useOutbrainPixelInitialization()
  useTaboolaPixelInitialization()
  useRedditPixelInitialization()
  useApplovinPixelInitialization()
  useHotJarInitialization()

  return (
    <CustomRouter history={browserHistory}>
      <Suspense fallback={<Spinner />}>
        <GrowthBookProvider growthbook={growthBook}>
          {userStatus && variant && config && language ? (
            <LayoutProvider />
          ) : (
            <Spinner />
          )}
        </GrowthBookProvider>
      </Suspense>
    </CustomRouter>
  )
}
