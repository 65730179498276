import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledPageTitle as S } from './PageTitle.styles'

export const PageTitle: React.FC<
  TComponentProps & { marginBottom?: number }
> = ({ children, marginBottom, className = '' }) => (
  <S.PageTitle className={className} marginBottom={marginBottom}>
    {children}
  </S.PageTitle>
)
