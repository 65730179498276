import styled from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'

import { Color } from 'root-constants'

export const StyledAnswerButtonWithImage2 = {
  Root: styled.div`
    width: 100%;
    max-width: 360px;
    padding: 12px;
    background-color: ${Color.WHITE};
    outline: none;
    border: 2px solid transparent;
    position: relative;
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::after {
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      border: 1px solid #cacaca;
      border-radius: 6px;
      position: absolute;
      top: 12px;
      right: 12px;
    }

    input:checked + & {
      background: rgba(255, 130, 118, 0.15);
      border: 2px solid ${Color.PRIMARY};
    }

    input:checked + &::after {
      border-color: ${Color.PRIMARY};
      background: ${Color.PRIMARY} url(${checkIcon}) no-repeat center;
      background-size: 10px 8.5px;
    }
  `,
  ImageContainer: styled.div`
    width: 100px;
    height: 100px;
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
    vertical-align: top;
  `,
  TextContainer: styled.p`
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    height: 48px;
    display: flex;
    align-items: center;
  `,
}
