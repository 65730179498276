import React from 'react'

import { HeaderGift } from 'components/HeaderGift'
import { HeaderMinimalistic } from 'components/HeaderMinimalistic'

import { Cohort } from 'root-constants'

export const MINIMALISTIC_HEADER_COHORTS = [
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_GIFT,
]

export const HEADERS: Record<string, React.FC> = {
  [Cohort.LUVLY_12_SALE]: HeaderMinimalistic,
  [Cohort.LUVLY_CHAT]: HeaderMinimalistic,
  [Cohort.LUVLY_CHIN]: HeaderMinimalistic,
  [Cohort.LUVLY_GIFT]: HeaderGift,
}
