import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledProgramDescription = {
  ProgramDescription: styled.div`
    margin-bottom: 48px;
    text-align: center;
  `,
  SecondaryTitle: styled.h3`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
    font-family: 'Open Sans';
    text-align: center;
  `,
  ProgramDescriptionSubtitle: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
    margin-bottom: 24px;
    font-family: 'Open Sans';
  `,
  Icon: styled.div`
    width: 100%;
    max-width: 343px;
    height: 148px;
    margin-bottom: 8px;
  `,
  ProgramName: styled.h4`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 8px;
    font-family: 'Open Sans';
  `,
  ProgramText: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
    margin-bottom: 24px;
    font-family: 'Open Sans';

    &:last-child {
      margin-bottom: 0;
    }
  `,
}
