import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TTagsProps = {
  isEnLocaleStyles: boolean
  isSmallerFontSize?: boolean
}

export const StyledObTags = {
  Root: styled.div``,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: ${Color.BLUE_DARK};
    margin-bottom: 18px;
  `,
  Tags: styled.div<TTagsProps>`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px 12px;

    ${({ isEnLocaleStyles }) =>
      !isEnLocaleStyles &&
      css`
        display: grid;
        grid-template-columns: repeat(2, fit-content(100%));
      `}
  `,
  Tag: styled.div<TTagsProps>`
    border-radius: 32px;
    border: 1px solid #c6d3ff;
    background: #e4eaff;
    font-size: ${({ isSmallerFontSize }) =>
      isSmallerFontSize ? '14px' : '16px'};
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    padding: 12px 16px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    transition: 0.15s;
    letter-spacing: -0.15px;

    ${({ isEnLocaleStyles }) =>
      !isEnLocaleStyles &&
      css`
        height: 66px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        line-height: 20px;
        text-wrap: pretty;
      `}

    input:checked + & {
      background: ${Color.PRIMARY};
      border-color: ${Color.RED};
      color: ${Color.WHITE};
    }
  `,
}
