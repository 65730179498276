import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { Ticker } from 'components/Ticker'

import {
  sendFacebookParamsAction,
  sendGoogleAdsParamsAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useABTest } from 'hooks/useABTest'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import faceScanningConfig from 'assets/animation/faceScanning.json'

import { PageId } from 'page-constants'

import { StyledIntroVariantRefund as S } from './SkippableIntro.styles'

export const SkippableIntro: React.FC = () => {
  const { isReTargetingPageMapVariantBActive } = useABTest()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const uuid = useSelector(selectUUID)

  useStartSession()

  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: '',
    nextPagePath: `${PageId.PURCHASE_RE_TARGETING}${search}`,
  })

  const handleNext = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: '',
    })
    dispatch(sendGoogleAdsParamsAction())

    handleContinue('')
  }, [dispatch, handleContinue, uuid])

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie
        .loadAnimation({
          container: animationContainerRef.current,
          animationData: faceScanningConfig,
          loop: false,
        })
        .addEventListener('complete', () => {
          handleNext()
        })
    }

    return () => lottie.destroy()
  }, [handleNext])

  const renderTitle = () => {
    if (isReTargetingPageMapVariantBActive) {
      return <S.Title2>{t('onboarding.skippableIntro.title2')}</S.Title2>
    }

    return <S.Title>{t('onboarding.skippableIntro.title')}</S.Title>
  }

  return (
    <div>
      <IntroHeader
        colorTheme={IntroHeaderColor.DARK}
        isSupportActionVisible={false}
      />
      <S.TickerContainer>
        <Ticker />
      </S.TickerContainer>

      <Container>
        {renderTitle()}
        {!isReTargetingPageMapVariantBActive && (
          <S.Subtitle>{t('onboarding.skippableIntro.subtitle')}</S.Subtitle>
        )}
        <S.Animation ref={animationContainerRef} />
        <S.Loader />
      </Container>
    </div>
  )
}
