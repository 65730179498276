import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import hero from 'assets/images/double-chin-hero.png'
import roseCheckIcon from 'assets/images/sprite/check-icon-rose.svg'

import {
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'

import { StyledDoubleChin as S } from './DoubleChin.styles'
import { PROGRAM_STEPS, QUESTION } from './constants'

export const DoubleChin: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <S.Root>
      <Container fields={16}>
        <S.Hero>
          <img src={hero} alt="" />
        </S.Hero>
        <S.Title>{t('onboarding.doubleChin.title')}</S.Title>
        <S.Steps>
          {PROGRAM_STEPS.map((step) => (
            <S.Step key={step}>
              <SvgImage svg={roseCheckIcon} width={28} height={28} />
              {t(step)}
            </S.Step>
          ))}
        </S.Steps>
      </Container>
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.gotIt3')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </S.Root>
  )
}
