import React from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { StyledUpsellCoachingReviews as S } from './UpsellCoachingReviews.styles'
import { UPSELL_COACHING_CUSTOMER_REVIEWS } from './constants'

export const UpsellCoachingReviews = () => {
  const { t } = useTranslation()

  return (
    <>
      <S.Title>{t('upsellCoaching.alreadyWorking')}</S.Title>
      {UPSELL_COACHING_CUSTOMER_REVIEWS.map(({ title, review, icon, name }) => (
        <S.CustomerReview key={name}>
          <S.CustomerReviewTitle>
            {t(title)}
            <SvgImage svg={icon} width={20} height={20} />
          </S.CustomerReviewTitle>
          <S.ReviewText>{t(review)}</S.ReviewText>
          <S.CustomerName>{t(name)}</S.CustomerName>
        </S.CustomerReview>
      ))}
    </>
  )
}
