import styled from 'styled-components'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

export const StyledRealTransformation = {
  Container: styled(Container)`
    min-height: calc(var(--full-height) - 220px);
  `,
  PageTitle: styled(PageTitle)`
    text-align: left;
  `,
  Image: styled.img`
    min-height: 186px;
    margin-bottom: 16px;
  `,
  Review: styled.p<{ marginBottom?: number }>`
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px` || 0};
  `,
}
