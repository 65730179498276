import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import roseCheckIcon from 'assets/images/sprite/check-icon-rose.svg'

import { StyledHabits as S } from './Habits.styles'

export const Habits: React.FC = () => {
  const { t } = useTranslation()
  return (
    <S.Habits>
      <Container>
        <S.SecondaryTitle>
          <Trans i18nKey="reTargetingPurchase.habits.title" />
        </S.SecondaryTitle>
        <S.HabitsList>
          <S.HabitsItem>
            <SvgImage svg={roseCheckIcon} width={24} height={24} />

            <div>
              <S.HabitsItemName>
                {t('reTargetingPurchase.habits.option1')}
              </S.HabitsItemName>
              <S.HabitsItemText>
                {t('reTargetingPurchase.habits.description1')}
              </S.HabitsItemText>
            </div>
          </S.HabitsItem>
          <S.HabitsItem>
            <SvgImage svg={roseCheckIcon} width={24} height={24} />

            <div>
              <S.HabitsItemName>
                {t('reTargetingPurchase.habits.option2')}
              </S.HabitsItemName>
              <S.HabitsItemText>
                {t('reTargetingPurchase.habits.description2')}
              </S.HabitsItemText>
            </div>
          </S.HabitsItem>
          <S.HabitsItem>
            <SvgImage svg={roseCheckIcon} width={24} height={24} />

            <div>
              <S.HabitsItemName>
                {t('reTargetingPurchase.habits.option3')}
              </S.HabitsItemName>
              <S.HabitsItemText>
                {t('reTargetingPurchase.habits.description3')}
              </S.HabitsItemText>
            </div>
          </S.HabitsItem>
        </S.HabitsList>
      </Container>
    </S.Habits>
  )
}
