import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { usePurchaseCancelOfferRoute } from 'hooks/usePurchaseCancelOfferRoute'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  Currency,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  INTRO_SALES_COHORTS_DISCOUNT_MAP,
  ONE_HUNDRED_PERCENTAGE,
} from 'modules/purchase/constants'
import {
  getDynamicDiscountText,
  getFormattedPriceValue,
} from 'modules/purchase/helpers/rootHelpers'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  selectDynamicDiscount,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { SubscriptionProduct, TrialBillingCycle } from 'root-constants'

import { StyledCheckoutPurchaseDetails as S } from './CheckoutPurchaseDetails.styles'

export const CheckoutPurchaseDetails: React.FC = () => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const selectedSubscription = useSelector(selectSubscription)
  const { isDynamicDiscountCohort, isLuvlyWheelCohort } =
    useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()
  const hasVatInfo = useVatInfo()
  const { currency, taxAmount, discountAmount, trialPrice } = usePurchaseStore()
  const cancelOfferRoute = usePurchaseCancelOfferRoute()
  const isCancelOfferRoute = useIsCurrentRoute(cancelOfferRoute)

  if (!selectedSubscription) {
    return null
  }

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice

    return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
  }

  const getPriceWithFormattedDiscount = (
    subscriptionPrice: number,
    discount: number,
  ) => {
    const calculatedOldPrice =
      subscriptionPrice /
      ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

    if (currency === Currency.JPY) {
      return Math.ceil(getCalculatedPrice(calculatedOldPrice))
    }

    return (
      Math.ceil(getCalculatedPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
    )
  }

  const currencyLabel = currency.toUpperCase()

  const getSummarizedSubscription = () => {
    if (isDynamicDiscountCohort && !isLuvlyWheelCohort) {
      const percentage =
        dynamicDiscount?.amount ||
        INTRO_SALES_COHORTS_DISCOUNT_MAP[cohortToUse] ||
        DEFAULT_DYNAMIC_DISCOUNT
      const appliedDiscount = isCancelOfferRoute
        ? percentage + CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : percentage

      const totalAmount = getPriceWithFormattedDiscount(
        selectedSubscription.trialPrices.fullPrice,
        appliedDiscount,
      )

      return {
        percentage: appliedDiscount,
        totalAmount: totalAmount.toFixed(2),
        discountAmount: (
          totalAmount - selectedSubscription.trialPrices.fullPrice
        ).toFixed(2),
      }
    }

    const totalAmount =
      selectedSubscription.trialPrices.durationDays ===
      SubscriptionProduct.SEVEN_DAY
        ? getCalculatedPrice(
            selectedSubscription.trialPrices.oldPrices.fullPrice,
          )
        : getCalculatedPrice(selectedSubscription.mainPrices.fullPrice)

    return {
      percentage:
        selectedSubscription.mainPrices.oldPrices.percentOfDiscount ||
        DEFAULT_DYNAMIC_DISCOUNT,
      totalAmount,
      discountAmount,
    }
  }

  const summarizedSubscription = getSummarizedSubscription()

  const getDiscountLabel = () => {
    const discountValue = !isDynamicDiscountCohort
      ? getCalculatedPrice(discountAmount)
      : summarizedSubscription.discountAmount

    return `-${getFormattedPriceValue(
      discountValue,
      currency,
    )} ${currencyLabel}`
  }

  const totalAmountLabel = `${getFormattedPriceValue(
    summarizedSubscription.totalAmount,
    currency,
  )} ${currencyLabel}`

  const renderDiscountText = () => {
    if (isLuvlyWheelCohort) {
      return (
        <Trans
          i18nKey="purchase7.off"
          values={{
            value: summarizedSubscription.percentage,
          }}
        />
      )
    }

    if (isDynamicDiscountCohort) {
      return getDynamicDiscountText({
        discountAmount: summarizedSubscription.percentage,
        isCancelOffer: isCancelOfferRoute,
        cohort: cohortToUse,
        discountText: dynamicDiscount?.checkout,
      })
    }

    return (
      <Trans
        i18nKey="checkoutExtended.introductoryDiscount"
        values={{
          discountPercentage: summarizedSubscription.percentage,
        }}
      />
    )
  }

  const renderDiscountBlock = () => {
    if (isLuvlyWheelCohort && !trialPrice) {
      return null
    }

    return (
      <S.PriceInfo>
        <S.PriceBlockText>{renderDiscountText()}</S.PriceBlockText>
        <S.TotalDiscount>{getDiscountLabel()}</S.TotalDiscount>
      </S.PriceInfo>
    )
  }

  const renderSavedAmount = () => {
    if (isLuvlyWheelCohort && !trialPrice) {
      return null
    }

    return (
      <S.SaveText>
        <Trans
          i18nKey="checkoutExtended.savedAmount"
          values={{
            savedAmount: getFormattedPriceValue(
              summarizedSubscription.discountAmount,
              currency,
            ),
            currency: currencyLabel,
            discountPercentage: summarizedSubscription.percentage,
          }}
        />
      </S.SaveText>
    )
  }

  const getDailyPriceLabel = () => {
    if (isLuvlyWheelCohort && !trialPrice) {
      return `${getCalculatedPrice(
        selectedSubscription.mainPrices.daily,
      )} ${currencyLabel}`
    }

    return `${getCalculatedPrice(
      selectedSubscription.trialPrices.daily,
    )} ${currencyLabel}`
  }

  const getSubscriptionDuration = () => {
    if (isLuvlyWheelCohort && !trialPrice) {
      return selectedSubscription.mainPrices.periodQuantity ===
        TrialBillingCycle.SEVEN_DAYS
        ? selectedSubscription.mainPrices.periodQuantity
        : selectedSubscription.mainPrices.periodQuantity *
            TrialBillingCycle.ONE_MONTH
    }

    return selectedSubscription.trialPrices.durationDays
  }

  return (
    <>
      <S.PriceDescription>
        <S.PriceInfo>
          <S.PriceBlockText>
            {t('checkoutExtended.personalizedPlan')}
          </S.PriceBlockText>
          <S.PriceBlockText>{totalAmountLabel}</S.PriceBlockText>
        </S.PriceInfo>
        {renderDiscountBlock()}
      </S.PriceDescription>

      <S.DailyPayment>
        <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
        <S.DailyAmount>{getDailyPriceLabel()}</S.DailyAmount>
      </S.DailyPayment>

      <S.TotalPayment>
        <S.TotalDescription>
          <S.TotalText>
            <Trans
              i18nKey={
                hasVatInfo
                  ? t('checkoutExtended.totalWithVat')
                  : t('checkoutExtended.total')
              }
              components={[<br />, <strong />]}
            />
          </S.TotalText>
          <S.TotalAmount>
            <Trans
              i18nKey="checkoutExtended.totalAmount"
              values={{
                totalAmount: getFormattedPriceValue(
                  selectedSubscription.trialPrices.fullPrice ||
                    selectedSubscription.mainPrices.fullPrice,
                  currency,
                ),
                currency: currencyLabel,
                context: getSubscriptionDuration(),
              }}
            />
          </S.TotalAmount>
        </S.TotalDescription>
        {renderSavedAmount()}
      </S.TotalPayment>
    </>
  )
}
