import { GiftCardType } from 'root-constants'

export const NAME_MAX_LENGTH = 50
export const WISH_MAX_LENGTH = 300
export const QUESTION = 'Customize the gift card'
export const GIFT_CARDS = [
  GiftCardType.GREEN,
  GiftCardType.LIGHT_PINK,
  GiftCardType.YELLOW,
]
