import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: '#000',
    fontFamily: 'Open Sans,  sans-serif',
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '24px',
    padding: '30px 0 10px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: Color.LIGHT,
      fontWeight: 400,
    },
  },
  invalid: {
    color: '#F83B00',
  },
}

const filledInputLabelStyles = css`
  & + p {
    top: 9px;
    color: ${Color.GRAY};
    font-size: 14px;
    line-height: 18px;
  }
`

export const commonInputStyles = css`
  color: #000;
  height: 64px;
  background-color: ${Color.WHITE};
  line-height: 24px;
  padding-top: 30px;
  border-radius: 16px;
  border: 1px solid ${Color.GRAY_LIGHT};
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-left: 16px;
  transition: border-color 0.2s ease-out;
  position: relative;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    border-color: #f83b00;
  }

  &.StripeElement--focus,
  &.StripeElement--invalid,
  &.StripeElement--complete {
    ${filledInputLabelStyles}
  }
`

export const StyledCardPaymentFormSeparateCheckout2 = {
  Wrapper: styled.div``,
  Form: styled.form``,
  InputsContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  InputWrapper: styled.div`
    position: relative;
  `,
  Label: styled.p`
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    text-align: left;
    position: absolute;
    z-index: 1;
    left: 16px;
    top: 20px;
    color: ${Color.GRAY};
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
  `,
  CardholderInput: styled.input<{ hasValue?: boolean }>`
    ${commonInputStyles};
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    box-shadow: none;

    ::placeholder {
      color: ${Color.LIGHT};
      font-weight: 400;
    }

    &:focus {
      ${filledInputLabelStyles}
    }

    ${({ hasValue }) =>
      hasValue &&
      css`
        ${filledInputLabelStyles}
      `}
  `,
}
