import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { Gender, OptionType } from 'root-constants'

import { StyledAgeWithImages as S } from './AgeWithImages.styles'
import { AGE_OPTIONS_2, AGE_OPTION_VALUES_MAP, QUESTION } from './constants'

export const AgeWithImages: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userGender = userAnswers?.[PageId.GENDER]

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: PageId.AGE,
    type: OptionType.RADIO,
    height: '100px',
    onChange: handleAnswerChange,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
  }

  return (
    <Container>
      <DoubleChinTitle>
        <Trans i18nKey="onboarding.ageWithImages.question" />
      </DoubleChinTitle>
      {AGE_OPTIONS_2.map(({ value, text, man, woman }) => (
        <Answer {...optionProps} key={value} value={value}>
          <S.AnswerContent imagePath={userGender === Gender.MALE ? man : woman}>
            {t(text)}
          </S.AnswerContent>
        </Answer>
      ))}
      <SandyBackgroundGlobalStyles />
    </Container>
  )
}
