import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellCoachingFeatures = {
  Title: styled.h1`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  UpsellFeatureContainer: styled.div`
    margin-bottom: 40px;
  `,
  UpsellFeature: styled.div`
    border-radius: 24px;
    border: 1px solid ${Color.WHITE};
    margin-bottom: 24px;
    background: radial-gradient(
        153.63% 158.28% at 67.35% 70.09%,
        rgba(215, 1, 5, 0.12) 0%,
        rgba(255, 255, 255, 0) 48.5%
      ),
      radial-gradient(
        218.16% 126.86% at 26.09% 72.05%,
        rgba(161, 86, 0, 0.12) 0%,
        rgba(255, 255, 255, 0) 48.5%
      ),
      radial-gradient(
        135.34% 123.53% at 78.13% 33.95%,
        rgba(216, 119, 150, 0.12) 0%,
        rgba(255, 255, 255, 0) 48.5%
      ),
      radial-gradient(
        139.36% 103.44% at 29.3% 18.12%,
        rgba(197, 119, 216, 0.12) 0%,
        rgba(255, 255, 255, 0) 48.5%
      ),
      linear-gradient(0deg, #fcfcfc 0%, #fcfcfc 100%), rgba(255, 114, 98, 0.16);

    &:last-child {
      margin-bottom: 0;
    }
  `,
  FeatureTitle: styled.div`
    display: flex;
    padding: 24px 24px 8px;
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    svg {
      margin-right: 6px;
      min-width: 24px;
    }
  `,
  Description: styled.p`
    padding: 0 24px 16px;
    color: ${Color.GRAY};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
}
