export const STEPS = [
  {
    id: 1,
    title: 'upsellCoaching.steps.first.title',
    description: 'upsellCoaching.steps.first.description',
  },
  {
    id: 2,
    title: 'upsellCoaching.steps.second.title',
    description: 'upsellCoaching.steps.second.description',
  },
  {
    id: 3,
    title: 'upsellCoaching.steps.third.title',
    description: 'upsellCoaching.steps.third.description',
  },
]
