import styled, { css } from 'styled-components'

import topBannerBg from 'assets/images/violet-banner-bg-2.png'
import bannerBg from 'assets/images/violet-banner-bg.png'

import { Color } from 'root-constants'

type TRefundBannerProps = {
  isTopBanner: boolean
}

type TLocaleStylesProps = {
  isSmallerText: boolean
}

export const StyledPurchaseRefundBanner = {
  RefundBannerContainer: styled.div<TRefundBannerProps>`
    ${({ isTopBanner }) =>
      isTopBanner &&
      css`
        margin-top: 16px;
      `}
  `,
  RefundBanner: styled.div<TRefundBannerProps & TLocaleStylesProps>`
    max-width: 343px;
    color: ${Color.WHITE};
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;

    ${({ isTopBanner }) =>
      isTopBanner
        ? css`
            height: 112px;
            background: url(${topBannerBg}) no-repeat center;
            margin: 0 auto 16px;
          `
        : css`
            height: 84px;
            background: url(${bannerBg}) no-repeat center;
            margin: 0 auto 32px;
          `}

    ${({ isTopBanner, isSmallerText }) =>
      !isTopBanner &&
      isSmallerText &&
      css`
        font-size: 14px;
        line-height: 18px;
      `}

    background-size: cover;

    & > strong {
      font-weight: 600;
    }
  `,
  RefundBannerImage: styled.img<TRefundBannerProps>`
    ${({ isTopBanner }) =>
      isTopBanner
        ? css`
            width: 70px;
            height: auto;
          `
        : css`
            width: 60px;
            height: 60px;
          `}

    margin-right: 8px;
  `,
}
