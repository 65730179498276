import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledAnswerButtonMini = {
  Root: styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 60px;
    width: 100%;
    height: 74px;
    background-color: #ffffff;
    transition: background-color 0.25s;
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 10px;

    input:checked + & {
      background: ${Color.PRIMARY};
      color: #fff;
    }
  `,
}
