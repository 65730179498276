import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button as UIButton, buttonTheme } from 'storybook-ui'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import { selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { eventLogger } from 'services/eventLogger.service'

import closeIcon from 'assets/images/sprite/close-icon.svg'

import { StyledConfirmPurchaseModal as S } from './ConfirmPurchaseModal.styles'

type TModalProps = {
  closeModal: () => void
  confirmPurchase: () => void
  children?: React.ReactNode
  trackEvent?: boolean
}

export const ConfirmPurchaseModal: React.FC<TModalProps> = ({
  closeModal,
  confirmPurchase,
  children,
  trackEvent = true,
}: TModalProps) => {
  const { t } = useTranslation()
  const email = useSelector(selectUserOnboardingEmail)
  const { isLuvlyGiftCardCohort } = useActiveCohortIdentifier()

  useEffect(() => {
    trackEvent && eventLogger.logConfirmationModalShown()
  }, [trackEvent])

  const renderButton = () => {
    if (isLuvlyGiftCardCohort) {
      return (
        <UIButton theme={buttonTheme.LUVLY_SECONDARY} onClick={confirmPurchase}>
          {t('finishingTouches.action')}
        </UIButton>
      )
    }

    return (
      <Button onClick={confirmPurchase}>{t('finishingTouches.action')}</Button>
    )
  }

  return (
    <S.Root>
      <S.Content>
        <S.CloseButton onClick={closeModal}>
          <SvgImage svg={closeIcon} width={16} height={16} />
        </S.CloseButton>
        <S.Title>{t('upsellCoaching.confirmPurchase')}</S.Title>
        <S.Subtitle>
          {children || (
            <Trans
              i18nKey="upsellCoaching.confirmDisclaimer"
              values={{
                email,
              }}
            />
          )}
        </S.Subtitle>
        {renderButton()}
      </S.Content>
    </S.Root>
  )
}
