import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'

import {
  CDN_FOLDER_LINK,
  I18N_CONTEXT_COHORT_MAP,
  Locale,
} from 'root-constants'

import { StyledLongPaywallUserBenefits as S } from './LongPaywallUserBenefits.styles'
import {
  DAILY_SCHEDULE_IMAGE_NY_PATH,
  DAILY_SCHEDULE_IMAGE_PATH,
  USER_BENEFITS,
} from './constants'

export const LongPaywallUserBenefits: React.FC = () => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const cohortToUse = useCohortToUse()
  const { isLuvlyChinCohort, isLuvlyEmailNYCohort } =
    useActiveCohortIdentifier()

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  const renderTitle = () => {
    if (isLuvlyEmailNYCohort) {
      return t('purchase9.userBenefitsTitle')
    }

    return t('purchase7.userBenefits.title')
  }

  const renderSubtitle = () => {
    if (isLuvlyEmailNYCohort) {
      return <Trans i18nKey="purchase9.dailyRitualsSubtitle" />
    }

    return (
      <Trans
        i18nKey="purchase7.dailyRitualsSubtitle"
        context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
      />
    )
  }

  const renderBenefitText = (text: string, textV2: string, textV3: string) => {
    if (isLuvlyChinCohort) {
      return textV2
    }

    if (isLuvlyEmailNYCohort) {
      return textV3
    }

    return text
  }

  return (
    <S.Wrapper>
      <S.Subtitle isEnLocaleStyle={isEnLocale}>{renderTitle()}</S.Subtitle>
      <S.BenefitCardsWrapper>
        {USER_BENEFITS.map(({ id, image, bgColor, text, textV2, textV3 }) => (
          <S.BenefitCard key={id}>
            <S.ImageContainer>
              <S.BenefitImage src={image} alt="benefit-image" />
            </S.ImageContainer>
            <S.Benefit bgColor={bgColor} isEnLocaleStyle={isEnLocale}>
              <Trans
                i18nKey={renderBenefitText(text, textV2, textV3)}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
              />
            </S.Benefit>
          </S.BenefitCard>
        ))}
      </S.BenefitCardsWrapper>
      <S.Subtitle isEnLocaleStyle={isEnLocale}>{renderSubtitle()}</S.Subtitle>
      <S.DailyScheduleImage
        src={`${CDN_FOLDER_LINK}${
          isLuvlyEmailNYCohort
            ? DAILY_SCHEDULE_IMAGE_NY_PATH
            : DAILY_SCHEDULE_IMAGE_PATH
        }_${language}.png`}
        alt="daily-schedule"
      />
    </S.Wrapper>
  )
}
