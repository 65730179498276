import { SubscriptionProduct, TimeInterval } from 'root-constants'

export const PERIOD_NAME_TRANSLATIONS_MAP: Record<TimeInterval, string> = {
  [TimeInterval.DAY]: 'commonComponents.timeInterval.day',
  [TimeInterval.WEEK]: 'commonComponents.timeInterval.week',
  [TimeInterval.MONTH]: 'commonComponents.timeInterval.month',
  [TimeInterval.YEAR]: 'commonComponents.timeInterval.year',
}

export const PURCHASE_INTRO_OFFER_DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchaseIntroOffer.weeklySubscription',
  [SubscriptionProduct.ONE_MONTH]: 'purchaseIntroOffer.oneMonthSubscription',
  [SubscriptionProduct.TWO_MONTHS]:
    'purchaseIntroOffer.flexibleMonthsSubscription',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscription',
  [SubscriptionProduct.FOUR_MONTHS]:
    'purchaseIntroOffer.flexibleMonthsSubscription',
  [SubscriptionProduct.SIX_MONTHS]: 'purchaseIntroOffer.sixMonthsSubscription',
}

export const PURCHASE_INTRO_OFFER_DISCLAIMER_WITHOUT_TAXES = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseIntroOffer.weeklySubscriptionWithoutTaxes',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionWithoutTaxes',
  [SubscriptionProduct.TWO_MONTHS]:
    'purchaseIntroOffer.flexibleMonthsSubscriptionWithoutTaxes',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithoutTaxes',
  [SubscriptionProduct.FOUR_MONTHS]:
    'purchaseIntroOffer.flexibleMonthsSubscriptionWithoutTaxes',
  [SubscriptionProduct.SIX_MONTHS]:
    'purchaseIntroOffer.sixMonthsSubscriptionWithoutTaxes',
}

export const PURCHASE_INTRO_SALE_DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchaseIntroOffer.weeklySubscriptionSale',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionSale',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionSale',
  [SubscriptionProduct.SIX_MONTHS]:
    'purchaseIntroOffer.sixMonthsSubscriptionSale',
}

export const PURCHASE_INTRO_SALE_DISCLAIMER_WITHOUT_TAXES = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseIntroOffer.weeklySubscriptionWithoutTaxesSale',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionWithoutTaxesSale',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithoutTaxesSale',
  [SubscriptionProduct.SIX_MONTHS]:
    'purchaseIntroOffer.sixMonthsSubscriptionWithoutTaxesSale',
}

export const PURCHASE_MAIN_OFFER_DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchaseDisclaimer.mainWeeklySubscription',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseDisclaimer.mainOneMonthSubscription',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseDisclaimer.mainThreeMonthsSubscription',
}

export const PURCHASE_MAIN_OFFER_DISCLAIMER_WITHOUT_TAXES = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseDisclaimer.mainWeeklySubscriptionWithoutTaxes',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseDisclaimer.mainOneMonthSubscriptionWithoutTaxes',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseDisclaimer.mainThreeMonthsSubscriptionWithoutTaxes',
}
