import React from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import benefitIcon from 'assets/images/sprite/upsell-benefit-icon.svg'

import { StyledUpsellCoachingBenefits as S } from './UpsellCoachingBenefits.styles'
import { UPSELL_COACHING_BENEFITS } from './constants'

export const UpsellCoachingBenefits: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <S.Title>{t('upsellCoaching.unlockCoaching')}</S.Title>
      <S.BenefitsContainer>
        {UPSELL_COACHING_BENEFITS.map((text) => (
          <S.BenefitRow key={text}>
            <SvgImage svg={benefitIcon} width={40} height={40} />
            <S.Benefit>{t(text)}</S.Benefit>
          </S.BenefitRow>
        ))}
      </S.BenefitsContainer>
    </>
  )
}
