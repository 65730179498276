import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'

import { usePricesStatus } from 'modules/purchase/hooks'

import neckAgingConfig from 'assets/animation/neckAging.json'
import sculptChinConfig from 'assets/animation/sculptChin.json'
import smoothSkinConfig from 'assets/animation/smoothSkin.json'

import { StyledProgramDescription as S } from './ProgramDescription.styles'

export const ProgramDescription = () => {
  const { t } = useTranslation()

  const { arePricesReady } = usePricesStatus()

  const sculptChinRef = useRef<HTMLDivElement | null>(null)
  const smoothSkinRef = useRef<HTMLDivElement | null>(null)
  const neckAgingRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!arePricesReady) return lottie.destroy()
    if (sculptChinRef.current) {
      lottie.loadAnimation({
        container: sculptChinRef.current,
        animationData: sculptChinConfig,
        loop: true,
      })
    }

    if (smoothSkinRef.current) {
      lottie.loadAnimation({
        container: smoothSkinRef.current,
        animationData: smoothSkinConfig,
        loop: true,
      })
    }

    if (neckAgingRef.current) {
      lottie.loadAnimation({
        container: neckAgingRef.current,
        animationData: neckAgingConfig,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [arePricesReady])
  return (
    <S.ProgramDescription>
      <Container>
        <S.SecondaryTitle>
          {t('reTargetingPurchase.programDescription.title')}
        </S.SecondaryTitle>
        <S.ProgramDescriptionSubtitle>
          {t('reTargetingPurchase.programDescription.subtitle')}
        </S.ProgramDescriptionSubtitle>
        <S.Icon ref={sculptChinRef} />
        <S.ProgramName>
          {t('reTargetingPurchase.programDescription.option1')}
        </S.ProgramName>
        <S.ProgramText>
          {t('reTargetingPurchase.programDescription.text1')}
        </S.ProgramText>
        <S.Icon ref={smoothSkinRef} />
        <S.ProgramName>
          {t('reTargetingPurchase.programDescription.option2')}
        </S.ProgramName>
        <S.ProgramText>
          {t('reTargetingPurchase.programDescription.text2')}
        </S.ProgramText>
        <S.Icon ref={neckAgingRef} />
        <S.ProgramName>
          {t('reTargetingPurchase.programDescription.option3')}
        </S.ProgramName>
        <S.ProgramText>
          {t('reTargetingPurchase.programDescription.text3')}
        </S.ProgramText>
      </Container>
    </S.ProgramDescription>
  )
}
