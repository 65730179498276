import styled from 'styled-components'

import swiperControl from 'assets/images/swiper-control-chevron.png'

import { Color } from 'root-constants'

export const StyledUsersGallery = {
  Root: styled.div`
    position: relative;
    margin-bottom: 40px;

    .swiper {
      padding-bottom: 56px;
      max-width: 375px;
    }

    .swiper-pagination {
      bottom: 8px;
    }

    .swiper-pagination-bullet {
      background-color: #cacaca;
    }

    .swiper-pagination-bullet-active {
      background-color: ${Color.CORAL};
    }

    .swiper-button-prev {
      width: 32px;
      height: 32px;
      background-color: ${Color.WHITE};
      border-radius: 50%;
      box-shadow: 0px 8px 20px #e8e8e8;
      background: url(${swiperControl}) no-repeat center;
      background-size: 8px 13px;
      bottom: 2px;
      left: 80px;
      top: auto;
      z-index: 11;
    }

    .swiper-button-next {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: url(${swiperControl}) no-repeat center;
      background-size: 8px 13px;
      box-shadow: 0px 8px 20px #e8e8e8;
      bottom: 2px;
      right: 80px;
      left: auto;
      top: auto;
      transform: scaleX(-1);
      z-index: 11;
    }
  `,
  ImageWrapper: styled.div`
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
    max-width: 343px;
    margin: 0 auto;
  `,
  SlideImage: styled.img`
    display: block;
    width: 100%;
    border-radius: 16px 16px 0 0;
  `,
  User: styled.div`
    padding: 16px 0 16px;
    width: 100%;
    text-align: center;
    color: ${Color.BLUE_DARK};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
}
