import faceWithHearts from 'assets/images/sprite/face-with-hearts.svg'
import okaySign from 'assets/images/sprite/okay-sign.svg'
import thumbUp from 'assets/images/sprite/thumb-up.svg'

export const UPSELL_COACHING_CUSTOMER_REVIEWS = [
  {
    title: 'upsellCoaching.customerReviews.first.title',
    review: 'upsellCoaching.customerReviews.first.review',
    icon: faceWithHearts,
    name: 'upsellCoaching.customerReviews.first.name',
  },
  {
    title: 'upsellCoaching.customerReviews.second.title',
    review: 'upsellCoaching.customerReviews.second.review',
    icon: okaySign,
    name: 'upsellCoaching.customerReviews.second.name',
  },
  {
    title: 'upsellCoaching.customerReviews.third.title',
    review: 'upsellCoaching.customerReviews.third.review',
    icon: thumbUp,
    name: 'upsellCoaching.customerReviews.third.name',
  },
]
