import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellCoaching = {
  Root: styled.div`
    padding-bottom: 24px;

    & .swiper {
      margin-bottom: 40px;
    }

    & .swiper-wrapper {
      margin-bottom: 24px;
    }

    & .swiper-pagination {
      bottom: 0;
    }

    & span.swiper-pagination-bullet {
      background-color: #cacaca;
      opacity: 1;
      margin: 0 8px !important;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }
  `,
  UpsellCoachingSubscriptionBlock: styled.div`
    margin-bottom: 40px;
  `,
  SkipBtn: styled.button`
    position: absolute;
    z-index: 3;
    top: 15px;
    right: 0;
    color: ${Color.PRIMARY};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  `,
}
