import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledWheelDiscountCongratsModal = {
  Wrapper: styled.div<{ isShown: boolean }>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 10 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition-duration: 250ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Content: styled.div`
    width: calc(100% - 40px);
    max-width: 360px;
    min-height: 358px;
    margin: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 40px;
    background: linear-gradient(180deg, #efe4dc 0%, #fff 100%);
  `,
  Title: styled.p`
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${Color.DARK};
    text-align: center;
  `,
  ButtonContainer: styled.div`
    padding: 16px 16px;
    border-top: 1px solid ${Color.GRAY_LIGHT};
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 2;
    background-color: ${Color.WHITE};
  `,
  Button: styled.button`
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${Color.WHITE};
    text-align: center;
    border-radius: 30px;
    background: linear-gradient(
      90deg,
      #ffa33a 0%,
      #ff321f 49.22%,
      #c91100 100%
    );
    height: 56px;
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
  DiscountBoxAnimation: styled.div`
    height: 390px;
  `,
}
