import styled, { css } from 'styled-components'

import { Currency } from 'modules/purchase/constants'
import { TLocaleStylesProps } from 'modules/purchase/types'

import coralCheckbox from 'assets/images/coral-checkbox.svg'
import coralPriceBadgeUrl from 'assets/images/coral-price-badge.svg'
import greenCheckbox from 'assets/images/green-checkbox.svg'
import priceBadgeUrl from 'assets/images/price-badge.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge.svg'
import unselectedPriceBadgeUrl from 'assets/images/unselected-price-badge.svg'

import { Color, Locale } from 'root-constants'

type TProps = {
  isSelected: boolean
  isDefault?: boolean
  customPriceBadge?: string
}

type TThemeStylesProps = {
  isCoralTheme?: boolean
}

type TMxnCurrencyProps = {
  isMxnCurrency: boolean
}

type TPriceValueProps = {
  currency: string
  language?: string
}

const getFontSize = (currency: string, language?: string) => {
  if (currency === Currency.MXN) {
    return '13px'
  }

  if (language === Locale.FRENCH || language === Locale.SPANISH) {
    return '16px'
  }

  return '18px'
}

export const StyledIntroOfferSelectSubscriptionItem = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    position: relative;
    background: ${Color.WHITE};
  `,
  Content: styled.div<TProps & TLocaleStylesProps & TThemeStylesProps>`
    display: flex;
    align-items: center;
    padding-right: 5px;
    min-height: 80px;
    border-radius: 20px;
    border: ${({ isSelected, isCoralTheme }) => {
      if (isSelected && isCoralTheme) return `2px solid ${Color.PRIMARY}`
      if (isSelected && !isCoralTheme) return '2px solid #89cc45'
      return `2px solid ${Color.ASH_GRAY}`
    }};

    &:after {
      content: '';
      ${({ isCoralTheme }) =>
        isCoralTheme
          ? css`
              width: 20px;
              height: 20px;
            `
          : css`
              width: 24px;
              height: 24px;
            `}
      mix-blend-mode: normal;
      border: 1px solid #555965;
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: ${({ isEnLocaleStyle }) => (isEnLocaleStyle ? '16px' : '12px')};
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;
      ${({ isSelected, isCoralTheme }) =>
        isSelected &&
        `
          background-image: url(${
            isCoralTheme ? coralCheckbox : greenCheckbox
          });
          opacity: 1;
          border: none;
        `}
    }
  `,
  PlanContainer: styled.div<TLocaleStylesProps>`
    width: 100%;
    padding-left: ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle ? '44px' : '40px'};
  `,
  MostPopularBadge: styled.div<TThemeStylesProps>`
    z-index: 1;
    border-radius: 8px;
    background: ${({ isCoralTheme }) =>
      isCoralTheme
        ? Color.PRIMARY
        : `linear-gradient(97.03deg, #70cd1c 3.15%, #a2ea35 88.83%)`};
    font-weight: ${({ isCoralTheme }) => (isCoralTheme ? 600 : 700)};
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    left: 44px;
    transform: translateY(-50%);
    padding: 4px 8px;
    color: ${Color.WHITE};
    font-size: 10px;
    line-height: 12px;
  `,
  PlanPeriod: styled.p<TProps>`
    font-weight: ${({ theme }) => theme.planPeriod.fontWeight};
    font-size: ${({ theme }) => theme.planPeriod.fontSize};
    line-height: ${({ theme }) => theme.planPeriod.lineHeight};
    text-transform: uppercase;
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.planPeriod.activeColor : theme.planPeriod.color};
    padding-right: 2px;
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 45px;
  `,
  PricesComparisonWrapper: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  PreviousPrice: styled.span<TMxnCurrencyProps>`
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '12px' : '14px')};
    font-weight: ${({ theme }) => theme.previousPrice.fontWeight};
    line-height: 18px;
    color: ${({ theme }) => theme.previousPrice.color};
    padding-right: 4px;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
    white-space: nowrap;
  `,
  OldestPrice: styled.span`
    font-size: 16px;
    font-weight: ${({ theme }) => theme.previousPrice.fontWeight};
    color: ${({ theme }) => theme.previousPrice.color};
    flex: 1;
    padding: 2px 6px;
    text-align: end;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
  `,
  CustomPrice: styled.p<TProps & TMxnCurrencyProps & TThemeStylesProps>`
    display: flex;
    flex-direction: column;
    align-items: ${({ isMxnCurrency }) =>
      isMxnCurrency ? 'flex-end' : 'flex-start'};
    padding: ${({ isMxnCurrency }) =>
      isMxnCurrency ? '10px 6px' : '10px 6px 10px 24px'};
    object-fit: contain;
    font-weight: ${({ theme }) => theme.customPrice.fontWeight};
    color: ${({ theme }) => theme.customPrice.color};
    transition: background 0.5s ease-out;
    background-repeat: no-repeat;
    background-position: center;

    ${({ isCoralTheme }) =>
      isCoralTheme
        ? css`
            background-image: url(${unselectedPriceBadgeUrl});
            min-width: 86px;
          `
        : css`
            background-image: url(${priceBadgeUrl});
            min-width: 98px;
          `}

    ${({ isSelected, isCoralTheme }) =>
      isSelected &&
      `
        background-image: url(${
          isCoralTheme ? coralPriceBadgeUrl : selectedPriceBadgeUrl
        });
        color: ${Color.WHITE};
        
        & > span:first-child {
          color: ${Color.WHITE};
        }
    `};
  `,
  CurrentPrice: styled.span<TMxnCurrencyProps>`
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '12px' : '14px')};
    font-weight: ${({ theme }) => theme.currentPrice.fontWeight};
    color: ${({ theme }) => theme.currentPrice.color};
    line-height: 14px;
    text-align: center;
  `,
  PriceValue: styled.span<TPriceValueProps>`
    font-size: ${({ currency }) => {
      switch (currency) {
        case Currency.MXN:
        case Currency.JPY:
          return '14px'
        default:
          return '18px'
      }
    }};
    font-weight: ${({ theme }) => theme.priceValue.fontWeight};
    line-height: 20px;
    margin-bottom: 4px;
  `,
  Period: styled.span`
    font-size: 8px;
    font-weight: 500;
    line-height: 8px;
  `,
  PriceValue2: styled.span<TPriceValueProps>`
    font-size: ${({ currency, language }) => getFontSize(currency, language)};
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  `,
}
