import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { MAX_COUNTER_VALUE } from 'pages/questions/CreatingSculptingPlan/constants'

import { Container } from 'components/Container'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { Ticker } from 'components/Ticker'

import {
  sendFacebookParamsAction,
  sendGoogleAdsParamsAction,
} from 'root-redux/actions/user'
import {
  selectUUID,
  selectUserConfigData,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import { Color, EU_COUNTRY_CODES } from 'root-constants'

import { StyledLoadingIntro as S } from './LoadingIntro.styles'

export const LoadingIntro: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const uuid = useSelector(selectUUID)
  const config = useSelector(selectUserConfigData)
  const userCountryCode = useSelector(selectUserCountryCode)

  const dispatch = useDispatch()
  const [isAnimationActive, setIsAnimationActive] = useState(false)
  const userCookieConsentAnswer = config?.cookieConsent as string[]
  const isEUUser = EU_COUNTRY_CODES.includes(userCountryCode.toUpperCase())

  useStartSession()

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: '',
    nextPagePath,
  })

  const handleNext = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: '',
    })
    dispatch(sendGoogleAdsParamsAction())

    handleContinue('')
  }, [dispatch, handleContinue, uuid])

  useEffect(() => {
    const handleAnimationStart = () => {
      setIsAnimationActive(true)
    }
    window.addEventListener('consent_submitted', handleAnimationStart)

    return () =>
      window.removeEventListener('consent_submitted', handleAnimationStart)
  }, [])

  const { counter } = useCounter(
    !isEUUser || !!userCookieConsentAnswer?.length || isAnimationActive,
    {
      duration: 5000,
      to: MAX_COUNTER_VALUE,
      callback: handleNext,
    },
  )

  return (
    <div>
      <IntroHeader
        colorTheme={IntroHeaderColor.DARK}
        isSupportActionVisible={false}
      />
      <S.Root>
        <Ticker />
        <Container>
          <S.Title>{t('onboarding.introSaleAgeAsIntro.titleV2')}</S.Title>
          <S.Subtitle>
            <p>{t('onboarding.loadingIntro.subtitle')}</p>
            <div>
              <span />
            </div>
          </S.Subtitle>
          <S.Loader>
            <S.Progress value={counter} max="100" />
            <span
              style={{
                color:
                  counter <= MAX_COUNTER_VALUE / 1.5 ? '#DD9F98' : Color.WHITE,
              }}
            >
              {counter}%
            </span>
          </S.Loader>
        </Container>
      </S.Root>
    </div>
  )
}
