import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { IStepInfo, TAnswer } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'

export const useNextStep = ({
  pageId,
  question,
  nextPagePath,
  trackEvent = true,
  sendApplovinAnalytics = true,
}: IStepInfo): ((userAnswer: TAnswer, analyticsAnswer?: TAnswer) => void) => {
  const dispatch = useDispatch()

  return useCallback(
    (userAnswer: TAnswer, analyticsAnswer?: TAnswer) => {
      dispatch(
        setAnswersAction({
          answers: userAnswer,
          pageId,
        }),
      )
      dispatch(sendUserAnswersAction())
      trackEvent &&
        eventLogger.logQuestion({
          question,
          answers: analyticsAnswer || userAnswer,
        })

      sendApplovinAnalytics && window.axon && window.axon('track', 'page_view')

      goTo(nextPagePath)
    },
    [
      dispatch,
      question,
      pageId,
      nextPagePath,
      trackEvent,
      sendApplovinAnalytics,
    ],
  )
}
