import styled from 'styled-components'

import { Color } from 'root-constants'

type TBanner = {
  fontWeight?: number
}

export const StyledPurchaseRefundInstructions = {
  Wrapper: styled.div`
    background-color: #efecff;
    padding: 32px 0;
    margin-bottom: 24px;
  `,
  Banner: styled.div<TBanner>`
    padding: 6px 16px;
    border-radius: 16px;
    font-size: 14px;
    color: ${Color.WHITE};
    font-weight: ${({ fontWeight }) => fontWeight || 400};
    background: linear-gradient(273deg, #d190ff 33.19%, #9a64ff 94.54%), #fff;
    text-transform: uppercase;
    width: fit-content;
    margin: 0 auto 16px;
  `,
  Title: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
  `,
  Subtitle: styled.p`
    color: ${Color.DARK};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    & strong {
      font-weight: 600;
    }
  `,
  Steps: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  `,
  StepWrapper: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 20px;
    box-shadow: 0px 8px 20px 0px rgba(204, 207, 222, 0.5);
    padding: 16px;
    display: flex;
  `,
  StepImage: styled.img`
    width: 40px;
    height: 40px;
    margin-right: 16px;
  `,
  StepContent: styled.div``,
  StepTitle: styled.p`
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  StepDescription: styled.p`
    color: ${Color.GRAY};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    & > a {
      color: ${Color.RED};
      text-decoration: underline;
    }

    & > strong {
      color: ${Color.DARK};
      font-weight: 600;
    }
  `,
  Disclaimer: styled.div`
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;

    & > a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
}
