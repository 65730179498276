import { buttonTheme } from 'storybook-ui'

export const QUESTION = 'Over 1.4 million women'

export const BUTTONS_PROPS = {
  theme: buttonTheme.LUVLY,
  borderRadius: '12px',
  padding: '0 24px',
  height: '52px',
  width: '100%',
  minWidth: '320px',
  fontWeight: '500',
}
