import React, { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import {
  CURRENCY_SYMBOLS,
  Currency,
  DECORATING_DISCOUNT,
} from 'modules/purchase/constants'
import { getFormattedPriceValue } from 'modules/purchase/helpers/rootHelpers'
import {
  selectCurrency,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import giftIcon from 'assets/images/sprite/gift-icon.svg'

import { PageId } from 'page-constants'

import { StyledUpsellCoachingSubscriptionBlock as S } from './UpsellCoachingSubscriptionBlock.styles'
import { BONUS_CURRENT_PRICE, BONUS_PRICE_RATE } from './constants'

type TUpsellCoachingSubscriptionBlockProps = {
  onContinueButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  onSkipButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  buttonOrder?: number
}

export const UpsellCoachingSubscriptionBlock: React.FC<
  TUpsellCoachingSubscriptionBlockProps
> = ({
  onContinueButtonClick,
  onSkipButtonClick,
  buttonOrder,
}: TUpsellCoachingSubscriptionBlockProps) => {
  const { t } = useTranslation()

  const currency = useSelector(selectCurrency)
  const selectedSubscription = useSelector(selectSubscription)
  const isCancelOffer = useIsCurrentRoute(PageId.COACHING_CANCEL_PAYWALL)

  const contactFormLink = getSupportFormLink()

  const getDetoxGuidePrice = () => {
    if (!selectedSubscription?.mainPrices.fullPrice) return 0

    const calculatedPrice = Math.ceil(
      selectedSubscription.mainPrices.fullPrice * BONUS_PRICE_RATE,
    )

    if (currency === Currency.JPY) {
      return getFormattedPriceValue(calculatedPrice, currency)
    }

    return calculatedPrice - DECORATING_DISCOUNT
  }

  const renderPrices = () => {
    if (isCancelOffer) {
      return (
        <>
          <S.OldPrice isSmallerFontSize={currency === Currency.MXN}>
            {CURRENCY_SYMBOLS[currency]}
            {getFormattedPriceValue(
              selectedSubscription?.mainPrices.fullPrice || 0,
              currency,
            )}
          </S.OldPrice>
          <S.CurrentPrice>
            {CURRENCY_SYMBOLS[currency]}
            {getFormattedPriceValue(
              selectedSubscription?.trialPrices.fullPrice || 0,
              currency,
            )}
          </S.CurrentPrice>
        </>
      )
    }

    return (
      <S.CurrentPrice>
        {CURRENCY_SYMBOLS[currency]}
        {getFormattedPriceValue(
          selectedSubscription?.mainPrices.fullPrice || 0,
          currency,
        )}
      </S.CurrentPrice>
    )
  }

  return (
    <div>
      <S.Title>{t('upsellCoaching.refineRoutine')}</S.Title>
      <S.SubscriptionBlock>
        <S.SubscriptionItem>
          <S.PlanTitle>{t('upsellCoaching.planTitle')}</S.PlanTitle>
          <S.PlanPrice>{renderPrices()}</S.PlanPrice>
        </S.SubscriptionItem>
        <S.SubscriptionItem>
          <S.PlanTitle>
            <SvgImage svg={giftIcon} width={40} height={40} />
            {t('upsellCoaching.detoxGuide')}
          </S.PlanTitle>
          <S.PlanPrice>
            <S.OldPrice isSmallerFontSize={currency === Currency.MXN}>
              {CURRENCY_SYMBOLS[currency]}
              {getDetoxGuidePrice()}
            </S.OldPrice>
            <S.CurrentPrice>
              {CURRENCY_SYMBOLS[currency]}
              {BONUS_CURRENT_PRICE}
            </S.CurrentPrice>
          </S.PlanPrice>
        </S.SubscriptionItem>
      </S.SubscriptionBlock>
      <S.Button onClick={onContinueButtonClick} data-order={buttonOrder}>
        {t('actions.continue')}
      </S.Button>
      <S.SkipButton onClick={onSkipButtonClick}>
        {t(
          isCancelOffer
            ? 'upsellCoaching.continueWithoutExpert'
            : 'upsellCoaching.continueWithoutPlan',
        )}
      </S.SkipButton>
      <S.Disclaimer>
        {t('upsellCoaching.disclaimer', {
          currency: CURRENCY_SYMBOLS[currency],
          price: getFormattedPriceValue(
            selectedSubscription?.mainPrices.fullPrice || 0,
            currency,
          ),
        })}{' '}
        <TermsOfUseLink /> {t`commonComponents.or`}{' '}
        <a target="_blank" rel="noopener noreferrer" href={contactFormLink}>
          {t`commonComponents.contactSupportTeam`}.
        </a>
      </S.Disclaimer>
    </div>
  )
}
