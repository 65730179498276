import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

type TOldPriceProps = {
  isSmallerFontSize?: boolean
}

export const StyledUpsellCoachingSubscriptionBlock = {
  SubscriptionBlock: styled.div`
    margin-bottom: 24px;
  `,
  SubscriptionItem: styled.div`
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 rgba(209, 214, 235, 0.5);
    margin-bottom: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    height: 56px;
  `,
  PlanTitle: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${Color.GRAY};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  PlanPrice: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  CurrentPrice: styled.div`
    color: ${Color.DARK};
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
  OldPrice: styled.div<TOldPriceProps>`
    color: ${Color.GRAY_SECONDARY};
    text-align: right;
    font-size: ${({ isSmallerFontSize }) =>
      isSmallerFontSize ? '14px' : '16px'};
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: line-through;
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
  `,
  SkipButton: styled.button`
    color: ${Color.LIGHT};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-bottom: 24px;
    width: 100%;
  `,
  Disclaimer: styled.div`
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 12px;

    a {
      text-decoration: underline;
      color: ${Color.LIGHT};
    }
  `,
  Title: styled.h1`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
}
