import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import { selectAnswers } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { ConfirmPurchaseModal } from 'modules/purchase/components/ConfirmPurchaseModal'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { makeGiftCardUpsellAction } from 'modules/purchase/redux/actions/upsell'
import {
  selectCurrency,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import giftCardGreen from 'assets/images/giftcard-green.png'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import {
  GIFT_CARDS,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { StyledCustomizeGiftCard as S } from './GiftCardPaywall.styled'

export const GiftCardPaywall: React.FC<TPageProps> = ({ nextPagePath }) => {
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()

  const selectedSubscription = useSelector(selectSubscription)
  const userAnswers = useSelector(selectAnswers)
  const currency = useSelector(selectCurrency)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)

  const userAnswer = userAnswers?.[PageId.CUSTOMIZE_GIFT_CARD]

  const { subscriptions } = usePurchaseStore()

  useLayoutEffect(() => {
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.GIFT,
        SubscriptionTagsType.NO_TAX,
      ),
    )
  }, [dispatch])

  useEffect(() => {
    const giftCardSubscription =
      subscriptions.find(
        (subscription) => subscription?.type === SubscriptionListType.GIFT,
      ) || subscriptions[0]

    dispatch(setSelectedSubscriptionAction(giftCardSubscription))
  }, [dispatch, subscriptions])

  const handleClick = (trackEvent: boolean = true) => {
    trackEvent && eventLogger.logGiftPaywallContinued()
    setIsConfirmationModalVisible(!isConfirmationModalVisible)
  }

  const handleReplace = () => {
    replaceHistory({ pathname: PageId.CUSTOMIZE_GIFT_CARD, search })
  }

  const handleMakePurchase = () => {
    eventLogger.logGiftPurchasePopupConfirmed()
    dispatch(makeGiftCardUpsellAction())
    setIsConfirmationModalVisible(false)
  }

  return (
    <>
      <Container fields={10}>
        <S.Title>{t('onboarding.giftCardPaywall.title')}</S.Title>
        <S.Summary>
          <S.SummaryContent>
            <S.Caption>
              <S.Action>
                <S.Subtitle>
                  {t('onboarding.giftCardPaywall.subtitle')}
                </S.Subtitle>
                <S.Edit type="button" onClick={handleReplace}>
                  {t('onboarding.giftCardPaywall.edit')}
                </S.Edit>
              </S.Action>
              {userAnswer?.name && (
                <p>
                  {t('onboarding.giftCardPaywall.targetName')}{' '}
                  {userAnswer?.name}
                </p>
              )}
            </S.Caption>
            {userAnswer?.wish && (
              <div>
                <S.Note> {t('onboarding.giftCardPaywall.note')}</S.Note>
                <S.NoteText>{userAnswer?.wish}</S.NoteText>
              </div>
            )}
          </S.SummaryContent>
          <S.ImageContainer>
            <img
              src={GIFT_CARDS[userAnswer?.cardType] || giftCardGreen}
              alt=""
            />
          </S.ImageContainer>
        </S.Summary>
        <S.SubscriptionBlock>
          <Trans
            i18nKey="onboarding.giftCardPaywall.prices"
            components={[<i />, <br />, <strong />]}
            values={{
              oldPrice: selectedSubscription?.mainPrices.oldPrices.fullPrice,
              price: selectedSubscription?.mainPrices.fullPrice,
              currency: selectedSubscription?.currency.toLocaleUpperCase(),
            }}
          />
        </S.SubscriptionBlock>
      </Container>
      {isConfirmationModalVisible && (
        <ConfirmPurchaseModal
          closeModal={() => handleClick(false)}
          confirmPurchase={handleMakePurchase}
          trackEvent={false}
        >
          <Trans
            i18nKey="onboarding.giftCardPaywall.disclaimer"
            values={{
              price: selectedSubscription?.mainPrices.fullPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
          {userAnswer?.name ? ` for ${userAnswer?.name}.` : '.'}
        </ConfirmPurchaseModal>
      )}
      <S.ButtonContainer>
        <Button
          theme={buttonTheme.LUVLY_SECONDARY}
          onClick={() => handleClick(true)}
        >
          {t('actions.continue')}
        </Button>
      </S.ButtonContainer>
      <PaymentWaitingModal
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        buttonText={t`actions.continue`}
        successCallback={() => replaceHistory(nextPagePath)}
      />
    </>
  )
}
