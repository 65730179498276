import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectIsInContextShown } from 'root-redux/selects/common'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { CurrentEnvironment } from 'root-constants'

export const useInContextInitialization = () => {
  const isTestEnvironment = getCurrentEnv() !== CurrentEnvironment.PROD
  const isInContextShown = useSelector(selectIsInContextShown)

  useLayoutEffect(() => {
    if (!isTestEnvironment || !isInContextShown) return

    const initialScript = document.createElement('script')
    const externalScript = document.createElement('script')

    initialScript.type = 'text/javascript'
    initialScript.text = `
      var _jipt = [];
      _jipt.push(['project', '88dab09a7738a844999807f6c8321e74']);
      _jipt.push(['domain', 'gismart']);
    `

    externalScript.type = 'text/javascript'
    externalScript.src = '//cdn.crowdin.com/jipt/jipt.js'
    externalScript.async = true

    document.body.appendChild(initialScript)
    document.body.appendChild(externalScript)
  }, [isTestEnvironment, isInContextShown])
}
