import React from 'react'
import { useLocation } from 'react-router-dom'

import { SvgImage } from 'components/SvgImage'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import arrowBlackSvg from 'assets/images/sprite/arrow-back-black.svg'
import logoSvg from 'assets/images/sprite/logo.svg'

import { goBack } from 'browser-history'
import { PageId } from 'page-constants'

import { StyledHeaderGift as S } from './HeaderGift.styles'

export const HeaderGift: React.FC = () => {
  const { pathname } = useLocation()
  const currentPageId = getPageIdFromPathName(pathname)
  const isArrowVisible = currentPageId !== PageId.SHARE_GIFT_CARD

  return (
    <S.Wrapper>
      {isArrowVisible && (
        <S.Arrow svg={arrowBlackSvg} width={24} height={24} onClick={goBack} />
      )}
      <SvgImage svg={logoSvg} width={58} />
    </S.Wrapper>
  )
}
