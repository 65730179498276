import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import greenStarSquare from 'assets/images/sprite/green-star-square.svg'

import { StyledReviews as S } from './Reviews.styles'
import { REVIEWS_FULL, REVIEWS_SHORT } from './constants'

export const Reviews: React.FC = () => {
  const [visibleReviews, setVisibleReviews] = useState(REVIEWS_SHORT)
  const [isButtonVisible, setIsButtonVisible] = useState(true)

  const { t } = useTranslation()
  return (
    <S.Root>
      {visibleReviews.map(({ id, title, text, name, date }) => (
        <S.Card key={id}>
          <S.Caption>
            <S.Rating>
              <SvgImage svg={greenStarSquare} width={20} height={20} />
              <SvgImage svg={greenStarSquare} width={20} height={20} />
              <SvgImage svg={greenStarSquare} width={20} height={20} />
              <SvgImage svg={greenStarSquare} width={20} height={20} />
              <SvgImage svg={greenStarSquare} width={20} height={20} />
            </S.Rating>
            <span>{t(date)}</span>
          </S.Caption>
          <S.Title>{t(title)}</S.Title>
          <S.Text>{t(text)}</S.Text>
          <S.Separator />
          <S.Name>{t(name)}</S.Name>
        </S.Card>
      ))}
      {isButtonVisible && (
        <S.Action>
          <button
            type="button"
            onClick={() => {
              setVisibleReviews(REVIEWS_FULL)
              setIsButtonVisible(false)
            }}
          >
            {t('actions.viewMore')}
          </button>
        </S.Action>
      )}
    </S.Root>
  )
}
