import faceChanges1 from 'assets/images/face-changes-1.png'
import faceChanges2 from 'assets/images/face-changes-2.png'
import faceChanges3 from 'assets/images/face-changes-3.png'
import faceChanges4 from 'assets/images/face-changes-4.png'

export const QUESTION = 'What age-related change worries you the most?'

const OPTION_VALUES = {
  FACIAL_DROOPING: 'facial_drooping',
  SAGGING_SKIN: 'sagging_skin',
  EXCESS_FAT: 'excess_fat',
  IMBALANCE_IN_PROPORTIONS: 'imbalance_in_proportions',
}

export const OPTIONS = [
  {
    value: OPTION_VALUES.FACIAL_DROOPING,
    text: 'onboarding.ageRelatedChanges.facialDropping',
    image: faceChanges1,
  },
  {
    value: OPTION_VALUES.SAGGING_SKIN,
    text: 'onboarding.ageRelatedChanges.saggingSkin',
    image: faceChanges2,
  },
  {
    value: OPTION_VALUES.EXCESS_FAT,
    text: 'onboarding.ageRelatedChanges.excessFat',
    image: faceChanges3,
  },
  {
    value: OPTION_VALUES.IMBALANCE_IN_PROPORTIONS,
    text: 'onboarding.ageRelatedChanges.imbalancedProportions',
    image: faceChanges4,
  },
]
