import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import hero from 'assets/images/upsell-coaching-hero.png'

import { PageId } from 'page-constants'

import { StyledUpsellCoachingResults as S } from './UpsellCoachingResults.styles'

export const UpsellCoachingResults: React.FC = () => {
  const { t } = useTranslation()
  const isCancelOffer = useIsCurrentRoute(PageId.COACHING_CANCEL_PAYWALL)

  const renderHeaders = () => {
    if (isCancelOffer) {
      return (
        <>
          <S.Title>{t('upsellCoaching.titleCancelOffer')}</S.Title>
          <S.Subtitle>
            <Trans
              i18nKey="upsellCoaching.subtitleCancelOffer"
              components={[<br />]}
            />
          </S.Subtitle>
        </>
      )
    }

    return (
      <>
        <S.Title>{t('upsellCoaching.title')}</S.Title>
        <S.Subtitle>
          <Trans i18nKey="upsellCoaching.subtitle" components={[<br />]} />
        </S.Subtitle>
      </>
    )
  }

  return (
    <>
      {renderHeaders()}
      <S.Hero>
        <img src={hero} alt="alex-review" />
        <S.Review>
          <Trans i18nKey="upsellCoaching.alexReview" />
        </S.Review>
      </S.Hero>
      <S.ReviewDisclaimer>
        <Trans i18nKey="upsellCoaching.reviewDisclaimer" />
      </S.ReviewDisclaimer>
    </>
  )
}
