import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { usePurchaseCancelOfferRoute } from 'hooks/usePurchaseCancelOfferRoute'

import { createProductId } from 'helpers/createProductId'

import { PaymentSystem } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import {
  setIsFirstPaymentRetryPassedAction,
  setSelectedSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { ScreenName } from 'root-constants'

import { Checkout } from '../Checkout'
import { CheckoutPrimer } from '../CheckoutPrimer'
import {
  CheckoutSeparateMethods,
  CheckoutSeparateMethods2,
  CheckoutSeparateMethodsMix,
} from '../CheckoutSeparateMethods'
import { CANCEL_OFFER_COHORTS, SEPARATED_CHECKOUT_COHORTS } from './constants'

type TCheckoutList = {
  setIsCheckoutShown: (isCheckoutShown: boolean) => void
  isCancelOffer: boolean
}
export const CheckoutList = ({
  setIsCheckoutShown,
  isCancelOffer,
}: TCheckoutList) => {
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const cohortToUse = useCohortToUse()

  const { isPrimerActive, isPaypalPrimerButtonActive } = useABTest()

  const { isLuvlyIntroSaleCohort, isLuvlyChinCohort } =
    useActiveCohortIdentifier()

  const { cohort, hasCancelOffer, periodName, periodQuantity, price } =
    usePurchaseStore()

  const cancelOfferRoutePath = usePurchaseCancelOfferRoute()

  const isSeparatedCheckout = SEPARATED_CHECKOUT_COHORTS.includes(cohortToUse)

  const productId = createProductId({ periodName, periodQuantity, price })

  const cancelOfferCohort =
    hasCancelOffer && CANCEL_OFFER_COHORTS.includes(cohortToUse)

  const handleCloseCheckout = () => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: isCancelOffer
        ? ScreenName.CANCEL_OFFER_LONG
        : ScreenName.PURCHASE,
    })

    if (isPrimerActive) {
      dispatch(setIsFirstPaymentRetryPassedAction(false))
    }

    if (cancelOfferCohort && !isCancelOffer) {
      dispatch(setSubscriptionListAction([]))
      dispatch(setSelectedSubscriptionAction(null))
      goTo({ pathname: cancelOfferRoutePath, search })
    }
    setIsCheckoutShown(false)
  }

  if (isLuvlyChinCohort) {
    return (
      <CheckoutSeparateMethods2 handleCloseCheckout={handleCloseCheckout} />
    )
  }

  if (isLuvlyIntroSaleCohort) {
    return (
      <CheckoutSeparateMethodsMix
        hasPaypalButton={isPaypalPrimerButtonActive}
        handleCloseCheckout={handleCloseCheckout}
      />
    )
  }

  if (isSeparatedCheckout) {
    return (
      <CheckoutSeparateMethods
        handleCloseCheckout={handleCloseCheckout}
        paymentSystem={
          isPrimerActive ? PaymentSystem.PRIMER : PaymentSystem.STRIPE
        }
      />
    )
  }

  if (isPrimerActive) {
    return (
      <CheckoutPrimer
        handleCloseCheckout={handleCloseCheckout}
        hasTotalAmount={false}
      />
    )
  }

  return (
    <Checkout
      handleCloseCheckout={handleCloseCheckout}
      hasTotalAmount={false}
    />
  )
}
