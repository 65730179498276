import styled from 'styled-components'

import { Currency } from 'modules/purchase/constants'
import { TLocaleStylesProps } from 'modules/purchase/types'

import coralCheckbox from 'assets/images/coral-checkbox.svg'
import coralPriceBadgeUrl from 'assets/images/coral-price-badge.svg'
import greenCheckbox from 'assets/images/green-checkbox-2.svg'
import greenPriceBadgeUrl from 'assets/images/green-price-badge.svg'
import unselectedPriceBadgeUrl from 'assets/images/unselected-price-badge.svg'
import violetCheckbox from 'assets/images/violet-checkbox.svg'
import violetPriceBadgeUrl from 'assets/images/violet-price-badge.svg'

import { AvailableFortuneWheelAttempts, Color, Locale } from 'root-constants'

type TProps = {
  isSelected: boolean
  customPriceBadge?: string
}

type TMxnCurrencyProps = {
  isMxnCurrency: boolean
}

type TPriceValueProps = {
  currency: string
  language?: string
}

type TAvailableSpinsProps = {
  availableSpins: AvailableFortuneWheelAttempts
}

const getFontSize = (currency: string, language?: string) => {
  if (currency === Currency.MXN) {
    return '13px'
  }

  if (language === Locale.SPANISH) {
    return '16px'
  }

  return '18px'
}

const ITEM_COLOR_MAP = {
  [AvailableFortuneWheelAttempts.ZERO]: {
    border: '#EF395A',
    checkbox: coralCheckbox,
    badge: coralPriceBadgeUrl,
    background: 'linear-gradient(91deg, #FF5343 -8.21%, #E1226C 95.97%)',
  },
  [AvailableFortuneWheelAttempts.ONE]: {
    border: '#B08CFC',
    checkbox: violetCheckbox,
    badge: violetPriceBadgeUrl,
    background: 'linear-gradient(97deg, #7671FF 3.15%, #DBA0F9 88.83%)',
  },
  [AvailableFortuneWheelAttempts.TWO]: {
    border: '#89CC45',
    checkbox: greenCheckbox,
    badge: greenPriceBadgeUrl,
    background: 'linear-gradient(97deg, #70CD1C 3.15%, #A2EA35 88.83%)',
  },
}

export const StyledColoredSelectSubscriptionItem = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    position: relative;
    background: ${Color.WHITE};
  `,
  Content: styled.div<TProps & TLocaleStylesProps & TAvailableSpinsProps>`
    display: flex;
    align-items: center;
    padding-right: 5px;
    min-height: 80px;
    border-radius: 20px;
    border: ${({ isSelected, availableSpins }) =>
      isSelected
        ? `2px solid ${[ITEM_COLOR_MAP[availableSpins].border]}`
        : `2px solid ${Color.ASH_GRAY}`};

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 1px solid #555965;
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: ${({ isEnLocaleStyle }) => (isEnLocaleStyle ? '16px' : '12px')};
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;

      ${({ isSelected, availableSpins }) =>
        isSelected &&
        `
          background-image: url(${ITEM_COLOR_MAP[availableSpins].checkbox});
          opacity: 1;
          border: none;
        `}
    }
  `,
  PlanContainer: styled.div<TLocaleStylesProps>`
    width: 100%;
    padding-left: ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle ? '44px' : '40px'};
  `,
  MostPopularBadge: styled.div<TAvailableSpinsProps>`
    z-index: 1;
    border-radius: 8px;
    background: ${({ availableSpins }) =>
      ITEM_COLOR_MAP[availableSpins].background};
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    left: 44px;
    transform: translateY(-50%);
    padding: 4px 8px;
    color: ${Color.WHITE};
    font-size: 10px;
    line-height: 12px;
  `,
  PlanPeriod: styled.p<TProps>`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${({ isSelected }) => (isSelected ? Color.BLUE_DARK : Color.LIGHT)};
    padding-right: 2px;
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 45px;
  `,
  PricesComparisonWrapper: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  PreviousPrice: styled.span<TMxnCurrencyProps>`
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '12px' : '14px')};
    font-weight: 500;
    line-height: 18px;
    color: ${Color.LIGHT};
    padding-right: 4px;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
    white-space: nowrap;
  `,
  OldestPrice: styled.span`
    font-size: 16px;
    font-weight: 500;
    color: ${Color.LIGHT};
    flex: 1;
    padding: 2px 6px;
    text-align: end;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
  `,
  CustomPrice: styled.p<TProps & TMxnCurrencyProps & TAvailableSpinsProps>`
    display: flex;
    flex-direction: column;
    align-items: ${({ isMxnCurrency }) =>
      isMxnCurrency ? 'flex-end' : 'flex-start'};
    padding: ${({ isMxnCurrency }) =>
      isMxnCurrency ? '10px 6px' : '10px 6px 10px 24px'};
    object-fit: contain;
    font-weight: 600;
    color: ${Color.LIGHT};
    transition: background 0.5s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${unselectedPriceBadgeUrl});
    min-width: 86px;

    ${({ isSelected, availableSpins }) =>
      isSelected &&
      `
        background-image: url(${ITEM_COLOR_MAP[availableSpins].badge});
        color: ${Color.WHITE};
        
        & > span:first-child {
          color: ${Color.WHITE};
        }
    `};
  `,
  CurrentPrice: styled.span<TMxnCurrencyProps>`
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '12px' : '14px')};
    font-weight: 500;
    color: ${Color.LIGHT};
    line-height: 14px;
    text-align: center;
  `,
  PriceValue: styled.span<TPriceValueProps>`
    font-size: ${({ currency, language }) => getFontSize(currency, language)};
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 2px;
  `,
  Period: styled.span`
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
  `,
}
