import { PageId } from 'page-constants'

import { useActiveCohortIdentifier } from './useActiveCohortIdentifier'

export const usePurchaseCancelOfferRoute = (): PageId => {
  const { isRefundCohort, isLuvlyChinCohort } = useActiveCohortIdentifier()

  if (isRefundCohort) return PageId.PURCHASE_CANCEL_REFUND
  if (isLuvlyChinCohort) return PageId.PURCHASE_CANCEL_CHIN

  return PageId.PURCHASE_INTRO_CANCEL_OFFER
}
