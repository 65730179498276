import cheeksImage from 'assets/images/problematic-areas-cheeks.png'
import chinImage from 'assets/images/problematic-areas-chin.png'
import decolleteImage from 'assets/images/problematic-areas-decollete.png'
import eyesImage from 'assets/images/problematic-areas-eyes.png'
import foreheadImage from 'assets/images/problematic-areas-forehead.png'
import jawlineImage from 'assets/images/problematic-areas-jawline.png'
import mouthImage from 'assets/images/problematic-areas-mouth.png'
import neckImage from 'assets/images/problematic-areas-neck.png'

import { Cohort } from 'root-constants'

export const QUESTION = 'What areas would you like to improve?'

export const enum OPTION_VALUES {
  FOREHEAD = 'Forehead',
  TEMPLES = 'Temples',
  EYES = 'Eyes',
  CHEEKS = 'Cheeks',
  JAWLINE = 'Jawline',
  MOUTH = 'Mouth',
  CHIN = 'Chin',
  NECK = 'Neck',
  NECKLINE = 'Décolleté (neckline)',
}

export const PROBLEMATIC_AREAS_DEFAULT_OPTIONS = [
  {
    value: OPTION_VALUES.FOREHEAD,
    text: 'onboarding.problematicAreas.forehead',
  },
  {
    value: OPTION_VALUES.TEMPLES,
    text: 'onboarding.problematicAreas.temples',
  },
  {
    value: OPTION_VALUES.EYES,
    text: 'onboarding.problematicAreas.eyes',
  },
  {
    value: OPTION_VALUES.CHEEKS,
    text: 'onboarding.problematicAreas.cheeks',
  },
  {
    value: OPTION_VALUES.JAWLINE,
    text: 'onboarding.problematicAreas.jawline',
  },
  {
    value: OPTION_VALUES.MOUTH,
    text: 'onboarding.problematicAreas.mouth',
  },
  {
    value: OPTION_VALUES.CHIN,
    text: 'onboarding.problematicAreas.chin',
  },
  {
    value: OPTION_VALUES.NECK,
    text: 'onboarding.problematicAreas.neck',
  },
  {
    value: OPTION_VALUES.NECKLINE,
    text: 'onboarding.problematicAreas.neckline',
  },
]

export const PROBLEMATIC_AREAS_COSMETIC_SCANNER_OPTIONS = [
  {
    value: OPTION_VALUES.FOREHEAD,
    text: 'onboarding.problematicAreas.forehead',
    image: foreheadImage,
  },
  {
    value: OPTION_VALUES.EYES,
    text: 'onboarding.problematicAreas.eyes',
    image: eyesImage,
  },
  {
    value: OPTION_VALUES.CHEEKS,
    text: 'onboarding.problematicAreas.cheeks',
    image: cheeksImage,
  },
  {
    value: OPTION_VALUES.JAWLINE,
    text: 'onboarding.problematicAreas.jawline',
    image: jawlineImage,
  },
  {
    value: OPTION_VALUES.MOUTH,
    text: 'onboarding.problematicAreas.mouth',
    image: mouthImage,
  },
  {
    value: OPTION_VALUES.CHIN,
    text: 'onboarding.problematicAreas.chin',
    image: chinImage,
  },
  {
    value: OPTION_VALUES.NECK,
    text: 'onboarding.problematicAreas.neck',
    image: neckImage,
  },
  {
    value: OPTION_VALUES.NECKLINE,
    text: 'onboarding.problematicAreas.neckline',
    image: decolleteImage,
  },
]

export const PROBLEMATIC_AREAS_OPTIONS_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]: PROBLEMATIC_AREAS_COSMETIC_SCANNER_OPTIONS,
  [Cohort.LUVLY_12_SALE]: PROBLEMATIC_AREAS_COSMETIC_SCANNER_OPTIONS,
}

export const PROBLEMATIC_AREAS_ANSWERS_MAP = {
  [OPTION_VALUES.FOREHEAD]: 'onboarding.problematicAreas.forehead',
  [OPTION_VALUES.EYES]: 'onboarding.problematicAreas.eyes',
  [OPTION_VALUES.CHEEKS]: 'onboarding.problematicAreas.cheeks',
  [OPTION_VALUES.JAWLINE]: 'onboarding.problematicAreas.jawline',
  [OPTION_VALUES.MOUTH]: 'onboarding.problematicAreas.mouth',
  [OPTION_VALUES.CHIN]: 'onboarding.problematicAreas.chin',
  [OPTION_VALUES.NECK]: 'onboarding.problematicAreas.neck',
  [OPTION_VALUES.NECKLINE]: 'onboarding.problematicAreas.neckline',
}

export const LIKE_CHAT_PROBLEMATIC_AREAS_OPTIONS_MAP = {
  [OPTION_VALUES.FOREHEAD]: { value: 'forehead', label: 'Forehead' },
  [OPTION_VALUES.EYES]: { value: 'eyes', label: 'Eyes' },
  [OPTION_VALUES.CHEEKS]: { value: 'cheeks', label: 'Cheeks' },
  [OPTION_VALUES.JAWLINE]: { value: 'jawline', label: 'Jawline' },
  [OPTION_VALUES.MOUTH]: { value: 'mouth', label: 'Mouth' },
  [OPTION_VALUES.CHIN]: { value: 'chin', label: 'Chin' },
  [OPTION_VALUES.NECK]: { value: 'neck', label: 'Neck' },
  [OPTION_VALUES.NECKLINE]: { value: 'neck', label: 'Décolleté (neckline)' },
}
