import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellCoachingResults = {
  Title: styled.h1`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
    text-align: center;
  `,
  Subtitle: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.LIGHT};
    text-align: center;
    margin-bottom: 24px;

    strong {
      font-weight: 600;
      color: ${Color.PRIMARY};
    }
  `,
  Review: styled.div`
    position: absolute;
    bottom: 12px;
    left: 8px;
    width: 304px;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background: ${Color.WHITE};
    box-shadow: 0 4px 14px 0 rgba(8, 4, 23, 0.03);

    strong {
      color: ${Color.PRIMARY};
      font-weight: 500;
    }
  `,
  Hero: styled.div`
    margin-bottom: 24px;
    position: relative;
    border-radius: 24px;

    img {
      width: 320px;
      height: 300px;
    }
  `,
  ReviewDisclaimer: styled.div`
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 32px;
  `,
}
