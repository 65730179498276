import styled from 'styled-components'

export const StyledManageStressLevels = {
  OptionsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
  `,
  Content: styled.div`
    margin: 0 auto;
  `,
  Icon: styled.img`
    max-width: 36px;
    height: auto;
    margin-bottom: 4px;
  `,
  Text: styled.p`
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
  `,
}
