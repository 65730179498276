import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { selectAnswers } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import confettiAnimation from 'assets/animation/confettiGiftCard.json'
import giftCardGreen from 'assets/images/giftcard-green.png'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { GIFT_CARDS, LUVLY_ONE_LINK } from 'root-constants'

import { StyledShareGiftCard as S } from './ShareGiftCard.styles'

export const ShareGiftCard: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const animationContainerRef = useRef<HTMLDivElement>(null)
  const [promoCode, setPromoCode] = useState<string | null>(null)
  const userAnswers = useSelector(selectAnswers)
  const userStatus = useSelector(selectUserStatus)

  const customizeGiftCardData = userAnswers?.[PageId.CUSTOMIZE_GIFT_CARD]

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        name: 'confetti',
        container: animationContainerRef.current,
        animationData: confettiAnimation,
        loop: true,
      })
    }

    return () => lottie.destroy('confetti')
  }, [])

  useEffect(() => {
    if (!userStatus) return

    const { giftPromoCode } = userStatus.config
    if (!giftPromoCode) {
      replaceHistory({ pathname: PageId.INTRO_GIFT_CARD, search })

      return
    }

    setPromoCode(giftPromoCode)
  }, [userStatus, search])

  useEffect(() => {
    eventLogger.logGiftShareScreenOpened()
  }, [])

  const shareGiftCard = async () => {
    eventLogger.logGiftShareCardTap()

    await navigator.share({
      url: LUVLY_ONE_LINK,
      text: t('onboarding.shareGiftCard.shareText', {
        name: customizeGiftCardData.name
          ? `${customizeGiftCardData.name}, `
          : '',
        wish:
          customizeGiftCardData.wish || t('onboarding.shareGiftCard.loveYoga'),
        giftCode: promoCode,
      }),
    })
    eventLogger.logGiftCardShared()
  }

  return (
    <S.Root>
      <S.AnimationContainer ref={animationContainerRef} />
      <S.Title>{t`onboarding.shareGiftCard.title`}</S.Title>
      <S.ImageContainer>
        <img
          src={GIFT_CARDS[customizeGiftCardData?.cardType] || giftCardGreen}
          alt="card"
        />
      </S.ImageContainer>
      <S.Text>{t`onboarding.shareGiftCard.text`}</S.Text>

      <S.StickyButtonContainer>
        <Button
          type="button"
          theme={buttonTheme.LUVLY_SECONDARY}
          onClick={shareGiftCard}
        >
          {t`onboarding.shareGiftCard.share`}
        </Button>
      </S.StickyButtonContainer>
    </S.Root>
  )
}
