import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'storybook-ui'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import image from 'assets/images/korean-beauty.png'

import { StickyButtonContainer } from 'common-styles'

import { StyledTriedKoreanBeauty as S } from './TriedKoreanBeauty.styles'
import { BUTTONS_PROPS, QUESTION } from './constants'

export const TriedKoreanBeauty: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <S.Root>
      <Container>
        <PageTitle marginBottom={15}>
          <Trans i18nKey="onboarding.triedKoreanBeauty.title" />
        </PageTitle>

        <S.Subtitle>
          <Trans i18nKey="onboarding.triedKoreanBeauty.subtitle" />
        </S.Subtitle>
      </Container>

      <div>
        <S.Image src={image} alt="Over 1.4 million women" />

        <StickyButtonContainer centerMode>
          <Button {...BUTTONS_PROPS} onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </div>
    </S.Root>
  )
}
