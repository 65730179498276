import styled from 'styled-components'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledGoingToLoveIt = {
  Container: styled(Container)`
    min-height: calc(var(--full-height) - 220px);
  `,
  PageTitle: styled(PageTitle)`
    text-align: left;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  DescriptionBlock: styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 16px;
  `,
  Description: styled.p`
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
  `,
  Image: styled.img`
    min-height: 206px;
    border-radius: 24px;
  `,
}
