import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'

import { useNextStep } from 'hooks/useNextStep'
import { useSendIntroPageCompletedAnalytics } from 'hooks/useSendIntroPageCompletedAnalytics'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { StyledIntroLikeChat as S } from './IntroLikeChat.styles'
import { CHAT_QUESTION } from './constants'

export const IntroLikeChat: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { sendIntroPageCompletedAnalytics } =
    useSendIntroPageCompletedAnalytics()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: CHAT_QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    sendIntroPageCompletedAnalytics()

    eventLogger.logFirstPageCompleted({
      question: CHAT_QUESTION,
    })

    handleContinue('')
  }, [handleContinue, sendIntroPageCompletedAnalytics])

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.LIGHT} />
      <S.Container>
        <div>
          <S.Title>{t('onboarding.introLikeChat.title')}</S.Title>
          <S.Subtitle>{t`onboarding.introLikeChat.subtitle`}</S.Subtitle>
          <S.ButtonContainer>
            <Button onClick={handleNextClick}>
              {t('onboarding.introLikeChat.CTA')}
            </Button>
          </S.ButtonContainer>
        </div>
      </S.Container>
    </S.Root>
  )
}
