import styled from 'styled-components'

import { Container } from 'components/Container'

import branchesImage from 'assets/images/branches.svg'

import { Color } from 'root-constants'

export const StyledHaveSolution = {
  Container: styled(Container)`
    min-height: calc(var(--full-height) - 167px);
  `,
  AppNumberOne: styled.div`
    min-width: 288px;
    width: 100%;
    height: 182px;
    background-image: url(${branchesImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 16px;

    & p:nth-child(1) {
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      line-height: 30px;
      text-transform: uppercase;
    }

    & strong {
      font-size: 56px;
      font-weight: 500;
      line-height: 30px;
      text-transform: uppercase;
    }
  `,
  Country: styled.span`
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    color: ${Color.PRIMARY};
  `,
  Description: styled.p`
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.LIGHT};
    margin-bottom: 12px;

    & strong:first-child {
      font-weight: 600;
      color: ${Color.PRIMARY};
    }

    & strong:last-child {
      color: ${Color.DARK};
      font-weight: 600;
    }
  `,
}
