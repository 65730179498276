import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  INTRO_SALES_COHORTS_DISCOUNT_MAP,
  SUBSCRIPTION_DISCOUNTS,
} from 'modules/purchase/constants'
import {
  selectDynamicDiscount,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { Locale } from 'root-constants'

import { StyledCancelOfferDiscountInfo as S } from './CancelOfferDiscountInfo.styles'

export const CancelOfferDiscountInfo = () => {
  const {
    isDynamicDiscountCohort,
    isLuvlyCancelIntro1Cohort,
    isLuvlyChinCohort,
  } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const subscription = useSelector(selectSubscription)
  const language = useSelector(selectLanguage)

  const getPrevDiscount = () => {
    if (isDynamicDiscountCohort) {
      return (
        dynamicDiscount?.amount ||
        INTRO_SALES_COHORTS_DISCOUNT_MAP[cohortToUse] ||
        SUBSCRIPTION_DISCOUNTS.DEFAULT
      )
    }

    const prevDiscountAmount =
      subscription && subscription.mainPrices.oldPrices.percentOfDiscount
        ? subscription.mainPrices.oldPrices.percentOfDiscount -
          CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : SUBSCRIPTION_DISCOUNTS.DEFAULT

    return prevDiscountAmount
  }

  const prevDiscount = getPrevDiscount()

  const renderDiscountText = () => {
    if (!isLuvlyCancelIntro1Cohort) {
      return (
        <Trans
          i18nKey="purchase7.cancelOfferDiscount"
          components={[<i />, <strong />]}
          values={{
            discount: prevDiscount + CANCEL_OFFER_ADDITIONAL_DISCOUNT,
            prevDiscount,
          }}
        />
      )
    }

    return (
      <Trans
        i18nKey="purchase7.cancelOfferDiscountV2"
        components={[<i />, <strong />]}
        values={{
          discount: prevDiscount + CANCEL_OFFER_ADDITIONAL_DISCOUNT,
        }}
      />
    )
  }

  if (isLuvlyChinCohort) {
    return (
      <S.DiscountBanner data-testid="cancel-offer-container">
        {renderDiscountText()}
      </S.DiscountBanner>
    )
  }

  return (
    <S.Root
      data-testid="cancel-offer-container"
      isSmallerFontSize={language === Locale.GERMAN}
    >
      {renderDiscountText()}
    </S.Root>
  )
}
