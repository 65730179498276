import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import chart from 'assets/images/coaching-chart.png'

import { StyledSkipUpsellCoachingBlock as S } from './SkipUpsellCoachingBlock.styles'

type TSkipUpsellCoachingBlockProps = {
  handleContinue: () => void
}

export const SkipUpsellCoachingBlock: React.FC<
  TSkipUpsellCoachingBlockProps
> = ({ handleContinue }: TSkipUpsellCoachingBlockProps) => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <Container fields={16}>
        <S.Title>{t('upsellCoaching.skipModal.title')}</S.Title>
        <S.Subtitle>
          <Trans i18nKey="upsellCoaching.skipModal.subtitle" />
        </S.Subtitle>
        <S.GraphContainer>
          <S.Chart src={chart} alt="chart" />
          <S.Note>{t('upsellCoaching.skipModal.graphNote')}</S.Note>
        </S.GraphContainer>
        <S.DiscountContainer>
          {t('upsellCoaching.skipModal.extraDiscount')}
        </S.DiscountContainer>
      </Container>
      <S.ButtonContainer>
        <Button onClick={handleContinue}>{t('actions.great')}</Button>
      </S.ButtonContainer>
    </S.Root>
  )
}
