import {
  selectUUID,
  selectUserConfigData,
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'
import { store } from 'root-redux/store'

import {
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { CookieConsentOption, EU_COUNTRY_CODES } from 'root-constants'

import { getAxonItems } from './getAxonItems'

export const sendAddToCartEvents = () => {
  const state = store.getState()

  const config = selectUserConfigData(state)
  const userCountryCode = selectUserCountryCode(state)
  const uuid = selectUUID(state)
  const selectedSubscription = selectSubscription(state)
  const email = selectUserOnboardingEmail(state)
  const currentPrice = selectSubscriptionFullPrice(state)
  const trialPrice = selectSubscriptionTrialPeriodPrice(state)

  const isEUUser = EU_COUNTRY_CODES.includes(userCountryCode.toUpperCase())

  const userCookieConsentAnswer = config?.cookieConsent as string[]
  const hasFunctionalCookie = userCookieConsentAnswer?.includes(
    CookieConsentOption.FUNCTIONAL_COOKIES,
  )
  const isPersonalDataAllowed = (isEUUser && hasFunctionalCookie) || !isEUUser

  window.fbq('track', 'AddToCart', {}, { eventID: uuid })

  selectedSubscription &&
    googleAnalyticsLogger.logAddingToCart(selectedSubscription)

  window.axon &&
    window.axon('track', 'add_to_cart', {
      value: trialPrice || currentPrice,
      ...(selectedSubscription && {
        currency: selectedSubscription.currency.toUpperCase(),
        items: getAxonItems([selectedSubscription]),
      }),
    })

  window.ttq && window.ttq.track('AddToCart', { event_id: uuid })

  window.rdt &&
    window.rdt('track', 'AddToCart', {
      email: isPersonalDataAllowed ? email : '',
      externalId: uuid,
    })

  window.snaptr &&
    window.snaptr('track', 'ADD_CART', {
      user_email: isPersonalDataAllowed ? email : '',
    })

  window.obApi && window.obApi('track', 'Add To Cart')
  window._tfa && window._tfa.push({ notify: 'event', name: 'add_to_cart' })
}
