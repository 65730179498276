import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { IntroOfferSelectSubscriptionItem } from 'modules/purchase/components/IntroOfferSelectSubscriptionItem'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { OptionType } from 'root-constants'

import { ColoredSelectSubscriptionItem } from '../ColoredSelectSubscriptionItem'
import { StyledSubscriptionsIntroOfferBlock as S } from './SubscriptionsIntroOfferBlock.styles'

type TSubscriptionsIntroOfferBlockProps = {
  hasTitle?: boolean
}
export const SubscriptionsIntroOfferBlock: React.FC<
  TSubscriptionsIntroOfferBlockProps
> = ({ hasTitle = true }: TSubscriptionsIntroOfferBlockProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const { isLuvlyWheelCohort } = useActiveCohortIdentifier()

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  const SelectSubscriptionItem = isLuvlyWheelCohort
    ? ColoredSelectSubscriptionItem
    : IntroOfferSelectSubscriptionItem

  return (
    <S.Wrapper>
      {hasTitle && (
        <S.Title>{t`purchaseIntroOffer.subscription.unleashBeauty`}</S.Title>
      )}
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          withoutMargin
        >
          <SelectSubscriptionItem
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
          />
        </Option>
      ))}
    </S.Wrapper>
  )
}
