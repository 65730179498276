import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellCoachingSteps = {
  Title: styled.h1`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  Step: styled.div`
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${Color.GRAY_LIGHT};

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 40px;
    }
  `,
  StepNumber: styled.h3`
    color: ${Color.PRIMARY};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 8px;
  `,
  StepTitle: styled.div`
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  StepDescription: styled.p`
    color: ${Color.GRAY};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
}
