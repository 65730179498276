import React from 'react'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useOnboardingProgressStepsLength } from 'hooks/useOnboardingProgressStepsLength'

import { StyledLinearProgressBar as S } from './LinearProgressBar.styles'

type TLinearProgressBarProps = {
  progressStatus?: number
}

export const LinearProgressBar: React.FC<TLinearProgressBarProps> = ({
  progressStatus,
}: TLinearProgressBarProps) => {
  const { progressValue } = useGetPageInfo()
  const cohortToUse = useCohortToUse()
  const progressStepsLength = useOnboardingProgressStepsLength()

  return (
    <S.Wrapper cohort={cohortToUse}>
      <S.Progress
        value={progressValue ?? progressStatus}
        max={progressStepsLength}
        cohort={cohortToUse}
      />
    </S.Wrapper>
  )
}
