import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { AnalyzingAnswersModal } from 'components/AnalyzingAnswersModal'
import { BlinkingPageTitle } from 'components/BlinkingPageTitle'
import { Container } from 'components/Container'
import { ProgressAnalyzer } from 'components/ProgressAnalyzer'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useManualAnalyticsSending } from 'hooks/useManualAnalyticsSending'
import { useNextStep } from 'hooks/useNextStep'
import { useProgressAnimationData } from 'hooks/useProgressAnimationData'

import { TPageProps } from 'models/common.model'

import RatingImage from 'assets/images/stars.png'

import { Color, I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledAnalyzingAnswers as S } from './AnalyzingAnswers.styles'
import {
  FIRST_MODAL_DISABLE_POINT,
  HIGH_SWITCH_BREAKPOINT,
  LOW_SWITCH_BREAKPOINT,
  MODAL_HIDING_DELAY,
  QUESTION,
  REVIEWS,
  SECOND_MODAL_DISABLE_POINT,
  STATIC_ANIMATION_DURATION,
  STOP_COUNTER_AT,
} from './constants'

export const AnalyzingAnswers: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const handleSendAnalytics = useManualAnalyticsSending()

  const [answers, setAnswers] = useState<string[]>([])

  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  // useCallback to prevent duplicating events
  const goToNextPage = useCallback(
    () => handleContinue(answers),
    [answers, handleContinue],
  )

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
  }

  const handleContinueAnimation = (playback: () => void) => {
    const timerId = setTimeout(() => {
      playback && playback()
      clearTimeout(timerId)
    }, MODAL_HIDING_DELAY)
  }

  const handleChange = (
    animationPlayback: () => void,
    question: string,
    answer: string,
  ) => {
    handleContinueAnimation(animationPlayback)
    handleSendAnalytics(question, answer, pageId)
  }

  const {
    firstCounter,
    isFirstCounterFinished,
    secondCounter,
    isSecondCounterFinished,
    secondProgressAnimationPlayback,
    thirdCounter,
    isThirdCounterFinished,
    thirdProgressAnimationPlayback,
    fourthCounter,
    isFourthCounterFinished,
  } = useProgressAnimationData({
    goToNextPage,
    STATIC_ANIMATION_DURATION,
    STOP_COUNTER_AT,
  })

  return (
    <Container>
      {firstCounter <= LOW_SWITCH_BREAKPOINT && !isFirstCounterFinished && (
        <BlinkingPageTitle>{t`onboarding.analyzingAnswers.title1`}</BlinkingPageTitle>
      )}
      {firstCounter > LOW_SWITCH_BREAKPOINT &&
        !isFirstCounterFinished &&
        firstCounter <= HIGH_SWITCH_BREAKPOINT && (
          <BlinkingPageTitle>
            {t`onboarding.analyzingAnswers.title2`}
          </BlinkingPageTitle>
        )}
      {isFirstCounterFinished &&
        !isSecondCounterFinished &&
        secondCounter <= LOW_SWITCH_BREAKPOINT && (
          <BlinkingPageTitle>{t`onboarding.analyzingAnswers.title3`}</BlinkingPageTitle>
        )}
      {isFirstCounterFinished &&
        !isSecondCounterFinished &&
        secondCounter > LOW_SWITCH_BREAKPOINT &&
        secondCounter <= HIGH_SWITCH_BREAKPOINT && (
          <BlinkingPageTitle>
            <Trans
              i18nKey="onboarding.analyzingAnswers.title4"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </BlinkingPageTitle>
        )}
      {isSecondCounterFinished &&
        !isThirdCounterFinished &&
        thirdCounter <= LOW_SWITCH_BREAKPOINT && (
          <BlinkingPageTitle>{t`onboarding.analyzingAnswers.title5`}</BlinkingPageTitle>
        )}
      {isSecondCounterFinished &&
        !isThirdCounterFinished &&
        thirdCounter > LOW_SWITCH_BREAKPOINT &&
        thirdCounter <= HIGH_SWITCH_BREAKPOINT && (
          <BlinkingPageTitle>
            <Trans
              i18nKey="onboarding.analyzingAnswers.title6"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </BlinkingPageTitle>
        )}
      {isThirdCounterFinished &&
        !isFourthCounterFinished &&
        fourthCounter <= LOW_SWITCH_BREAKPOINT && (
          <BlinkingPageTitle>
            <Trans
              i18nKey="onboarding.analyzingAnswers.title4"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </BlinkingPageTitle>
        )}
      {isThirdCounterFinished &&
        !isFourthCounterFinished &&
        fourthCounter > LOW_SWITCH_BREAKPOINT &&
        fourthCounter <= HIGH_SWITCH_BREAKPOINT && (
          <BlinkingPageTitle>
            <Trans
              i18nKey="onboarding.analyzingAnswers.title7"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </BlinkingPageTitle>
        )}

      <S.ProgressContainer>
        <ProgressAnalyzer
          color={Color.PRIMARY}
          value={firstCounter}
          isInProgress={!!firstCounter}
          isFinished={isFirstCounterFinished}
        >
          <Trans
            i18nKey="onboarding.analyzingAnswers.progressTitle1"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </ProgressAnalyzer>
        <ProgressAnalyzer
          color={Color.PRIMARY}
          value={secondCounter}
          isInProgress={!!secondCounter}
          isFinished={isSecondCounterFinished}
        >
          <Trans
            i18nKey="onboarding.analyzingAnswers.progressTitle2"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </ProgressAnalyzer>
        <ProgressAnalyzer
          color={Color.PRIMARY}
          value={thirdCounter}
          isInProgress={!!thirdCounter}
          isFinished={isThirdCounterFinished}
        >
          <Trans
            i18nKey="onboarding.analyzingAnswers.progressTitle3"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </ProgressAnalyzer>
        <ProgressAnalyzer
          color={Color.PRIMARY}
          value={fourthCounter}
          isInProgress={!!fourthCounter}
          isFinished={isFourthCounterFinished}
        >
          <Trans
            i18nKey="onboarding.analyzingAnswers.progressTitle4"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </ProgressAnalyzer>
      </S.ProgressContainer>
      <S.CarouselTitle>
        <Trans
          i18nKey="onboarding.analyzingAnswers.carouselTitle"
          context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
        />
      </S.CarouselTitle>
      <Carousel
        className="analyzingCarousel"
        spaceBetween={35}
        slidesPerView={1}
        loop
        pagination={false}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {REVIEWS.map(({ text, author }) => {
          return (
            <SwiperSlide key={text}>
              <S.Card>
                <S.CardRating src={RatingImage} alt="" />
                <S.CardReview>{t(text)}</S.CardReview>
                <S.CardReviewer>{t(author)}</S.CardReviewer>
              </S.Card>
            </SwiperSlide>
          )
        })}
      </Carousel>

      <AnalyzingAnswersModal
        isModalVisible={secondCounter === STOP_COUNTER_AT}
        disabled={answers.length > FIRST_MODAL_DISABLE_POINT}
        {...optionProps}
        onChange={(answer) => {
          setAnswers([...answers, answer])
          handleChange(
            secondProgressAnimationPlayback,
            t`onboarding.analyzingAnswers.question1`,
            answer,
          )
        }}
      >
        {t`onboarding.analyzingAnswers.question1`}
      </AnalyzingAnswersModal>
      <AnalyzingAnswersModal
        isModalVisible={thirdCounter === STOP_COUNTER_AT}
        disabled={answers.length > SECOND_MODAL_DISABLE_POINT}
        {...optionProps}
        onChange={(answer) => {
          setAnswers([...answers, answer])
          handleChange(
            thirdProgressAnimationPlayback,
            t`onboarding.analyzingAnswers.question2`,
            answer,
          )
        }}
      >
        <Trans
          i18nKey="onboarding.analyzingAnswers.question2"
          components={[<br />]}
        />
      </AnalyzingAnswersModal>
    </Container>
  )
}
