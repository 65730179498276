import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel } from 'swiper/react'

import { StyledUpsellCoachingBeforeAfter as S } from './UpsellCoachingBeforeAfter.styles'
import { COACHING_BEFORE_AFTER } from './constants'

export const UpsellCoachingBeforeAfter: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <S.Title>{t('upsellCoaching.fastResults')}</S.Title>
      <S.Subtitle>{t('upsellCoaching.getRidOfInsecurity')}</S.Subtitle>
      <Carousel
        slidesPerView={1}
        loop
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {COACHING_BEFORE_AFTER.map(({ title, img }) => (
          <S.SwiperSlide key={title}>
            <S.Hero>
              <img src={img} alt="user-before-after" />
              <S.Review>
                <Trans i18nKey={title} />
              </S.Review>
            </S.Hero>
          </S.SwiperSlide>
        ))}
        <S.ReviewDisclaimer>
          <Trans i18nKey="upsellCoaching.reviewDisclaimer" />
        </S.ReviewDisclaimer>
      </Carousel>
    </>
  )
}
