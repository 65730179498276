import styled from 'styled-components'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledSmallStepsHelp = {
  Container: styled(Container)`
    min-height: calc(var(--full-height) - 220px);
  `,
  PageTitle: styled(PageTitle)`
    text-align: left;
  `,
  Image: styled.img`
    min-height: 186px;
    margin-bottom: 16px;
  `,
  Review: styled.div`
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    border-radius: 20px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px #dfe3f3;
  `,
}
