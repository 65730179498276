export const QUESTION = 'Stress level'

const OPTION_VALUES = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
}

export const STRESS_LEVEL_ANSWERS = [
  {
    value: OPTION_VALUES.HIGH,
    text: 'onboarding.stressLevel.high',
  },
  {
    value: OPTION_VALUES.MEDIUM,
    text: 'onboarding.stressLevel.medium',
  },
  {
    value: OPTION_VALUES.LOW,
    text: 'onboarding.stressLevel.low',
  },
]
