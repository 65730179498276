import React from 'react'
import { useTranslation } from 'react-i18next'

import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { StyledUsersGallery as S } from './UsersGallery.styles'
import { USERS_GALLERY } from './constants'

export const UsersGallery: React.FC = () => {
  const { t } = useTranslation()
  return (
    <S.Root>
      <Carousel
        slidesPerView={1}
        loop
        pagination={{
          clickable: true,
        }}
        navigation
        modules={[Pagination, Autoplay, Navigation]}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
      >
        {USERS_GALLERY.map(({ name, img }) => (
          <SwiperSlide key={name}>
            <S.ImageWrapper>
              <S.SlideImage src={img} alt="user-image-before-after" />
              <S.User>{t(name)}</S.User>
            </S.ImageWrapper>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.Root>
  )
}
