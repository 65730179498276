export const REVIEWS_SHORT = [
  {
    id: 1,
    title: 'reTargetingPurchase.review1.title',
    text: 'reTargetingPurchase.review1.text',
    name: 'reTargetingPurchase.review1.name',
    date: 'reTargetingPurchase.review1.date',
  },
  {
    id: 2,
    title: 'reTargetingPurchase.review2.title',
    text: 'reTargetingPurchase.review2.text',
    name: 'reTargetingPurchase.review2.name',
    date: 'reTargetingPurchase.review2.date',
  },
]

export const REVIEWS_FULL = [
  {
    id: 1,
    title: 'reTargetingPurchase.review1.title',
    text: 'reTargetingPurchase.review1.text',
    name: 'reTargetingPurchase.review1.name',
    date: 'reTargetingPurchase.review1.date',
  },
  {
    id: 2,
    title: 'reTargetingPurchase.review2.title',
    text: 'reTargetingPurchase.review2.text',
    name: 'reTargetingPurchase.review2.name',
    date: 'reTargetingPurchase.review2.date',
  },
  {
    id: 3,
    title: 'reTargetingPurchase.review3.title',
    text: 'reTargetingPurchase.review3.text',
    name: 'reTargetingPurchase.review3.name',
    date: 'reTargetingPurchase.review3.date',
  },
]
