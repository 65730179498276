/* eslint-disable camelcase */
import { ISubscription } from 'models/subscriptions.model'

import { TimeInterval } from 'root-constants'

type TAxonItem = {
  item_id: string
  item_name: string
  price: number
  quantity: number
  item_category_id: number
}

const APPLOVIN_SKIN_CARE_CATEGORY_ID = 567

export const getAxonItem = ({
  id,
  periodQuantity,
  periodName,
  trialPrice,
  mainPrice,
}: {
  id: string
  periodQuantity: number | null
  periodName: TimeInterval | null
  trialPrice: number
  mainPrice: number
}): TAxonItem => ({
  item_id: id,
  item_category_id: APPLOVIN_SKIN_CARE_CATEGORY_ID,
  item_name: `${periodQuantity} ${periodName} plan`,
  price: trialPrice || mainPrice,
  quantity: 1,
})

export const getAxonItems = (subscriptions: ISubscription[]): TAxonItem[] =>
  subscriptions.map(({ id, mainPrices, trialPrices }) =>
    getAxonItem({
      id,
      periodQuantity: mainPrices.periodQuantity,
      periodName: mainPrices.periodName,
      trialPrice: trialPrices.fullPrice,
      mainPrice: mainPrices.fullPrice,
    }),
  )
