import styled from 'styled-components'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

type TBenefitProps = {
  bgColor: string
}

export const StyledLongPaywallUserBenefits = {
  Wrapper: styled.div`
    margin: 48px auto;
  `,
  Subtitle: styled.h2<TLocaleStylesProps>`
    text-align: center;
    color: ${({ theme }) => theme.userBenefits.title.color};
    font-weight: 700;
    font-size: ${({ theme }) => theme.userBenefits.title.fontSize};
    line-height: ${({ theme }) => theme.userBenefits.title.lineHeight};
  `,
  BenefitCardsWrapper: styled.div`
    margin: 20px auto 48px;

    & > div:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `,
  BenefitCard: styled.div`
    max-width: 375px;
    width: 100%;
    display: flex;
    border-radius: 20px;
  `,
  ImageContainer: styled.div`
    width: 88px;
    flex-shrink: 0;
  `,
  BenefitImage: styled.img`
    object-fit: fill;
    width: 100%;
    height: 100%;
  `,
  Benefit: styled.div<TBenefitProps & TLocaleStylesProps>`
    display: flex;
    align-items: center;
    flex-shrink: 1;
    border-radius: 0 20px 20px 0;
    background: ${({ bgColor }) => bgColor};
    font-weight: ${({ theme }) => theme.userBenefits.benefit.fontWeight};
    color: ${Color.BLUE_DARK};
    padding: 24px 16px;
    line-height: 24px;
    font-size: 16px;
  `,
  DailyScheduleImage: styled.img`
    margin-top: 24px;
    width: 100%;
  `,
}
