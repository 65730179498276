import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { selectUUID } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import appStore from 'assets/images/app-store-2.png'
import googlePlay from 'assets/images/google-play.png'
import leftLeaf from 'assets/images/left-leaf.svg'
import rightLeaf from 'assets/images/right-leaf.svg'
import arrowIcon from 'assets/images/sprite/arrow-bottom.svg'
import stars from 'assets/images/sprite/stars-orange.svg'

import { StyledIntroGiftCard as S } from './IntroGiftCard.styles'
import {
  BENEFITS_DATA,
  GIFT_WELCOME_PAGE_TRACKED_BREAKPOINTS,
  HOW_IT_WORKS_DATA,
  QUESTION,
} from './constants'

export const IntroGiftCard: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const uuid = useSelector(selectUUID)

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
    trackEvent: false,
  })

  useEffect(() => {
    eventLogger.logGiftWelcomePageOpened()
  }, [])

  const handleNextClick = () => {
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logGiftWelcomePageContinued()

    handleContinue('')
  }

  const scrollEvent = (scrolledPart: number) => {
    eventLogger.logGiftWelcomePageScrolled({
      scrolledPart,
    })
  }

  useTrackPageScrollDepth({
    trackedBreakpoints: GIFT_WELCOME_PAGE_TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
  })

  return (
    <S.Root>
      <S.IntroMedia>
        <S.Subtitle>{t`onboarding.introGiftCard.subtitle`}</S.Subtitle>
        <S.Text>
          <Trans i18nKey="onboarding.introGiftCard.text" />
        </S.Text>
        <S.ArrowIcon svg={arrowIcon} width={48} />
      </S.IntroMedia>
      <S.Content>
        <S.BenefitsTitle>{t`onboarding.introGiftCard.benefits.title`}</S.BenefitsTitle>
        <S.Benefits>
          {BENEFITS_DATA.map(({ image, text, description }) => (
            <S.Benefit key={text}>
              <S.BenefitImage src={image} alt="icon" />
              <S.BenefitText>{t(text)}</S.BenefitText>
              <S.BenefitDescription>{t(description)}</S.BenefitDescription>
            </S.Benefit>
          ))}
        </S.Benefits>

        <S.HowItWorksContainer>
          <S.HowItWorksTitle>{t`onboarding.introGiftCard.howItWorks.title`}</S.HowItWorksTitle>
          <S.HowItWorksList>
            {HOW_IT_WORKS_DATA.map(({ image, text, description }) => (
              <S.HowItWorksListItem key={text}>
                <S.HowItWorksImage src={image} alt="step" />
                <S.HowItWorksText>{t(text)}</S.HowItWorksText>
                <S.HowItWorksDescription>
                  {t(description)}
                </S.HowItWorksDescription>
              </S.HowItWorksListItem>
            ))}
          </S.HowItWorksList>
        </S.HowItWorksContainer>

        <S.FaqTitle>{t`onboarding.introGiftCard.faq.title`}</S.FaqTitle>
        <S.FaqItem>
          <S.FaqText>{t`onboarding.introGiftCard.faq.firstText`}</S.FaqText>
          <S.FaqDescription>{t`onboarding.introGiftCard.faq.firstDescription`}</S.FaqDescription>
        </S.FaqItem>

        <S.FaqItem>
          <S.FaqText>{t`onboarding.introGiftCard.faq.secondText`}</S.FaqText>
          <S.FaqDescription>{t`onboarding.introGiftCard.faq.secondDescription`}</S.FaqDescription>
        </S.FaqItem>

        <S.FaqItem backgroundColor="#fff5ee">
          <S.FaqText>{t`onboarding.introGiftCard.faq.thirdText`}</S.FaqText>
          <S.FaqDescription>
            {t`onboarding.introGiftCard.faq.thirdDescription`}
            <a
              href="https://account-luvly.gistage.com/contact-form"
              target="_blank"
              rel="noreferrer"
            >
              Contact support
            </a>
          </S.FaqDescription>
        </S.FaqItem>

        <S.RatingContainer>
          <S.Leaf src={leftLeaf} alt="leaf" gridArea="leftLeaf" />
          <S.Stars svg={stars} width={128} />
          <S.Reviews>140,850+</S.Reviews>
          <S.RatingText>{t`onboarding.introGiftCard.rating.text`}</S.RatingText>
          <S.Leaf src={rightLeaf} alt="leaf" gridArea="rightLeaf" />
        </S.RatingContainer>
        <S.Disclaimer>{t`onboarding.introGiftCard.rating.disclaimer`}</S.Disclaimer>

        <S.ButtonsContainer>
          <img src={appStore} alt="app-store" />
          <img src={googlePlay} alt="google-play" />
        </S.ButtonsContainer>
      </S.Content>

      <S.StickyButtonContainer>
        <Button
          type="button"
          theme={buttonTheme.LUVLY_SECONDARY}
          onClick={handleNextClick}
        >
          {t`onboarding.introGiftCard.buttonText`}
        </Button>
      </S.StickyButtonContainer>
    </S.Root>
  )
}
