import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledTriedKoreanBeauty = {
  Root: styled.div`
    min-height: calc(var(--full-height) - 63px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  Subtitle: styled.p`
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.LIGHT};

    & strong {
      color: ${Color.DARK};
    }
  `,
  Image: styled.img`
    width: 100%;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
}
