import styled from 'styled-components'

import heroBg from 'assets/images/re-targeting-purchase-hero-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReTargetingPurchase = {
  Root: styled.div`
    font-family: 'Open Sans';
    padding-bottom: 40px;
  `,
  TickerContainer: styled.div`
    margin-top: -15px;
  `,
  Hero: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    min-height: 500px;
    background: url(${heroBg}) no-repeat center;
    background-size: cover;
    padding-bottom: 96px;
    margin: 0 auto 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 4px;
    text-align: center;
    font-family: 'Open Sans';
  `,
  Subtitle: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${Color.GRAY};
    font-family: 'Open Sans';
  `,
  Plans: styled.div`
    margin-bottom: 40px;
  `,
  TimerTop: styled.div`
    margin-bottom: 16px;
  `,
  TimerBottom: styled.div`
    margin: 32px auto;
  `,
  MoneyBackGuarantee: styled.div`
    display: flex;
    align-items: center;
    border-radius: 12px;
    background: #f2f2f2;
    padding: 13px 12px;
    margin-bottom: 24px;

    & img {
      width: 24px;
      margin-right: 12px;
    }
  `,
  MoneyBackGuaranteeTitle: styled.h4`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: ${Color.BLUE_DARK};
    font-family: 'Open Sans';
  `,
  MoneyBackGuaranteeText: styled.p`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    font-family: 'Open Sans';
    color: ${Color.GRAY};
  `,
  SecondaryTitle: styled.h3`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
    font-family: 'Open Sans';
    text-align: center;
  `,
  Map: styled.div`
    margin-bottom: 40px;

    & img {
      margin-top: 24px;
    }
  `,

  Awards: styled.div`
    margin-bottom: 48px;

    & img {
      margin-top: 24px;
    }
  `,
}
