import styled from 'styled-components'

import gift from 'assets/images/gift.png'

import { Color } from 'root-constants'

export const StyledUpsellCoachingCancelOfferBanner = {
  Root: styled.div`
    border-radius: 12px;
    background: linear-gradient(91deg, #b7a9ff 0%, #7650f8 98.82%);
    padding: 16px 16px 16px 80px;
    position: relative;
    margin-bottom: 16px;

    &::before {
      content: '';
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background-color: rgba(255, 255, 255, 0.24);
      background-image: url(${gift});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
  `,
  Text: styled.p`
    color: rgba(255, 255, 255, 0.7);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    & > strong {
      color: ${Color.WHITE};
    }
  `,
}
