import blushEmoji from 'assets/images/blush-emoji.png'
import CustomerReview1 from 'assets/images/customer-photo-1.png'
import CustomerReview2 from 'assets/images/customer-photo-2.png'
import CustomerReview3 from 'assets/images/customer-photo-3.png'
import CustomerReview4 from 'assets/images/customer-photo-4.png'
import CustomerReview5 from 'assets/images/customer-photo-5.png'
import CustomerReview6 from 'assets/images/customer-photo-6.png'
import CustomerReviewGoogle1 from 'assets/images/customer-photo-google-funnel-1.png'
import CustomerReviewGoogle2 from 'assets/images/customer-photo-google-funnel-2.png'
import CustomerReviewGoogle3 from 'assets/images/customer-photo-google-funnel-3.png'
import CustomerReviewGoogle4 from 'assets/images/customer-photo-google-funnel-4.png'
import CustomerReviewGoogle5 from 'assets/images/customer-photo-google-funnel-5.png'
import CustomerReviewGoogle6 from 'assets/images/customer-photo-google-funnel-6.png'
import raisedHandsEmoji from 'assets/images/raised-hands-emoji.png'
import alicia from 'assets/images/reviewer-alicia.png'
import lien from 'assets/images/reviewer-lien.png'
import theresa from 'assets/images/reviewer-theresa.png'
import smileyEmoji from 'assets/images/smiley-emoji.png'
import twoHeartsEmoji from 'assets/images/two-hearts-emoji.png'

export const DEFAULT_CUSTOMER_REVIEWS = [
  {
    name: 'onboarding.shortProgramDescription.firstUserName',
    icon: CustomerReview1,
    review: 'onboarding.shortProgramDescription.firstUserReview',
    emoji: [blushEmoji],
  },
  {
    name: 'onboarding.shortProgramDescription.secondUserName',
    icon: CustomerReview2,
    review: 'onboarding.shortProgramDescription.secondUserReview',
  },
  {
    icon: CustomerReview3,
    name: 'onboarding.shortProgramDescription.thirdUserName',
    review: 'onboarding.shortProgramDescription.thirdUserReview',
  },
]

export const DEFAULT_CUSTOMER_REVIEWS_V2 = [
  {
    name: 'onboarding.shortProgramDescription.firstUserNameGoogle',
    icon: CustomerReviewGoogle1,
    review: 'onboarding.shortProgramDescription.firstUserReview',
    emoji: [blushEmoji],
  },
  {
    name: 'onboarding.shortProgramDescription.secondUserName',
    icon: CustomerReviewGoogle2,
    review: 'onboarding.shortProgramDescription.secondUserReview',
  },
  {
    icon: CustomerReviewGoogle3,
    name: 'onboarding.shortProgramDescription.thirdUserName',
    review: 'onboarding.shortProgramDescription.thirdUserReview',
  },
]

export const DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2 = [
  {
    name: 'onboarding.shortProgramDescription.fourthUserName',
    icon: CustomerReviewGoogle4,
    review: 'onboarding.shortProgramDescription.fourthUserReview',
  },
  {
    name: 'onboarding.shortProgramDescription.fifthUserName',
    icon: CustomerReviewGoogle5,
    review: 'onboarding.shortProgramDescription.fifthUserReview',
    emoji: [raisedHandsEmoji, smileyEmoji, twoHeartsEmoji],
  },
  {
    name: 'onboarding.shortProgramDescription.sixthUserName',
    icon: CustomerReviewGoogle6,
    review: 'onboarding.shortProgramDescription.sixthUserReview',
  },
]

export const DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS = [
  {
    name: 'onboarding.shortProgramDescription.fourthUserName',
    icon: CustomerReview4,
    review: 'onboarding.shortProgramDescription.fourthUserReview',
  },
  {
    name: 'onboarding.shortProgramDescription.fifthUserName',
    icon: CustomerReview5,
    review: 'onboarding.shortProgramDescription.fifthUserReview',
    emoji: [raisedHandsEmoji, smileyEmoji, twoHeartsEmoji],
  },
  {
    name: 'onboarding.shortProgramDescription.sixthUserName',
    icon: CustomerReview6,
    review: 'onboarding.shortProgramDescription.sixthUserReview',
  },
]

export const DEFAULT_CUSTOMER_REVIEWS_V3 = [
  {
    name: 'purchase5.reviews.firstReviewer.name',
    icon: lien,
    review: 'purchase5.reviews.firstReviewer.review',
  },
  {
    name: 'purchase5.reviews.secondReviewer.name',
    icon: theresa,
    review: 'purchase5.reviews.secondReviewer.review',
  },
  {
    name: 'purchase5.reviews.thirdReviewer.name',
    icon: alicia,
    review: 'purchase5.reviews.thirdReviewer.review',
  },
]

export const DEFAULT_CUSTOMER_REVIEWS_V4 = [
  {
    name: 'purchase8.reviews.firstReviewer.name',
    review: 'purchase8.reviews.firstReviewer.review',
  },
  {
    name: 'purchase8.reviews.secondReviewer.name',
    review: 'purchase8.reviews.secondReviewer.review',
  },
  {
    name: 'purchase8.reviews.thirdReviewer.name',
    review: 'purchase8.reviews.thirdReviewer.review',
  },
]

export const DEFAULT_CUSTOMER_REVIEWS_V5 = [
  {
    name: 'purchase9.reviews.firstReviewer.name',
    review: 'purchase9.reviews.firstReviewer.review',
  },
  {
    name: 'purchase9.reviews.secondReviewer.name',
    review: 'purchase9.reviews.secondReviewer.review',
  },
  {
    name: 'purchase9.reviews.thirdReviewer.name',
    review: 'purchase9.reviews.thirdReviewer.review',
  },
]
