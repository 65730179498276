import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'storybook-ui'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import koreanManStarImage from 'assets/images/korean-man-star.png'
import koreanWomanStarImage from 'assets/images/korean-woman-star.png'

import { StickyButtonContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { Gender } from 'root-constants'

import { BUTTONS_PROPS } from '../TriedKoreanBeauty/constants'
import { StyledGoingToLoveIt as S } from './GoingToLoveIt.styles'
import { QUESTION } from './constants'

export const GoingToLoveIt: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const userAnswers = useSelector(selectAnswers)

  const isMale = userAnswers?.[PageId.GENDER] === Gender.MALE

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <div>
      <S.Container>
        <S.PageTitle marginBottom={15}>
          <Trans i18nKey="onboarding.goingToLoveIt.title" />
        </S.PageTitle>

        <S.DescriptionBlock>
          <S.Description>
            <Trans i18nKey="onboarding.goingToLoveIt.firstParagraph" />
          </S.Description>

          <S.Description>
            <Trans i18nKey="onboarding.goingToLoveIt.secondParagraph" />
          </S.Description>
        </S.DescriptionBlock>

        <S.Image src={isMale ? koreanManStarImage : koreanWomanStarImage} />
      </S.Container>

      <StickyButtonContainer centerMode>
        <Button {...BUTTONS_PROPS} onClick={handleNextClick}>
          {t`actions.continue`}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
