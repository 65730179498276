import styled from 'styled-components'

import appNumberOne from 'assets/images/app-number-one.png'

import {
  CustomBackgroundStyles,
  DoubleChinTitle,
  customBackgroundStyles,
} from 'common-styles'
import { Color } from 'root-constants'

export const StyledWeWantYouLookBest = {
  Root: styled.div<{ background: CustomBackgroundStyles }>`
    ${customBackgroundStyles}
  `,
  Title: styled(DoubleChinTitle)`
    text-align: center;
  `,
  AppNumberOne: styled.div`
    height: 94px;
    background-image: url(${appNumberOne});
    background-size: 211px 94px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 12px;
  `,
  StarsWrapper: styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 8px;

    & > img {
      width: 108px;
      height: auto;
    }
  `,
  HarvardApproval: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 4px;
  `,
  HarvardLogo: styled.img`
    width: 40px;
    height: 40px;
  `,
  Text: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
  `,
  BeforeAfterImage: styled.img`
    width: 360px;
    height: auto;
    margin: 0 auto;
  `,
}
