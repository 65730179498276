import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellCoachingBenefits = {
  Title: styled.h1`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  BenefitsContainer: styled.div`
    margin-bottom: 40px;
  `,
  BenefitRow: styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;

    svg {
      margin-right: 12px;
      min-width: 40px;
    }

    &:not(:first-child) {
      p {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid ${Color.CORAL_GRAY};
      }
    }
  `,
  Benefit: styled.p`
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
}
