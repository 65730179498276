import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2 } from 'components/CustomerReviews/constants'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectLanguage } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useAxonPurchaseEvent } from 'hooks/useAxonPurchaseEvent'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { useScrollToTarget } from 'hooks/useScrollToTarget'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { CancelOfferDiscountInfo } from 'modules/purchase/components/CancelOfferDiscountInfo'
import { LongPaywallAppGallery } from 'modules/purchase/components/LongPaywallAppGallery'
import { LongPaywallCustomerReviews } from 'modules/purchase/components/LongPaywallCustomerReviews'
import { LongPaywallShortUserProfile } from 'modules/purchase/components/LongPaywallShortUserProfile'
import { LongPaywallUserBenefits } from 'modules/purchase/components/LongPaywallUserBenefits'
import { LongPaywallUserGoalsGallery } from 'modules/purchase/components/LongPaywallUserGoals'
import { LongPaywallUserTarget } from 'modules/purchase/components/LongPaywallUserTarget'
import { PurchaseRefundBanner } from 'modules/purchase/components/PurchaseRefundBanner'
import { PurchaseRefundInstructions } from 'modules/purchase/components/PurchaseRefundInstructions'
import { SubscriptionsIntroOfferBlock } from 'modules/purchase/components/SubscriptionsIntroOfferBlock'
import { SUBSCRIPTION_DISCOUNTS, TEN_MINUTES } from 'modules/purchase/constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  useTimerForTarget,
} from 'modules/purchase/hooks'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { useScrollEvent } from '../../hooks'
import { CheckoutList } from '../CheckoutList'
import {
  LONG_PAYWALL_TRACKED_BREAKPOINTS,
  NUMBER_ONE_APP_IMAGE_PATH,
} from '../PurchaseVariant7/constants'
import { StyledPurchaseRefund as S } from './PurchaseRefund.styles'
import { DEFAULT_REFUND_CUSTOMER_REVIEWS } from './constants'

export const PurchaseRefund: React.FC = () => {
  const [isCheckoutShown, setIsCheckoutShown] = useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const topSubscriptionsBlock = useRef<HTMLDivElement | null>(null)
  const bottomSubscriptionsBlock = useRef<HTMLDivElement | null>(null)
  const timerElementRef = useRef<HTMLParagraphElement | null>(null)
  const topDuplicateTimerElementRef = useRef<HTMLSpanElement | null>(null)
  const bottomDuplicateTimerElementRef = useRef<HTMLSpanElement | null>(null)
  const subscription = useSelector(selectSubscription)
  const language = useSelector(selectLanguage)

  const { arePricesReady } = usePricesStatus()
  const hasVatInfo = useVatInfo()
  const isCancelOfferRoute = useIsCurrentRoute(PageId.PURCHASE_CANCEL_REFUND)
  const { isHigherPricingRefundActive } = useABTest()

  const { scrollToTarget } = useScrollToTarget()

  useDefaultSubscription()

  const { scrollEvent } = useScrollEvent(
    isCancelOfferRoute ? ScreenName.CANCEL_OFFER_LONG : ScreenName.PURCHASE,
  )

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    isCancelOfferRoute ? ScreenName.CANCEL_OFFER_LONG : ScreenName.PURCHASE,
  )

  const isEnLocale = language === Locale.ENGLISH

  useLayoutEffect(() => {
    dispatch(
      setScreenNameAction(
        isCancelOfferRoute ? ScreenName.CANCEL_OFFER_LONG : ScreenName.PURCHASE,
      ),
    )

    const pricesTags: string[] = []

    hasVatInfo
      ? pricesTags.push(SubscriptionTagsType.TAX)
      : pricesTags.push(SubscriptionTagsType.NO_TAX)

    isCancelOfferRoute && pricesTags.push(SubscriptionTagsType.CANCEL_OFFER)

    if (isHigherPricingRefundActive) {
      pricesTags.push(SubscriptionTagsType.HIGHER_PRICES)
    }

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        pricesTags.join(','),
      ),
    )
  }, [dispatch, hasVatInfo, isCancelOfferRoute, isHigherPricingRefundActive])

  useAxonPurchaseEvent()

  useTimerForTarget(timerElementRef, TEN_MINUTES.IN_SECONDS, [
    topDuplicateTimerElementRef,
    bottomDuplicateTimerElementRef,
  ])

  useTrackPageScrollDepth({
    trackedBreakpoints: LONG_PAYWALL_TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
    preventSendingEvents: isCheckoutShown,
  })

  const handleShowCheckout = useCallback(async () => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  const discount =
    subscription?.mainPrices.oldPrices.percentOfDiscount ||
    (isCancelOfferRoute
      ? SUBSCRIPTION_DISCOUNTS.CANCEL_DEFAULT
      : SUBSCRIPTION_DISCOUNTS.DEFAULT)

  return arePricesReady ? (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <S.TimerContainer>
            <S.TimerContent>
              <S.TimerText>
                <p>
                  {discount}% {t('purchase7.timer')}
                </p>
                <S.CountdownTime ref={timerElementRef}>
                  {!timerElementRef?.current?.hasAttribute('data-value')
                    ? TEN_MINUTES.AS_STRING
                    : null}
                </S.CountdownTime>
              </S.TimerText>
              <S.TopButton
                onClick={() => scrollToTarget(topSubscriptionsBlock)}
              >
                {t('actions.getMyPlan')}
              </S.TopButton>
            </S.TimerContent>
          </S.TimerContainer>
          {isCancelOfferRoute ? (
            <CancelOfferDiscountInfo />
          ) : (
            <PurchaseRefundBanner isTopPosition />
          )}
          <LongPaywallUserTarget />
          <S.ContainerWrapper isEnLocaleStyle={isEnLocale}>
            <LongPaywallShortUserProfile
              timerRef={topDuplicateTimerElementRef}
            />
            <S.SubscriptionsContainer ref={topSubscriptionsBlock}>
              <SubscriptionsIntroOfferBlock hasTitle={false} />
            </S.SubscriptionsContainer>
            <S.ButtonContainer>
              <Button onClick={handleShowCheckout}>
                {t`actions.getMyPlan`}
              </Button>
            </S.ButtonContainer>
            <PurchaseRefundBanner />
          </S.ContainerWrapper>
          <PurchaseRefundInstructions handleOpenCheckout={handleShowCheckout} />
          <LongPaywallAppGallery />
          <Container>
            <S.NumberOneImage
              src={`${CDN_FOLDER_LINK}${NUMBER_ONE_APP_IMAGE_PATH}_${language}.png`}
              alt="number-one-app"
            />
            <Button onClick={() => scrollToTarget(bottomSubscriptionsBlock)}>
              {t('actions.getMyPlan')}
            </Button>
            <LongPaywallUserBenefits />
          </Container>
          <LongPaywallCustomerReviews
            title="purchaseRefund.reviews.subtitle"
            customerReviews={DEFAULT_REFUND_CUSTOMER_REVIEWS}
            additionalCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2}
          />
          <Container>
            <LongPaywallUserGoalsGallery />
            <LongPaywallShortUserProfile
              title="purchase7.visibleResultSubtitle"
              timerRef={bottomDuplicateTimerElementRef}
            />
            <S.SubscriptionsContainer ref={bottomSubscriptionsBlock}>
              <SubscriptionsIntroOfferBlock hasTitle={false} />
              <S.ButtonContainer>
                <Button onClick={handleShowCheckout}>
                  {t`actions.getMyPlan`}
                </Button>
              </S.ButtonContainer>
            </S.SubscriptionsContainer>
            <PurchaseRefundBanner />
            <S.SecurityInfo />
            <S.PurchaseDisclaimer />
          </Container>
        </S.Wrapper>
      )}
      {isCheckoutShown && (
        <FeaturesReady fallback={<Spinner />}>
          <CheckoutList
            setIsCheckoutShown={setIsCheckoutShown}
            isCancelOffer={isCancelOfferRoute}
          />
        </FeaturesReady>
      )}
    </>
  ) : null
}
