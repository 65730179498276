import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'

import { Color } from 'root-constants'

type TRootProps = {
  withCheckbox?: boolean
}

export const StyledAnswerButtonWithImage = {
  Root: styled.div<TRootProps>`
    width: 100%;
    max-width: 360px;
    padding: ${({ withCheckbox }) =>
      withCheckbox ? '8px 10px 40px' : '8px 10px'};
    background-color: #fff;
    outline: none;
    border: 1px solid transparent;
    position: relative;
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 14px;

    ${({ withCheckbox }) =>
      withCheckbox &&
      css`
        &::after {
          display: block;
          content: '';
          width: 24px;
          height: 24px;
          border: 1px solid #2d324080;
          border-radius: 50%;
          position: absolute;
          bottom: 8px;
          left: 50%;
          transform: translateX(-50%);
        }
      `}

    input:checked + & {
      background: rgba(255, 130, 118, 0.15);
      border: 1px solid ${Color.PRIMARY};
      border-radius: 20px;
    }

    input:checked + &::after {
      border-color: ${Color.PRIMARY};
      background: ${Color.PRIMARY} url(${checkIcon}) no-repeat center;
      background-size: 10px 8.5px;
    }
  `,
  ImageContainer: styled.div`
    aspect-ratio: 130/126;
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
    vertical-align: top;
  `,
}
