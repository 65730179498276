import styled, { css } from 'styled-components'

import { Cohort, Color } from 'root-constants'

type TWrapperProps = {
  hasNavigation: boolean
  hasProgressBar: boolean
  hasShadow?: boolean
}

type TContainerProps = {
  isVisible: boolean
}

type TCohortProps = {
  cohort: Cohort
}

const chinWrapperStyles = css`
  height: 52px;
  align-items: center;
  margin-bottom: 0;
  padding: 5px 16px;
`

const defaultWrapperStyles = css`
  height: 40px;
  align-items: flex-end;
`

const WRAPPER_STYLES_MAP = {
  [Cohort.LUVLY_CHIN]: chinWrapperStyles,
}

const defaultNumberContainerStyles = css`
  font-size: 14px;
  line-height: 24px;
  color: ${Color.LIGHT};
  font-weight: 400;

  & > span:not(:last-of-type) {
    margin-right: 4px;
  }
`

const chinNumberContainerStyles = css`
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;

  & > span:first-of-type {
    color: ${Color.CORAL};
  }

  & > span:not(:first-of-type) {
    color: ${Color.BLUE_DARK};
  }
`

const NUMBER_CONTAINER_STYLES_MAP = {
  [Cohort.LUVLY_CHIN]: chinNumberContainerStyles,
  [Cohort.LUVLY_GIFT]: chinNumberContainerStyles,
}

export const StyledHeaderMinimalistic = {
  Wrapper: styled.header<TWrapperProps & TCohortProps>`
    width: 100%;
    background-color: ${Color.WHITE};
    padding: 10px 16px 0;
    display: flex;
    position: relative;
    z-index: 3;
    justify-content: ${({ hasNavigation }) =>
      hasNavigation ? 'space-between' : 'center'};
    margin-bottom: ${({ hasProgressBar }) => (hasProgressBar ? '8px' : '16px')};
    ${({ hasShadow, cohort }) => {
      if (hasShadow) {
        return css`
          box-shadow: 0 5px 20px #dfe3f3;
          height: 50px;
          align-items: center;
          margin: 0 auto 16px;
          padding: 13px 16px;
        `
      }

      return css`
        ${WRAPPER_STYLES_MAP[cohort] || defaultWrapperStyles}
      `
    }}
  `,
  ArrowIconContainer: styled.div<TContainerProps>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    min-width: 55px;
    height: 24px;
  `,
  PageNumberContainer: styled.div<TContainerProps & TCohortProps>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    min-width: 55px;
    text-align: right;

    ${({ cohort }) =>
      NUMBER_CONTAINER_STYLES_MAP[cohort] || defaultNumberContainerStyles};
  `,
}
