import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledPageTitleDescription as S } from './PageTitleDescription.styles'

export const PageTitleDescription: React.FC<
  TComponentProps & { marginBottom?: number }
> = ({ children, marginBottom }) => {
  return (
    <S.PageTitleDescription marginBottom={marginBottom}>
      {children}
    </S.PageTitleDescription>
  )
}
