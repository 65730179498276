import styled from 'styled-components'

import leftArrow from 'assets/images/left-black-arrow.svg'
import recipientIcon from 'assets/images/recipient-input-icon.svg'
import rightArrow from 'assets/images/right-black-arrow.svg'
import wishIcon from 'assets/images/wish-icon.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCustomizeGiftCard = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${MediaBreakpoint.TABLET}px;
    min-height: calc(100dvh - 80px);
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h2`
    margin-bottom: 30px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    font-family: 'Rozha One', serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  `,
  SwiperContainer: styled.div`
    margin-bottom: 16px;
    min-height: 260px;

    .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 8px;
      background-color: #cacaca;
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background-color: ${Color.CORAL};
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 40px;
      height: 40px;
      top: 80px;
      background-color: ${Color.CORAL_GRAY};
      background-repeat: no-repeat;
      border-radius: 25px;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }

    .swiper-button-prev {
      left: 15px;
      background-image: url(${leftArrow});
      background-position: 45% 50%;
    }

    .swiper-button-next {
      right: 15px;
      background-image: url(${rightArrow});
      background-position: 55% 50%;
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-bottom: 30px;
    padding: 0 16px;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  `,
  InputContainer: styled.div`
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url(${recipientIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &::after {
      content: attr(data-error-text);
      position: absolute;
      top: 65px;
      left: 16px;
      color: ${Color.ERROR};
      font-size: 12px;
      line-height: 12px;
    }
  `,
  Input: styled.input`
    width: 100%;
    height: 64px;
    padding: 20px 16px 20px 48px;
    border-radius: 16px;
    background-color: ${Color.CORAL_GRAY};
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    outline: none;
    border: none;

    &::placeholder {
      color: #a9a9a9;
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
    }
  `,
  TextAreaContainer: styled.div`
    position: relative;
    min-height: 64px;
    max-height: 112px;
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;

    &::before {
      content: '';
      position: absolute;
      top: 20px;
      left: 16px;
      width: 24px;
      height: 24px;
      background-image: url(${wishIcon});
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 1;
    }

    &::after {
      content: attr(data-error-text);
      position: absolute;
      top: 115px;
      left: 16px;
      color: ${Color.ERROR};
      font-size: 12px;
      line-height: 12px;
    }
  `,
  ReplicatedText: styled.p`
    opacity: 0;
    padding: 20px 16px 20px 48px;
    border-radius: 16px;
    overflow-wrap: break-word;
  `,
  Textarea: styled.textarea`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    resize: none;
    overflow-y: auto;
    padding: 20px 16px 20px 48px;
    border-radius: 16px;
    background-color: ${Color.CORAL_GRAY};
    outline: none;
    border: none;

    &::placeholder {
      color: #a9a9a9;
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
    }
  `,
  StickyButtonContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px 24px;
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0.5px 0 0 #cacaca inset;
  `,
}
