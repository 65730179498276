import { PageId } from 'page-constants'
import { Cohort, SCALE_OPTION_VALUES } from 'root-constants'

export const ANSWERS = [
  { value: SCALE_OPTION_VALUES.ONE, label: 1 },
  { value: SCALE_OPTION_VALUES.TWO, label: 2 },
  { value: SCALE_OPTION_VALUES.THREE, label: 3 },
  { value: SCALE_OPTION_VALUES.FOUR, label: 4 },
  { value: SCALE_OPTION_VALUES.FIVE, label: 5 },
]

export const STATEMENT_QUESTIONS_MAP: Record<string, string> = {
  [PageId.MIRROR_REFLECTION]:
    'The reflection in the mirror affects my mood and my self-esteem',
  [PageId.COMPARE_MYSELF_TO_OTHERS]:
    'I tend to compare myself to others and it makes me frustrated',
  [PageId.APPEARANCE_EFFECT]: `My appearance may affect my relationships`,
  [PageId.AFRAID_TO_LOOK_OLDER]: `I'm afraid that people won't like me if I look older`,
  [PageId.SKIN_REQUIRES_COMMITMENT]: `Beautiful skin requires commitment, not a miracle`,
  [PageId.HAPPINESS_IS_HABIT]: `Happiness is a habit. So is your skin care`,
  [PageId.COSMETICS_HELP]:
    'Only cosmetics will help my skin stay healthy and young',
  [PageId.TO_BE_EXPERT]:
    'Every person should learn to be their own skincare expert',
  [PageId.NO_DESIRED_RESULT]:
    '“I’ve tried to reduce my double chin before but haven’t seen the results I wanted” ',
  [PageId.NOT_GREAT_RESULTS]: 'Having a double chin affects my confidence',
  [PageId.MAKE_ME_LOOK_HEAVIER]:
    'I feel like my double chin makes me look heavier than I really am',
  [PageId.NOT_REFLECT_FEELINGS]:
    'My appearance doesn’t reflect how young and energetic I feel inside',
}

export const STATEMENT_QUESTIONS_DEFAULT_TEXT = {
  [PageId.MIRROR_REFLECTION]: 'onboarding.mirrorReflection.question',
  [PageId.COMPARE_MYSELF_TO_OTHERS]:
    'onboarding.compareMyselfToOthers.question',
  [PageId.APPEARANCE_EFFECT]: 'onboarding.appearanceEffect.question',
  [PageId.AFRAID_TO_LOOK_OLDER]: 'onboarding.afraidToLookOlder.question',
  [PageId.COSMETICS_HELP]: 'onboarding.cosmeticsHelp.question',
}

export const STATEMENT_QUESTIONS_CONTEXT_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]: {
    [PageId.SKIN_REQUIRES_COMMITMENT]:
      'onboarding.skinRequiresCommitment.question_cosmetic',
    [PageId.COMPARE_MYSELF_TO_OTHERS]:
      'onboarding.compareMyselfToOthers.question_cosmetic',
    [PageId.TO_BE_EXPERT]: 'onboarding.afraidToLookOlder.question_cosmetic',
    [PageId.HAPPINESS_IS_HABIT]:
      'onboarding.happinessIsHabit.question_cosmetic',
    [PageId.COSMETICS_HELP]: 'onboarding.cosmeticsHelp.question_cosmetic',
  },
  [Cohort.LUVLY_12_SALE]: {
    [PageId.SKIN_REQUIRES_COMMITMENT]:
      'onboarding.skinRequiresCommitment.question_senior',
    [PageId.COMPARE_MYSELF_TO_OTHERS]:
      'onboarding.compareMyselfToOthers.question',
    [PageId.TO_BE_EXPERT]: 'onboarding.afraidToLookOlder.question_senior',
    [PageId.HAPPINESS_IS_HABIT]: 'onboarding.happinessIsHabit.question_senior',
    [PageId.COSMETICS_HELP]: 'onboarding.cosmeticsHelp.question_senior',
  },
  [Cohort.LUVLY_CHIN]: {
    [PageId.NO_DESIRED_RESULT]: 'onboarding.noDesiredResult.statement',
    [PageId.NOT_GREAT_RESULTS]: 'onboarding.notGreatResults.statement',
    [PageId.MAKE_ME_LOOK_HEAVIER]: 'onboarding.makeMeLookHeavier.statement',
    [PageId.NOT_REFLECT_FEELINGS]: 'onboarding.notReflectFeeling.statement',
  },
}

export const CHAT_STATEMENT_QUESTIONS_MAP: Record<string, string> = {
  [PageId.OB_STATEMENT_1]: 'Healthy skin requires commitment, not a magic pill',
  [PageId.OB_STATEMENT_2]: `I often compare myself to others, although I know it's not good for me`,
  [PageId.OB_STATEMENT_3]:
    'Everyone should learn to be their own skincare expert',
}
