import coachRecommendation from 'assets/images/coach-recommendation.png'
import faceYogaCoach from 'assets/images/face-yoga-coach.png'
import greatProgress from 'assets/images/great-progress.png'
import diagnosticIcon from 'assets/images/sprite/diagnostic-icon.svg'
import privateChatIcon from 'assets/images/sprite/private-chat.svg'
import programEffectiveness from 'assets/images/sprite/program-effectiveness.svg'

export const UPSELL_FEATURES = [
  {
    icon: diagnosticIcon,
    title: 'upsellCoaching.upsellFeatures.option1.title',
    description: 'upsellCoaching.upsellFeatures.option1.description',
    image: faceYogaCoach,
  },
  {
    icon: privateChatIcon,
    title: 'upsellCoaching.upsellFeatures.option2.title',
    description: 'upsellCoaching.upsellFeatures.option2.description',
    image: coachRecommendation,
  },
  {
    icon: programEffectiveness,
    title: 'upsellCoaching.upsellFeatures.option3.title',
    description: 'upsellCoaching.upsellFeatures.option3.description',
    image: greatProgress,
  },
]
