import styled, { keyframes } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import arrow from 'assets/images/intro-gift-arrow.svg'
import introBg from 'assets/images/intro-gift-card-bg.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

const topBottomAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
  }
`

type TFaqProps = {
  backgroundColor?: string
}

type TLeafProps = {
  gridArea: string
}

export const StyledIntroGiftCard = {
  Root: styled.div`
    max-width: ${MediaBreakpoint.TABLET}px;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 375px;
    }
  `,
  IntroMedia: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    aspect-ratio: 375/617;
    padding: 145px 0 120px;
    background-image: url(${introBg});
    background-position: top center;
    background-size: 100%;
    background-repeat: no-repeat;
  `,
  Content: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  `,
  Subtitle: styled.h3`
    margin-bottom: 10px;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  Text: styled.p`
    flex-grow: 1;
    color: ${Color.WHITE};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  ArrowIcon: styled(SvgImage)`
    animation: ${topBottomAnimation} 2.5s infinite ease-in-out;
  `,
  Benefits: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 331px;
    margin: 0 auto 40px;
    padding-right: 14px;
  `,
  BenefitsTitle: styled.h3`
    margin: -40px 0 20px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    font-family: 'Rozha One', serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  `,
  Benefit: styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'image text' 'image description';
    grid-gap: 7px 20px;

    &:last-of-type > p {
      padding-bottom: 0;
      border: none;
    }
  `,
  BenefitImage: styled.img`
    grid-area: image;
    align-self: center;
  `,
  BenefitText: styled.h3`
    grid-area: text;
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  BenefitDescription: styled.p`
    grid-area: description;
    padding-bottom: 24px;
    border-bottom: 1px solid #e9ecee;
    color: ${Color.GRAY};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
  HowItWorksContainer: styled.div`
    margin-bottom: 40px;
    padding: 30px 20px;
    border-radius: 30px;
    background: linear-gradient(218deg, #c6ddac 3.36%, #6ba193 96.59%);
  `,
  HowItWorksTitle: styled.h3`
    margin-bottom: 20px;
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0 2px 4px #a1c4a2;
    font-family: 'Rozha One', serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.5px;
  `,
  HowItWorksList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 70px;
  `,
  HowItWorksListItem: styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 141px 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'image text' 'image description';
    grid-gap: 10px 11px;

    &:not(:last-of-type)::before {
      content: '';
      position: absolute;
      left: 60px;
      bottom: -60px;
      width: 16px;
      height: 40px;
      background-image: url(${arrow});
    }
  `,
  HowItWorksImage: styled.img`
    grid-area: image;
    align-self: center;
  `,
  HowItWorksText: styled.p`
    grid-area: text;
    align-self: flex-end;
    color: ${Color.WHITE};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  HowItWorksDescription: styled.p`
    grid-area: description;
    color: ${Color.WHITE};
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  `,
  FaqTitle: styled.h3`
    margin-bottom: 16px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    font-family: 'Rozha One', serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  `,
  FaqItem: styled.div<TFaqProps>`
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 20px;
    background: ${({ backgroundColor }) => backgroundColor || Color.CORAL_GRAY};
  `,
  FaqText: styled.p`
    margin-bottom: 8px;
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
  `,
  FaqDescription: styled.p`
    color: ${Color.DARK_SECONDARY};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    a {
      font-weight: 600;
      color: #ff7c6a;
    }
  `,
  RatingContainer: styled.div`
    display: grid;
    grid-template-columns: 38px 1fr 38px;
    grid-template-rows: auto;
    grid-template-areas:
      'leftLeaf stars rightLeaf'
      'leftLeaf reviews rightLeaf'
      'leftLeaf ratingText rightLeaf';
    grid-gap: 12px 20px;
    margin: 30px 0 20px;
  `,
  Stars: styled(SvgImage)`
    grid-area: stars;
  `,
  Leaf: styled.img<TLeafProps>`
    grid-area: ${({ gridArea }) => gridArea};
    align-self: flex-end;
  `,
  Reviews: styled.p`
    grid-area: reviews;
    color: ${Color.BLUE_DARK};
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  `,
  RatingText: styled.p`
    grid-area: ratingText;
    color: ${Color.DARK_SECONDARY};
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  `,
  Disclaimer: styled.p`
    margin-bottom: 20px;
    color: ${Color.GRAY};
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
  `,
  ButtonsContainer: styled.div`
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    gap: 8px;

    img {
      max-height: 40px;
    }
  `,

  StickyButtonContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px 24px;
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0.5px 0 0 #cacaca inset;
  `,
}
