import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Option } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { OptionType } from 'root-constants'

import { SelectSubscriptionItemV3 } from '../SelectSubscriptionItemV3'
import { SelectSubscriptionItemV4 } from '../SelectSubscriptionItemV4'
import { StyledSubscriptionsBlockV2 as S } from './SubscriptionsBlockV2.styles'

export const SubscriptionsBlockV2: React.FC = () => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const { isLuvly21Cohort } = useActiveCohortIdentifier()

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  const renderSubscriptionItem = (subscription) => {
    if (isLuvly21Cohort) {
      return (
        <SelectSubscriptionItemV4
          subscription={subscription}
          isSelected={subscription.id === selectedSubscriptionId}
        />
      )
    }

    return (
      <SelectSubscriptionItemV3
        subscription={subscription}
        isSelected={subscription.id === selectedSubscriptionId}
      />
    )
  }

  return (
    <S.Wrapper>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          withoutMargin
        >
          {renderSubscriptionItem(subscription)}
        </Option>
      ))}
    </S.Wrapper>
  )
}
