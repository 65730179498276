import benefit1 from 'assets/images/intro-gift-card-benefit-1.png'
import benefit2 from 'assets/images/intro-gift-card-benefit-2.png'
import benefit3 from 'assets/images/intro-gift-card-benefit-3.png'
import benefit4 from 'assets/images/intro-gift-card-benefit-4.png'
import step1 from 'assets/images/intro-gift-step-1.png'
import step2 from 'assets/images/intro-gift-step-2.png'
import step3 from 'assets/images/intro-gift-step-3.png'

export const QUESTION = 'Give the gift of natural beauty'

export const BENEFITS_DATA = [
  {
    image: benefit1,
    text: 'onboarding.introGiftCard.benefits.firstBenefit.text',
    description: 'onboarding.introGiftCard.benefits.firstBenefit.description',
  },
  {
    image: benefit2,
    text: 'onboarding.introGiftCard.benefits.secondBenefit.text',
    description: 'onboarding.introGiftCard.benefits.secondBenefit.description',
  },
  {
    image: benefit3,
    text: 'onboarding.introGiftCard.benefits.thirdBenefit.text',
    description: 'onboarding.introGiftCard.benefits.thirdBenefit.description',
  },
  {
    image: benefit4,
    text: 'onboarding.introGiftCard.benefits.fourthBenefit.text',
    description: 'onboarding.introGiftCard.benefits.fourthBenefit.description',
  },
]

export const HOW_IT_WORKS_DATA = [
  {
    image: step1,
    text: 'onboarding.introGiftCard.howItWorks.firstPoint.text',
    description: 'onboarding.introGiftCard.howItWorks.firstPoint.description',
  },
  {
    image: step2,
    text: 'onboarding.introGiftCard.howItWorks.secondPoint.text',
    description: 'onboarding.introGiftCard.howItWorks.secondPoint.description',
  },
  {
    image: step3,
    text: 'onboarding.introGiftCard.howItWorks.thirdPoint.text',
    description: 'onboarding.introGiftCard.howItWorks.thirdPoint.description',
  },
]

export const GIFT_WELCOME_PAGE_TRACKED_BREAKPOINTS = [66, 100]
