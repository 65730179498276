import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledAnswerButtonMini2 = {
  Root: styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 63px;
    height: 80px;
    padding: 12px 16px;
    background-color: ${Color.WHITE};
    transition: background-color 0.25s;
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    border-radius: 32px;
    border: 2px solid ${Color.WHITE};

    input:checked + & {
      background-color: #ffefed;
      border: 2px solid ${Color.PRIMARY};
    }
  `,
}
