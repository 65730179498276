import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AnimationItem } from 'lottie-web'
import lottie from 'lottie-web/build/player/lottie_light'

import {
  sendUserConfigAction,
  setUserAvailableSpinsAction,
} from 'root-redux/actions/user'
import { selectUserAvailableSpins } from 'root-redux/selects/user'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { eventLogger } from 'services/eventLogger.service'

import confetti from 'assets/animation/chinaConfettiAnimation.json'
import fortuneWheel from 'assets/animation/fortuneWheel.json'
import confettiAudio from 'assets/sounds/confetti.mp3'
import failAudio from 'assets/sounds/fail.mp3'
import wheelAudio from 'assets/sounds/wheel-spin.mp3'

import { AvailableFortuneWheelAttempts } from 'root-constants'

import { WheelDiscountCongratsModal } from '../WheelDiscountModal'
import { StyledFortuneWheel as S } from './FortuneWheel.styles'
import {
  ATTEMPT_NUMBERS,
  AUDIO_START_POINT,
  GAME_STEP,
  WHEEL_ANIMATION_SEGMENTS,
  WHEEL_ANIMATION_START_POINTS,
} from './constants'

export const FortuneWheel: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const availableSpins = useSelector(selectUserAvailableSpins)

  const wheelAnimationRef = useRef<HTMLDivElement | null>(null)
  const confettiAnimationRef = useRef<HTMLDivElement | null>(null)
  const counterRef = useRef(availableSpins)

  const { isLuvlyWheelCohort } = useActiveCohortIdentifier()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false)
  const [wheelAnimation, setWheelAnimation] = useState<AnimationItem | null>(
    null,
  )

  const [confettiAnimation, setConfettiAnimation] =
    useState<AnimationItem | null>(null)

  const wheelSound = useMemo(() => new Audio(wheelAudio), [])
  const confettiSound = useMemo(() => new Audio(confettiAudio), [])
  const failSound = useMemo(() => new Audio(failAudio), [])

  const handleCompleteWheelAnimation = useCallback(() => {
    confettiSound.play()
    confettiAnimation?.show()
    confettiAnimation?.play()

    counterRef.current -= GAME_STEP
  }, [confettiSound, confettiAnimation])

  useEffect(() => {
    if (wheelAnimationRef.current) {
      const wheelAnimationInstance = lottie.loadAnimation({
        container: wheelAnimationRef.current,
        animationData: fortuneWheel,
        name: 'wheel',
        autoplay: false,
        loop: false,
      })

      setWheelAnimation(wheelAnimationInstance)
      wheelAnimationInstance.goToAndStop(
        WHEEL_ANIMATION_START_POINTS[counterRef.current],
        true,
      )
      wheelAnimationInstance.addEventListener(
        'complete',
        handleCompleteWheelAnimation,
      )
    }

    return () => lottie.destroy('wheel')
  }, [handleCompleteWheelAnimation])

  useEffect(() => {
    if (confettiAnimationRef.current) {
      const confettiAnimationInstance = lottie.loadAnimation({
        container: confettiAnimationRef.current,
        animationData: confetti,
        name: 'confetti',
        autoplay: false,
        loop: false,
      })

      setConfettiAnimation(confettiAnimationInstance)
      confettiAnimationInstance.hide()

      const openCongratsModal = () => setIsModalVisible(true)

      confettiAnimationInstance.addEventListener('complete', openCongratsModal)
    }

    return () => lottie.destroy('confetti')
  }, [])

  const resetSounds = useCallback(() => {
    confettiSound.pause()
    failSound.pause()
    confettiSound.currentTime = AUDIO_START_POINT
    failSound.currentTime = AUDIO_START_POINT
  }, [confettiSound, failSound])

  const handleSpin = useCallback(() => {
    setIsAnimationInProgress(true)
    wheelAnimation?.playSegments(
      WHEEL_ANIMATION_SEGMENTS[counterRef.current],
      true,
    )
    wheelSound.play()
    resetSounds()

    eventLogger.logSpinAndWinClicked(ATTEMPT_NUMBERS[availableSpins])
  }, [availableSpins, resetSounds, wheelAnimation, wheelSound])

  const handleCloseModal = () => {
    dispatch(sendUserConfigAction({ available_spins: counterRef.current }))
    dispatch(setUserAvailableSpinsAction(counterRef.current))

    eventLogger.logSpinResultsPopupClose(ATTEMPT_NUMBERS[availableSpins])
    setIsModalVisible(false)
  }

  if (!isLuvlyWheelCohort) {
    return null
  }

  return (
    <>
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="purchaseWheel.title" />
        </S.Title>
        <S.AnimationContainer>
          <S.WheelAnimation ref={wheelAnimationRef} />
          <S.SpinButton
            onClick={handleSpin}
            disabled={isAnimationInProgress || !availableSpins}
          >
            <S.SpinButtonText>{t`purchaseWheel.spinAndWin`}</S.SpinButtonText>
            <br />
            <S.SpinButtonAttempts>
              {counterRef.current}{' '}
              {t(
                counterRef.current === AvailableFortuneWheelAttempts.ONE
                  ? 'purchaseWheel.attempt'
                  : 'purchaseWheel.attempts',
              )}
            </S.SpinButtonAttempts>
          </S.SpinButton>
          <S.ConfettiAnimation ref={confettiAnimationRef} />
        </S.AnimationContainer>
      </S.Wrapper>
      <WheelDiscountCongratsModal
        isShown={isModalVisible}
        onClose={handleCloseModal}
      />
    </>
  )
}
