import styled from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
}

export const StyledSelectSubscriptionItemV4 = {
  Root: styled.div<TProps>`
    border-radius: 12px;
    box-shadow: 0 0 0 1px #cacaca;
    padding: 20px 16px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    transition: 0.15s;

    ${({ isSelected }) =>
      isSelected &&
      `
        box-shadow: 0 0 0 2px ${Color.CORAL};
        background-color: #FFEFED;
      `}
  `,
  Period: styled.span`
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
  OldPrice: styled.span`
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 141.176% */
    text-decoration: line-through;
    color: ${Color.GRAY};
    margin-right: 4px;
  `,
  MainPrice: styled.span`
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
  `,
  Text: styled.span`
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
  `,
}
