import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import af from 'assets/i18n/af.json'
import de from 'assets/i18n/de.json'
import en from 'assets/i18n/en.json'
import es from 'assets/i18n/es.json'
import fr from 'assets/i18n/fr.json'
import it from 'assets/i18n/it.json'
import ja from 'assets/i18n/ja.json'
import pt from 'assets/i18n/pt.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lang',
    },
    load: 'languageOnly',
    resources: {
      af,
      en,
      fr,
      es,
      it,
      pt,
      ja,
      de,
    },
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
