import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledAnswerButtonMini2 as S } from './AnswerButtonMini2.styles'

export const AnswerButtonMini2: React.FC<TComponentProps> = ({
  children,
  className,
}) => <S.Root className={className}>{children}</S.Root>
