import React from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import { StyledUpsellCoachingFeatures as S } from './UpsellCoachingFeatures.styles'
import { UPSELL_FEATURES } from './constants'

export const UpsellCoachingFeatures: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <S.Title>{t('upsellCoaching.gameChanger')}</S.Title>
      <S.UpsellFeatureContainer>
        {UPSELL_FEATURES.map(({ icon, title, description, image }) => (
          <S.UpsellFeature key={title}>
            <S.FeatureTitle>
              <SvgImage svg={icon} width={24} height={24} />
              {t(title)}
            </S.FeatureTitle>
            <S.Description>{t(description)}</S.Description>
            <img src={image} alt="what-you-get" />
          </S.UpsellFeature>
        ))}
      </S.UpsellFeatureContainer>
    </>
  )
}
