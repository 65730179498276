import React, { FC, useState } from 'react'
import { Trans } from 'react-i18next'

import { answerTheme } from 'storybook-ui'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { OptionType } from 'root-constants'

import { QUESTION, STRESS_LEVEL_ANSWERS } from './constanta'

export const StressLevel: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const [answer, setAnswer] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerTheme.LUVLY_SOLID,
    onChange: (value: string) => {
      setAnswer(value)
    },
  }

  return (
    <div>
      <Container>
        <PageTitle marginBottom={15}>
          <Trans i18nKey="onboarding.stressLevel.question" />
        </PageTitle>
        <PageTitleDescription marginBottom={16}>
          <Trans i18nKey="onboarding.stressLevel.description" />
        </PageTitleDescription>

        {STRESS_LEVEL_ANSWERS.map(({ value, text }) => (
          <Option {...optionProps} value={value}>
            <AnswerButton>
              <Trans i18nKey={text} />
            </AnswerButton>
          </Option>
        ))}
      </Container>

      <NavigationButtons
        onBackClick={goBack}
        onNextClick={handleNextClick}
        disabled={!answer}
      />
    </div>
  )
}
