import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { CustomerReviews } from 'components/CustomerReviews'
import { Header } from 'components/Header'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  resetErrorAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useAxonPurchaseEvent } from 'hooks/useAxonPurchaseEvent'

import { createProductId } from 'helpers/createProductId'

import { CancelOfferSubscriptionBlock } from 'modules/purchase/components/CancelOfferSubscriptionBlock'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { Checkout } from 'modules/purchase/pages'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import resultImage from 'assets/images/cancel-offer-result-img.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { StyledPurchaseCancelOffer as S } from './PurchaseCancelOffer.styles'

export const PurchaseCancelOffer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { pathname, search } = useLocation()
  const { cohort, periodName, periodQuantity, price } = usePurchaseStore()
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.CANCEL_OFFER,
  )

  const { arePricesReady } = usePricesStatus()

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        SubscriptionTagsType.CANCEL_OFFER,
      ),
    )
  }, [dispatch])

  useAxonPurchaseEvent()

  useDefaultSubscription()

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: ScreenName.CANCEL_OFFER,
    })

    dispatch(setSubscriptionListAction([]))
    goTo({ pathname: PageId.PURCHASE_VARIANT_2, search })
  }, [dispatch, pathname, cohort, productId, search])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <div data-testid="cancel-offer-container">
          <S.Header>{t`purchase1.cancelOffer.headerText`}</S.Header>
          <S.Wrapper>
            <S.Container>
              <S.Title>
                <Trans
                  i18nKey="purchase1.cancelOffer.title"
                  components={[<br />]}
                />
              </S.Title>
              <S.Subtitle>{t('purchase1.cancelOffer.subtitle')}</S.Subtitle>
              <CancelOfferSubscriptionBlock />
            </S.Container>
            <S.ResultImageContainer>
              <S.ResultImage src={resultImage} alt="result" />
            </S.ResultImageContainer>
            <S.GiftContainer>
              <S.PreviousDiscountText>
                <Trans
                  i18nKey="purchase1.cancelOffer.previousDiscount"
                  components={[<strong />]}
                />
              </S.PreviousDiscountText>
              <S.CurrentDiscountText>
                <Trans
                  i18nKey="purchase1.cancelOffer.currentDiscount"
                  components={[<strong />]}
                />
              </S.CurrentDiscountText>
            </S.GiftContainer>
            <S.Container>
              <MoneyBackGuarantee />
              <CustomerReviews />
            </S.Container>

            <S.ButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>
          </S.Wrapper>
        </div>
      )}

      {isCheckoutShown && (
        <>
          <Header />
          <Checkout
            handleCloseCheckout={handleCloseCheckout}
            hasTotalAmount={false}
          />
        </>
      )}
    </>
  )
}
