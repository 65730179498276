import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledHabits = {
  Habits: styled.div`
    margin-bottom: 48px;
  `,
  HabitsList: styled.ul`
    margin-top: 24px;
  `,
  HabitsItem: styled.li`
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    & svg {
      flex-shrink: 0;
      margin-right: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  HabitsItemName: styled.h4`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 4px;
    font-family: 'Open Sans';
  `,
  HabitsItemText: styled.p`
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
  `,
  SecondaryTitle: styled.h3`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
    font-family: 'Open Sans';
    text-align: center;
  `,
}
