import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledShareGiftCard = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${MediaBreakpoint.TABLET}px;
    min-height: calc(100dvh - 80px);
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 375px;
    }
  `,
  AnimationContainer: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
    width: 100%;
    aspect-ratio: 375/146;
  `,
  Title: styled.h2`
    margin-bottom: 136px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    font-family: 'Rozha One', serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    max-width: 315px;
    margin: 0 auto 30px;
    aspect-ratio: 315/200;
  `,
  Text: styled.p`
    flex-grow: 1;
    max-width: 300px;
    margin: 0 auto;
    color: ${Color.GRAY};
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  StickyButtonContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px 24px;
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0.5px 0 0 #cacaca inset;
  `,
}
